import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {ParcelHeader} from './header/ParcelHeader'
import {useQuery} from 'react-query'
import {ParcelStatus} from './status/ParcelStatus'
import {ParcelTracks} from './tracks/ParcelTracks'
import {getParcelById} from '../core/_requests'
import {useEffect, useState} from 'react'
import {Parcel} from '../core/_models/_parcels-models'
import {FormattedMessage, useIntl} from 'react-intl'
import {ParcelContent} from './content/ParcelContent'

interface Props {
  id: string | number
}

const ParcelPage = ({id}: Props) => {
  const {data: parcelById} = useQuery('parcel', () => {
    return getParcelById(id)
  })
  const intl = useIntl()

  const [currParcel, setCurrParcel] = useState<Parcel | null>(null)

  useEffect(() => {
    if (parcelById) {
      setCurrParcel(parcelById)
    }
  }, [parcelById])

  return (
    <>
      {currParcel !== null ? (
        <>
          {currParcel && parcelById && (
            <Routes>
              <Route
                element={
                  <>
                    <ParcelHeader parcel={currParcel} />
                    <Outlet />
                  </>
                }
              >
                <Route
                  path={`content/:id`}
                  element={
                    <>
                      <PageTitle>
                        {intl.formatMessage({id: 'BUYOUT_PAGE.CONTENT_TAB'}, {items: null})}
                      </PageTitle>
                      <ParcelContent parcel={currParcel} />
                    </>
                  }
                />

                <Route
                  path={`status/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'PARCELS.P_PAGE.HEADING'})}</PageTitle>
                      <ParcelStatus parcel={currParcel} />
                    </>
                  }
                />
                <Route
                  path={`tracks/:id`}
                  element={
                    <>
                      <PageTitle>
                        {intl.formatMessage({id: 'PARCELS.P_PAGE.TRACKS_SECTION'})}
                      </PageTitle>
                      <ParcelTracks parcel={currParcel} />
                    </>
                  }
                />

                <Route index element={<Navigate to={`content/${id}`} />} />
              </Route>
            </Routes>
          )}
        </>
      ) : (
      <FormattedMessage
            id='GENERAL.BUTTONS.WAIT'
          />  
      )}
    </>
  )
}

export default ParcelPage
