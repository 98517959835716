import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  props: any
}

export function SelectInputTemplate({props}: Props) {
  const {options, selectName, disabled, backgroundClass, defaultValue} = props

  return (
    <Field
      as='select'
      name={selectName}
      className={`form-select form-select-solid ${disabled ? 'disabled' : ''} ${
        backgroundClass ? backgroundClass : ''
      }`}
      disabled={disabled}
      defaultValue={defaultValue}
    >
      <option></option>

      {options?.map((option: IBasicSelect, index: number) => (
        <option value={option.value} key={index}>
          {option.label}
        </option>
      ))}
    </Field>
  )
}
