import {Field} from 'formik'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  // checkedBoolean: boolean
  inputName: string
  handleChange?: (val: number) => void
  firstOption?: string
  secondOption?: string
  value: number
  fontSize?: string
  setFieldValue: ISetFieldValue
  bgColor?: {first: string; second: string}
  disabled?: boolean
}

export function CheckboxSwitcher({
  // checkedBoolean,
  inputName,
  value,
  handleChange,
  setFieldValue,
  firstOption,
  secondOption,
  fontSize,
  bgColor,
  disabled = false,
}: Props) {
  return (
    <div className='d-flex justify-space-around align-items-center'>
      <span
        onClick={() => {
          setFieldValue(inputName, 0)
          if (handleChange) handleChange(0)
        }}
        style={{
          color: bgColor ? (value === 1 ? 'grey' : bgColor.second) : 'var(--kt-primary)',
        }}
        className={
          `${fontSize ? fontSize : 'fs-5'} fw-bolder cursor-pointer me-2 ` +
          (value === 0 ? 'activeCheckbox' : 'inactiveCheckbox')
        }
      >
        {firstOption}
      </span>
      <div className='form-check form-check-solid form-switch form-check-custom '>
        <Field
          className={`form-check-input w-45px h-30px form-check-equal`}
          type='checkbox'
          disabled={disabled}
          style={{
            backgroundColor: bgColor
              ? value === 1
                ? bgColor.first
                : bgColor.second
              : 'var(--kt-primary)',
          }}
          value={value}
          checked={value === 1 ? true : false}
          name={inputName}
          onChange={() => {
            setFieldValue(inputName, value === 1 ? 0 : 1)
            if (handleChange) handleChange(value === 1 ? 0 : 1)
          }}
          id='switcher-toggle'
        />
        <label aria-hidden='true' className='form-check-label' htmlFor='switcher-toggle'></label>
      </div>
      <span
        onClick={() => {
          setFieldValue(inputName, 1)
          if (handleChange) handleChange(1)
        }}
        style={{
          color: bgColor ? (value === 1 ? bgColor.first : 'grey') : 'var(--kt-primary)',
        }}
        className={
          `${fontSize ? fontSize : 'fs-5'} fw-bolder cursor-pointer me-2 ` +
          (value === 1 ? 'activeCheckbox' : 'inactiveCheckbox')
        }
      >
        {secondOption}
      </span>
    </div>
  )
}
