interface Props {
  inputName: string
  handleChange: (val: boolean) => void
  firstOption?: string
  secondOption?: string
  checked: boolean | null
  bgColor?: {first: string; second: string}
}

export function CheckboxSwitcherNoFormik({
  inputName,
  checked,
  handleChange,
  firstOption,
  secondOption,
  bgColor,
}: Props) {
  return (
    <div className='d-flex justify-space-around align-items-center'>
      <span
        style={{
          color: bgColor ? (checked === true ? 'grey' : bgColor.second) : 'var(--kt-primary)',
        }}
        onClick={() => {
          if (handleChange) handleChange(false)
        }}
        className={
          `cursor-pointer me-2 form-check-label ` +
          (checked === false ? 'inactiveCheckbox' : 'activeCheckbox')
        }
      >
        {firstOption}
      </span>
      <div className='form-check form-check-solid form-switch form-check-custom'>
        <input
          className='form-check-input cursor-pointer'
          style={{
            backgroundColor: bgColor
              ? checked === true
                ? bgColor.first
                : bgColor.second
              : 'var(--kt-primary)',
          }}
          type='checkbox'
          value='1'
          checked={typeof checked === 'boolean' ? checked : false}
          name={inputName}
          onChange={(e) => {
            handleChange(e.target.checked)
          }}
        />

        <label aria-hidden='true' className='form-check-label' htmlFor='switcher-toggle'></label>
      </div>
      <span
        style={{
          color: bgColor ? (checked === true ? bgColor.first : 'grey') : 'var(--kt-primary)',
        }}
        onClick={() => {
          if (handleChange) handleChange(true)
        }}
        className={
          `cursor-pointer me-2 form-check-label ` +
          (checked === true ? 'activeCheckbox' : 'inactiveCheckbox')
        }
      >
        {secondOption}
      </span>
    </div>
  )
}
