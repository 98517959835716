import {Formik, Form} from 'formik'
import {profileSettingsSchema} from '../../core/yup/user-profile'
import {useState} from 'react'
import {profileApi} from '../../../../../../_metronic/helpers/custom/api/profileApi'
import {ISettingsForm} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {ISettingsCustom, useAuth} from '../../../../../modules/auth'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {SettingsRadio} from '../../../../../modules/custom/form-elements/radios/SettingsRadio'
import {FormattedMessage, useIntl} from 'react-intl'
import {Notification} from '../../../../../modules/custom/Notification'
import DeliveryTypeSetting from './DeliveryTypeSetting'
import ForceClientDispatchSetting from './ForceClientDispatchSetting'
import TrackDispatchSetting from './TrackDispatchSetting'
import NotificationSettings from './NotificationSettings'
import ParcelArchiveSetting from './ParcelArchiveSetting'

interface Props {
  settings: ISettingsCustom
}
export function Settings({settings}: Props) {
  const {setCurrentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const intl = useIntl()

  const initialValues: ISettingsForm = {
    delivery_type: +settings?.delivery_type,
    dispatch: settings?.dispatch,
    dispatch_order: +settings?.dispatch_order,
    notification_bm: +settings?.notification_bm === 1 ? true : false,
    notification_email: +settings?.notification_email === 1 ? true : false,
    parcel_archive: settings?.parcel_archive,
    uast_force_dispatch: +settings?.uast_force_dispatch,
  }
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: ISettingsForm) => {
    console.log('profile contacts values', values)
    setLoading(true)
    const data = {
      delivery_type: values.delivery_type,
      parcel_archive: values.parcel_archive,
      dispatch: values.dispatch,
      dispatch_order: values.dispatch_order.toString(),
      notification_bm: values.notification_bm === true ? 1 : 0,
      notification_email: values.notification_email === true ? 1 : 0,
      uast_force_dispatch: values.uast_force_dispatch,
    }
    console.log('sent', data)

    const response = await profileApi.updateSettings(data)

    if (response) {
      setCurrentUser(response)
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      <Formik
        validationSchema={profileSettingsSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            <div className='card-body py-9 px-20 cardBody'>
              <DeliveryTypeSetting />

              <div className='separator separator-dashed mb-6'></div>

              <ForceClientDispatchSetting />

              {settings?.disp_stop_tool && (
                <>
                  <div className='separator separator-dashed mb-6'></div>
                  <TrackDispatchSetting />
                </>
              )}
              <div className='separator separator-dashed mb-6'></div>

              <ParcelArchiveSetting />

              <div className='separator separator-dashed my-6'></div>
              <NotificationSettings />

              {/* <div className='px-10'>
                <span className='form-check-label d-flex flex-column align-items-start mb-5'>
                  <span className='fw-bolder fs-5 '>
                    {intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.HEADING',
                    })}
                    :
                  </span>
                </span>{' '}
                <div>
                  <SettingsRadio
                    title={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.SEND_PARTIALLY.TITLE',
                    })}
                    description={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.SEND_PARTIALLY.DESCRIPTION',
                    })}
                    inputName='dispatch_order'
                    formValues={values}
                    value={1}
                    setFieldValue={setFieldValue}
                  />
                  <SettingsRadio
                    title={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.SEND_FULLY.TITLE',
                    })}
                    description={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.SEND_FULLY.DESCRIPTION',
                    })}
                    inputName='dispatch_order'
                    formValues={values}
                    value={0}
                    setFieldValue={setFieldValue}
                  />
                  <SettingsRadio
                    title={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.HOLD.TITLE',
                    })}
                    description={intl.formatMessage({
                      id: 'PROFILE.PAGE.SETTINGS.DEFAULT_BUYOUT_STATUS.HOLD.DESCRIPTION',
                    })}
                    inputName='dispatch_order'
                    formValues={values}
                    value={2}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div> */}
              {/* begin::Form Group*/}
            </div>{' '}
            <CardFooter loading={loading} success={success} />
          </Form>
        )}
      </Formik>{' '}
    </div>
  )
}
