/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Receiver} from '../../../core/_models'

type Props = {
  receiver: Receiver
}

const ReceiverDeliveryCell: FC<Props> = ({receiver}) => {
  return (
    <div className='d-flex '>
      <div className='d-flex flex-column'>
        <span className='text-dark fw-bold d-block fs-6'>{}</span>
        <span className='text-muted fw-normal d-block fs-6'>{}</span>
      </div>
    </div>
  )
}

export {ReceiverDeliveryCell}
