import {Formik, Form, FormikValues} from 'formik'
import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../../modules/custom/form-elements/InputTemplate'
import {Receiver} from '../../../receivers-list/core/_models'
import {receiverContactsSchema} from '../../../receivers-list/core/yup/receiver-page'
import {useState} from 'react'
import {updateReceiver} from '../../../receivers-list/core/_requests'
import {ReceiverContact} from '../../../elements/ReceiverContact'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  receiver: Receiver
  setCurrReceiver: (obj: Receiver) => void
}

export function ReceiverContacts({receiver, setCurrReceiver}: Props) {
  const initialValues = {
    phoneNumber: receiver.phoneNumber
      ? receiver.phoneNumber
          .replace('+38', '')
          .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '($1) $2-$3-$4')
      : '',
    email: receiver.email || '',
  }
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: Partial<Receiver>, actions: FormikValues) => {
    console.log('receiver contacts values', values)

    const {phoneNumber, email} = values
    setLoading(true)
    const response = await updateReceiver(
      {
        phoneNumber: '+38' + phoneNumber,
        email,
        id: receiver.id,
      },
      receiver.id
    )

    if (response) {
      setCurrReceiver(response)
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    } else {
      // set error
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_profile_details ' className='collapse show'>
        <Formik
          validationSchema={receiverContactsSchema(intl)}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form'>
              {/* {JSON.stringify(values)} */}

              <div className='card-body  p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id='RECEIVERS.REC_PAGE.CONTACTS.PHONE_LABEL' />
                  </label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <InputTemplate
                          inputName='phoneNumber'
                          type='tel'
                          required={true}
                          setFieldValue={setFieldValue}
                          value={values.phoneNumber}
                          custom={
                            <ReceiverContact
                              inputName='phoneNumber'
                              setFieldValue={setFieldValue}
                              value={values.phoneNumber || ''}
                              setDisableSubmit={setDisableSubmit}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <FormattedMessage id='RECEIVERS.REC_PAGE.CONTACTS.EMAIL_LABEL' />
                  </label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <InputTemplate inputName='email' type='mail' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CardFooter loading={loading} disabled={disableSubmit} success={success} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
