import {useState} from 'react'
import {Formik, Form} from 'formik'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {useQueryClient} from 'react-query'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {ITrackReceiverTab} from '../../core/_models/_track-page-models'
import {trackPageReceiverSchema} from '../../core/yup/track-page'
import {Track} from '../../core/_models/_tracks-models'
import {editTrack} from '../../core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'
import {Notification} from '../../../../modules/custom/Notification'

interface Props {
  track: Track
  setCurrTrack: (obj: Track) => void
  isArchive: boolean
}

export function TrackReceiver({track, setCurrTrack, isArchive}: Props) {
  const {receiver, track_package} = track
  const {lastName, firstName, patronymic, id, address, phoneNumber, region, city} = receiver
  const intl = useIntl()

  const queryClient = useQueryClient()
  const [success, setSuccess] = useState(false)

  const receiversListData: IBasicSelect[] = queryClient.getQueryData('receiversList') || []

  const initialValues = {
    receiver: receiversListData.find((receiver) => receiver.value == id) || receiversListData[0],
  }
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: ITrackReceiverTab) => {
    const {receiver} = values

    setLoading(true)
    const response = await editTrack({
      ...track,
      receiver: +receiver?.value,
      invoice_file:
        track.invoice_file.map((item) => {
          return {name: item.name, tmp_name: item.file.filename}
        }) || undefined,
    })

    if (response) {
      setCurrTrack(response)
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack'>
        <h3 className='fw-bolder my-2'>
          <FormattedMessage id='TRACK_PAGE.RECEIVER_TAB.PERS_REC_CRED' />
        </h3>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <Formik
          validationSchema={trackPageReceiverSchema(intl)}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({values, setFieldValue}) => (
            <Form noValidate className='form'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <FormattedMessage id='TRACK_PAGE.RECEIVER_TAB.FULL_NAME' />
                  </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      {track_package.length > 0 || isArchive === true ? (
                        <div className='col-lg-6 fv-row'>
                          {`${lastName || ''} ${firstName || ''} ${patronymic || ''}`}
                        </div>
                      ) : (
                        <>
                          {receiversListData && (
                            <InputTemplate
                              inputName='receiver'
                              required={true}
                              type='select'
                              value={values.receiver}
                              titleFontSize='fs-5'
                              reactSelectprops={{
                                options: receiversListData,
                                currentValue: receiversListData.find(
                                  (item) => item.value === values.receiver.value
                                ),
                                selectName: 'receiver',
                                mock: false,
                                isLoading: false,
                                onInputChange: () => {},
                                setFieldValue: setFieldValue,
                                setObject: true,
                                placeholder: intl.formatMessage({
                                  id: 'TRACKS.ADD_MODAL.FORM.RECEIVERS_PLACEHOLDER',
                                }),
                                noOptionsMessage: intl.formatMessage({
                                  id: 'TRACKS.ADD_MODAL.FORM.NO_RECEIVERS_MESSAGE',
                                }),
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <Notification
                        noteText={intl.formatMessage({
                          id: 'TRACK_PAGE.RECEIVER_TAB.FINAL_PROMPT',
                        })}
                        link={{
                          url: '/receivers/list',
                          text: intl.formatMessage({
                            id: 'TRACK_PAGE.RECEIVER_TAB.FINAL_PROMPT.LINK_WORD',
                          }),
                        }}
                      />
                    </div>{' '}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                    <FormattedMessage id='TRACK_PAGE.RECEIVER_TAB.PHONE' />
                  </label>

                  <div className='col-lg-8 '>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>{phoneNumber}</div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                    <FormattedMessage id='TRACK_PAGE.RECEIVER_TAB.ADDRESS' />
                  </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        {city && city},{' '}
                        {region && (
                          <FormattedMessage id='GENERAL.CONTRACTED.REGION' values={{region}} />
                        )}
                        , {address}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {track_package.length > 0 ? (
                <></>
              ) : (
                <CardFooter loading={loading} success={success} />
              )}{' '}
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}
