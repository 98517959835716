/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field} from 'formik'
import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import InputMask from 'react-input-mask'
import {profileApi} from '../../../../_metronic/helpers/custom/api/profileApi'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'

interface Props {
  value: string
  inputName: string
  setFieldValue: ISetFieldValue
  setDisableSubmit?: Dispatch<SetStateAction<boolean>>
}
function ReceiverContact({value, inputName, setFieldValue, setDisableSubmit}: Props) {
  const [phoneNumberStatus, setPhoneNumberStatus] = useState({success: true, err: ''})

  useEffect(() => {
    if (setDisableSubmit !== undefined) {
      if (phoneNumberStatus.success === false || phoneNumberStatus.err) {
        setDisableSubmit(true)
      } else {
        setDisableSubmit(false)
      }
    }
  }, [phoneNumberStatus])

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const res = await profileApi.checkPhone(e.target.value.split(' ').slice(1).join(' '))

    setPhoneNumberStatus(res)
  }

  console.log('+38 (099) 999-99-99'.replace(/[^0-9+]/g, ''))

  return (
    <div className='d-flex ProfileContactItem'>
      <div className='fv-row'>
        <div className='d-flex'>
          <Field
            name={inputName}
            render={() => (
              <InputMask
                name={inputName}
                type='tel'
                maskChar={null}
                mask='+38 (099) 999-99-99'
                className={`form-control form-control-lg form-control-solid`}
                value={value}
                id={inputName}
                onChange={(e) => {
                  setFieldValue(inputName, e.target.value.replace(/[^0-9+]/g, ''))
                  handleChange(e)
                }}
              />
            )}
          />
        </div>{' '}
        {phoneNumberStatus.err && value !== '' && (
          <div className='text-danger'>
            <FormattedMessage id='GENERAL.ERRORS.WRONG_PHONE_NUMBER' />
          </div>
        )}
        {!phoneNumberStatus.err && phoneNumberStatus.success === false && (
          <div className='text-danger'>
            <FormattedMessage id='GENERAL.ERRORS.USED_PHONE_NUMBER' />
          </div>
        )}
      </div>
    </div>
  )
}

export {ReceiverContact}
