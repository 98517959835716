import {Dispatch, SetStateAction, useRef, useState} from 'react'
import {Overlay, OverlayTrigger, Toast, ToastContainer, Tooltip} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {ID, KTSVG} from '../../../../../../_metronic/helpers'
import {copyToClipboard} from '../../../../../../_metronic/helpers/custom/funcs/copyToClipboard'
import {statusColor} from '../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {usePageData} from '../../../../../../_metronic/layout/core'
import {useAuth} from '../../../../../modules/auth'
import {Buyout} from '../../core/_models/_buyout-models'

interface Props {
  buyout: Buyout
  isArchive: boolean
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  setConfirmModalOpen: Dispatch<SetStateAction<boolean>>
  handleAction: (id?: ID) => void
}

export function BuyoutHeaderInfo({
  buyout,
  isArchive,
  show,
  setShow,
  setConfirmModalOpen,
  handleAction,
}: Props) {
  const {id, type_delive, no_money, summary, user_confirm} = buyout
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {setPageCustomData, addNotification} = usePageData()
  const moneyForPaymentConfirmation = no_money === true && currentUser?.vip === 0 ? false : true

  const codeRef = useRef(null)
  const [showCopied, setShowCopied] = useState(false)
  const target = useRef(null)
  return (
    <div className='w-100'>
      <div
        aria-live='polite'
        aria-atomic='true'
        className='bg-dark position-relative'
        style={{width: '200px'}}
      >
        <ToastContainer position='bottom-end'>
          <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
            <Toast.Body>
              {intl.formatMessage({id: 'GENERAL.TOOLTIPS.SUCCESSFUL_PAYMENT'})}
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>

      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column overflow-auto overflow-md-unset overflow-lg-unset'>
          <div className='d-flex align-items-md-center align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <div className='d-flex align-items-center mb-2 mb-md-0'>
              {type_delive == 0 ? (
                <KTSVG path='/media/icons/flight.svg' className='svg-icon-primary svg-icon-3' />
              ) : (
                <KTSVG path='/media/icons/anchor.svg' className='svg-icon-primary svg-icon-3' />
              )}
              <p
                className='text-gray-800 m-0 fs-6 fs-md-2 fw-bolder me-3 d-none d-md-block'
                ref={codeRef}
              >
                {id}
              </p>{' '}
              <div className='d-md-none d-lg-none'>
                <OverlayTrigger placement={'top'} overlay={<Tooltip>{id}</Tooltip>}>
                  <p className='text-gray-800 m-0 fs-6 fs-md-2 fw-bolder me-3'>{id}</p>
                </OverlayTrigger>
              </div>
            </div>
            <div
              ref={target}
              onClick={() => {
                copyToClipboard(codeRef, setShowCopied)
                setTimeout(() => {
                  setShowCopied(false)
                }, 1000)
              }}
              className='me-2 mb-2 mb-md-0'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen054.svg'
                className={`svg-icon-3 ${
                  showCopied ? 'svg-icon-dark' : 'svg-icon-gray-600 cursor-pointer'
                }`}
              />
            </div>{' '}
            <Overlay target={target.current} show={showCopied} placement='top'>
              {(props) => (
                <Tooltip id='overlay-example' {...props}>
                  {intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})}{' '}
                </Tooltip>
              )}
            </Overlay>
            {isArchive === false && summary > 0 && user_confirm === 0 && (
              <span
                className={`p-1 cursor-pointer me-2 fw-semobold  StatusBar__status ${statusColor(
                  moneyForPaymentConfirmation
                )}`}
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_checkout'
                onClick={() => {
                  if (moneyForPaymentConfirmation === false) {
                    // if (currentUser?.can_add_balance === 0) {
                    //   addNotification({
                    //     status: 'danger',
                    //     body: 'GENERAL.TOOLTIPS.PROH_PAYMENT',
                    //   })
                    // } else {
                    setPageCustomData({
                      debts: 0,
                      checkoutShow: true,
                      checkoutSum:
                        currentUser?.balance && currentUser?.balance > 0
                          ? (summary - currentUser.balance).toFixed(2)
                          : summary,
                      confirmRequest: handleAction,
                      confirmId: id,
                      showPayAll: true,
                    })
                    // }
                  } else {
                    setConfirmModalOpen(true)
                  }
                }}
              >
                <FormattedMessage
                  id='GENERAL.BUTTONS.CONFIRM_PAYMENT'
                  values={{sum: `$${summary}`}}
                />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
