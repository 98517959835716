// crauzer only

import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../_metronic/helpers'
import {ICrauzerUser} from '../../../_metronic/helpers/custom/tsHelpers/crauzerUser'
import {getConfig} from '../../../_metronic/i18n/Metronici18n'

const API = process.env.REACT_APP_API_PATH

const PAGE = '/profile'

const CRAUZER_BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API}`

function getCurrentUser(): Promise<ICrauzerUser | undefined> {
  return axios
    .get<ICrauzerUser>(`${CRAUZER_BASE_URL}${PAGE}`)
    .then((r: AxiosResponse<ICrauzerUser>) => r.data)
}

const editProfile = async (data: Partial<ICrauzerUser>) => {
  const {data: response} = await axios.put(`${CRAUZER_BASE_URL}${PAGE}`, data)
  return response
}
function confirmEmailResend() {
  const {selectedLang} = getConfig()
  console.log(selectedLang)
  return axios.post(
    `${CRAUZER_BASE_URL}${PAGE}/registration-resend`,
    {},
    {
      headers: {'Accept-Language': selectedLang},
    }
  )
}

const confirmTos = async (website: ID) => {
  const {data: response} = await axios.post(`${CRAUZER_BASE_URL}${PAGE}/tos-confirm`, {website})
  return response
}

export const crauzerProfileApi = {getCurrentUser, editProfile, confirmEmailResend, confirmTos}
