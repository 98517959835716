/* eslint-disable jsx-a11y/anchor-is-valid */
import {ParcelItemCard} from './ParcelItemCard'
import {IParcelProds, Parcel} from '../../core/_models/_parcels-models'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  parcel: Parcel
}

export function ParcelContent({parcel}: Props) {
  const {prods} = parcel
  const intl = useIntl()

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-10 '>
        <h3 className='fw-bolder my-2'>
          <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.HEADING' />
        </h3>
      </div>
      <div className='row g-6 g-xl-9'>
        {prods.map((item: IParcelProds, index: number) => (
          <ParcelItemCard key={index} items={prods} item={item} parcel={parcel} />
        ))}{' '}
      </div>
    </>
  )
}
