import {useState} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Buyout} from '../../core/_models/_buyout-models'
import {Portal} from '../../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../../modules/custom/modals/ActionConfirmation'
import {useMutation} from 'react-query'
import {confirmBuyout} from '../../core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'
import {BuyoutHeaderInfo} from './BuyoutHeaderInfo'
import {BuyoutHeaderShipment} from './BuyoutHeaderShipment'
import {scrollToTabsStart} from '../../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {HeaderMessage} from '../../../../../modules/custom/sections/header-elements/HeaderMessage'

interface Props {
  buyout: Buyout
  isArchive: boolean
  setCurrBuyout: (obj: Buyout) => void
}

const BuyoutHeader = ({buyout, setCurrBuyout, isArchive}: Props) => {
  const intl = useIntl()
  const location = useLocation()
  const {id, prods, comments, lastMessage: lastBuyoutMessage} = buyout
  const pagePath = isArchive === true ? '/archived-buyouts/info' : '/buyouts/edit'
  const [show, setShow] = useState(false)

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const confirmMutation = useMutation<any, Error>(
    async () => {
      return await confirmBuyout(id)
    },
    {
      onSuccess: () => {
        setShow(true)
        setCurrBuyout({...buyout, summary: 0})
      },
      onSettled: () => {
        setConfirmModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  return (
    <div className='card mb-5 mb-xl-10'>
      {confirmModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={intl.formatMessage({id: 'BUYOUT_PAGE.CONF_BUYOUT_CONF'})}
              setModalOpen={setConfirmModalOpen}
              handleAction={() => confirmMutation.mutate()}
              id={id}
              isOpen={confirmModalOpen}
            />
          }
          className='modal-root'
        />
      )}

      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-start align-items-center flex-column flex-md-row flex-lg-row   align-items-md-start justify-content-md-between overflow-hidden overflow-md-auto'>
          <div className='d-flex flex-row w-100'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-75px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm005.svg')}
                  alt='Buyout'
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <BuyoutHeaderInfo
                buyout={buyout}
                isArchive={isArchive}
                setConfirmModalOpen={setConfirmModalOpen}
                setShow={setShow}
                show={show}
                handleAction={() => confirmMutation.mutate()}
              />{' '}
              {comments && comments.length > 0 && (
                <div className='d-none d-md-block  mw-md-450px'>
                  {' '}
                  <HeaderMessage message={lastBuyoutMessage} id={id} />
                </div>
              )}
            </div>
          </div>
          {comments && comments.length > 0 && (
            <div className='d-md-none'>
              <HeaderMessage message={lastBuyoutMessage} id={id} />
            </div>
          )}
          <BuyoutHeaderShipment buyout={buyout} />
        </div>

        <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item' onClick={() => scrollToTabsStart()}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/content/${id}` && 'active')
                }
                to={`${pagePath}/content/${id}`}
              >
                <FormattedMessage
                  id='BUYOUT_PAGE.CONTENT_TAB'
                  values={{items: `(${prods.length})`}}
                />
              </Link>
            </li>
            <li className='nav-item' onClick={() => scrollToTabsStart()}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/comments/${id}` && 'active')
                }
                to={`${pagePath}/comments/${id}`}
              >
                <FormattedMessage id='BUYOUT_PAGE.COMMENTS_TAB' />
              </Link>
            </li>

            <li className='nav-item' onClick={() => scrollToTabsStart()}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/status/${id}` && 'active')
                }
                to={`${pagePath}/status/${id}`}
              >
                <FormattedMessage id='BUYOUT_PAGE.STATUSES_TAB' />
              </Link>
            </li>
            <li className='nav-item' onClick={() => scrollToTabsStart()}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/price/${id}` && 'active')
                }
                to={`${pagePath}/price/${id}`}
              >
                <FormattedMessage id='BUYOUT_PAGE.COST_TAB' />
              </Link>
            </li>
            <li className='nav-item' onClick={() => scrollToTabsStart()}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `${pagePath}/settings/${id}` && 'active')
                }
                to={`${pagePath}/settings/${id}`}
              >
                <FormattedMessage id='BUYOUT_PAGE.SETTINGS_TAB' />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {BuyoutHeader}
