/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Parcel} from '../../../../core/_models/_parcels-models'

type Props = {
  parcel: Parcel
}

const ParcelStatusCell = ({parcel}: Props) => {
  const {ttn, status, type, link} = parcel

  return (
    <div className='text-center'>
      <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
        <span className='text-dark fw-bold text-hover-primary d-block fs-6 mb-2'>{status} </span>
        {ttn && (
          <div className='d-flex justify-content-center align-items-center'>
            {type === 1 && <KTSVG path='/media/icons/np-logo.svg' className=' svg-icon-1 m-1' />}
            {type === 3 && <KTSVG path='/media/icons/vector.svg' className=' svg-icon-1 m-1' />}
            {link ? (
              <a
                target='_blank'
                rel='noreferrer noopener'
                href={link}
                className='text-muted fw-semobold d-block fs-7'
              >
                {' '}
                ТТН {ttn}
              </a>
            ) : (
              <span className='text-muted fw-semobold d-block fs-7'> ТТН {ttn}</span>
            )}
          </div>
        )}
      </span>
    </div>
  )
}

export {ParcelStatusCell}
