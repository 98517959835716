import {useState, Dispatch, SetStateAction} from 'react'
import {Formik, Form, useFormikContext} from 'formik'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {profileOverviewSchema} from '../../core/yup/user-profile'
import {TermsOfService} from '../../../../../modules/custom/TermsOfService'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {PatronymicCheckbox} from '../../../../receivers/elements/PatronymicCheckbox'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {crauzerProfileApi} from '../../../_requests'
import {ICrauzerUser} from '../../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'
import {VectorUserModel} from '../../../../../modules/auth'
import {establishOwnerFromDomain} from '../../../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'

interface Props {
  user: ICrauzerUser | undefined
  setCurrentUser: Dispatch<SetStateAction<VectorUserModel | undefined>>
}

export function ProfileOverview({user, setCurrentUser}: Props) {
  const intl = useIntl()

  const initialValues: Partial<ICrauzerUser> = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    patronymic: user?.patronymic || '',
    noPatronymic: user?.noPatronymic,
  }
  const [loading, setLoading] = useState(false)

  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: Partial<ICrauzerUser>) => {
    console.log('values', values)
    const {firstName, lastName, patronymic} = values
    try {
      setLoading(true)
      const dataToSend: Partial<ICrauzerUser> = {
        firstName,
        lastName,
        noPatronymic: Boolean(values.noPatronymic),
      }

      if (dataToSend.noPatronymic === false) {
        dataToSend.patronymic = patronymic
      } else {
        dataToSend.patronymic = null
      }
      const response = await crauzerProfileApi.editProfile(dataToSend)
      console.log('resp', response)

      if (response) {
        setCurrentUser({...user, ...response})
        setLoading(false)

        setSuccess(true)

        setTimeout(() => {
          setSuccess(false)
        }, 3000)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {user ? (
        <div className='card mb-5 mb-xl-10'>
          <Formik
            validationSchema={profileOverviewSchema(intl)}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({values, setFieldValue}) => (
              <Form noValidate className='form'>
                {/* {JSON.stringify(values)} */}

                <div className='card-body border-top p-9'>
                  <Name />
                  <NoPatronymic />
                  <TOS user={user} />
                </div>
                <CardFooter loading={loading} success={success} />
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        // refactor into one loader
        'Loading...'
      )}
    </>
  )
}

const Name = () => {
  const intl = useIntl()

  return (
    <>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
          <FormattedMessage id='PROFILE.PAGE.OVERVIEW.FULL_NAME_LABEL' />
        </label>

        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <InputTemplate
                inputName='firstName'
                required={false}
                type='text'
                placeholder={intl.formatMessage({
                  id: 'PROFILE.PAGE.OVERVIEW.NAME_PLACEHOLDER',
                })}
                addSymbol={
                  <KTSVG
                    path='/media/icons/duotune/communication/com006.svg'
                    className='svg-icon-3'
                  />
                }
                symbolMarginTop={false}
              />
            </div>

            <div className='col-lg-6 fv-row'>
              <InputTemplate
                inputName='lastName'
                required={false}
                type='text'
                placeholder={intl.formatMessage({
                  id: 'PROFILE.PAGE.OVERVIEW.LAST_NAME_PLACEHOLDER',
                })}
                addSymbol={
                  <KTSVG
                    path='/media/icons/duotune/communication/com006.svg'
                    className='svg-icon-3'
                  />
                }
                symbolMarginTop={false}
              />
            </div>
          </div>
        </div>
      </div>{' '}
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <InputTemplate
                inputName='patronymic'
                required={false}
                type='text'
                placeholder={intl.formatMessage({
                  id: 'PROFILE.PAGE.OVERVIEW.PATRONYMIC_PLACEHOLDER',
                })}
                addSymbol={
                  <KTSVG
                    path='/media/icons/duotune/communication/com006.svg'
                    className='svg-icon-3'
                  />
                }
                symbolMarginTop={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const NoPatronymic = () => {
  const intl = useIntl()
  const {values, setFieldValue} = useFormikContext<Partial<ICrauzerUser>>()
  return (
    <div className='row mb-6'>
      <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
      <div className='col-lg-8'>
        <div className='row'>
          <PatronymicCheckbox
            setFieldValue={setFieldValue}
            title={intl.formatMessage({
              id: 'PROFILE.PAGE.OVERVIEW.NO_PATRONYMIC_CHECKBOX',
            })}
            value={values.noPatronymic || false}
          />
        </div>
      </div>
    </div>
  )
}

const TOS = ({user}: Pick<Props, 'user'>) => {
  const intl = useIntl()
  const findWebsiteAgreementConfirmation = user?.tosConfirmedDates?.find(
    (i) => i?.website && i?.website === establishOwnerFromDomain()
  )
  return (
    <div className='row mb-0'>
      <TermsOfService
        heading={intl.formatMessage({
          id: 'PROFILE.PAGE.OVERVIEW.TERMS_OF_SERVICE',
        })}
        text={
          findWebsiteAgreementConfirmation
            ? `${intl.formatMessage({
                id: 'GENERAL.STATUSES.ACCEPTED',
              })}
             ${intl.formatDate(findWebsiteAgreementConfirmation.date, {
               year: 'numeric',
               month: 'numeric',
               day: 'numeric',
             })}, ${intl.formatTime(findWebsiteAgreementConfirmation.date)}`
            : intl.formatMessage({
                id: 'GENERAL.STATUSES.DECLINED',
              })
        }
        buttonText={intl.formatMessage({
          id: 'GENERAL.SEE_DETAILS',
        })}
        accepted={user?.tosConfirmedWebsites[establishOwnerFromDomain()] === true}
      />
    </div>
  )
}
