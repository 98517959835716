/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedPagination,
  initialQueryState,
  KTCard,
  KTSVG,
} from '../../../../../_metronic/helpers'
import {BuyoutsTable} from './table/BuyoutsTable'
import {BuyoutsListHeader} from './components/header/BuyoutsListHeader'
import {BuyoutModal} from '../_modals/BuyoutModal'
import {useLocation} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'

const BuyoutsList = () => {
  const {itemIdForUpdate, setItemIdForUpdate, isArchive, setIsArchive} = useListView()
  const location = useLocation()
  const {pathname, state} = location

  const openAddBuyoutModal = () => {
    setItemIdForUpdate(null)
  }

  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()

    updateState({
      ...initialQueryState,
      itemsPerPage: retrievedData,
    })

    if (setIsArchive && pathname === '/archived-buyouts/list') {
      setIsArchive(true)
      updateState({
        ...initialQueryState,
        filter: {archive: 1},
      })
    }
  }, [])

  useEffect(() => {
    if (state === '/buyouts/create') {
      setItemIdForUpdate(null)
    }
  }, [state])

  return (
    <>
      <div
        style={{marginTop: '-4%', zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>
          <FormattedMessage
            id={isArchive === true ? 'BUYOUTS.PAGE.PAGE_HEADING_ARCH' : 'BUYOUTS.PAGE.PAGE_HEADING'}
          />
        </h3>

        <button type='button' className='btn btn-primary' onClick={openAddBuyoutModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          <FormattedMessage id='BUYOUTS.LIST.ADD_BUTTON' />
        </button>
      </div>
      <KTCard>
        <BuyoutsListHeader />
        <BuyoutsTable />
        {itemIdForUpdate !== undefined && <BuyoutModal />}
      </KTCard>
    </>
  )
}

const BuyoutsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <BuyoutsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {BuyoutsListWrapper}
