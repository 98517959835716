/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../../../../modules/custom/modals/ActionConfirmation'
import {useListView} from '../../../../core/ListViewProvider'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {Buyout} from '../../../../core/_models/_buyout-models'
import {archiveBuyout, deleteBuyout} from '../../../../core/_requests'

type Props = {
  buyout: Buyout
}

const BuyoutActionsCell: FC<Props> = ({buyout}) => {
  const intl = useIntl()
  const {id, user_confirm} = buyout
  const {refetch} = useQueryResponse()
  const {isArchive} = useListView()
  const pagePath = isArchive === true ? '/archived-buyouts/info' : '/buyouts/edit'
  const [modalOpen, setModalOpen] = useState(false)

  const archiveMutation = useMutation<any, Error>(
    async () => {
      return await archiveBuyout(id, isArchive === true ? 0 : 1)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setModalOpen(false)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const deleteMutation = useMutation<any, Error>(
    async () => {
      return await deleteBuyout(id)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setModalOpen(false)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )
  return (
    <div className='d-flex flex-gutter justify-content-center flex-shrink-0'>
      {isArchive === true ? (
        <>
          {modalOpen && (
            <Portal
              children={
                <ActionConfirmation
                  actionName={intl.formatMessage({id: 'BUYOUTS.LIST.ACTIONS.RESTORE_CONF'})}
                  setModalOpen={setModalOpen}
                  handleAction={() => archiveMutation.mutate()}
                  id={id}
                  isOpen={modalOpen}
                />
              }
              className='modal-root'
            />
          )}
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>{intl.formatMessage({id: 'TRACKS.PAGE.LIST.ACTIONS.VIEW'})} </Tooltip>
            }
          >
            <Link
              to={`${pagePath}/content/${id}`}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-3' />
            </Link>
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.RESTORE'})} </Tooltip>}
          >
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setModalOpen(true)}
            >
              <KTSVG path='/media/icons/duotune/files/fil018.svg' className='svg-icon-3' />
            </button>
          </OverlayTrigger>
        </>
      ) : (
        <>
          {' '}
          {modalOpen && (
            <Portal
              children={
                <ActionConfirmation
                  actionName={intl.formatMessage({
                    id: !user_confirm
                      ? 'BUYOUTS.LIST.ACTIONS.TRASH_CONF'
                      : 'BUYOUTS.LIST.ACTIONS.ARCHIVE_CONF',
                  })}
                  setModalOpen={setModalOpen}
                  handleAction={() =>
                    !user_confirm ? deleteMutation.mutate() : archiveMutation.mutate()
                  }
                  id={id}
                  isOpen={modalOpen}
                />
              }
              className='modal-root'
            />
          )}
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
          >
            <Link
              to={`/buyouts/edit/content/${id}`}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </Link>
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>
                {intl.formatMessage({
                  id: !user_confirm ? 'GENERAL.TOOLTIPS.TRASH' : 'GENERAL.TOOLTIPS.ARCHIVE',
                })}
              </Tooltip>
            }
          >
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setModalOpen(true)}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </OverlayTrigger>
          <button
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm show-chat'
            data-id={id}
            data-type='Buyout'
            data-name={id}
          >
            {/*<KTSVG path='/media/icons/duotune/communication/com003.svg' className='svg-icon-3' />*/}
          </button>
        </>
      )}
    </div>
  )
}

export {BuyoutActionsCell}
