/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../_metronic/partials'
import {statusColor} from '../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {EditBuyoutContentCard} from './_modals/EditBuyoutContentCard'
import {Buyout, IBuyoutProds} from '../../core/_models/_buyout-models'
import {FormattedMessage, useIntl} from 'react-intl'
import {ActionConfirmation} from '../../../../../modules/custom/modals/ActionConfirmation'
import {useMutation, useQuery} from 'react-query'
import {editBuyout, getBuyoutBrandsByCat} from '../../core/_requests'

type Props = {
  item: IBuyoutProds
  buyout: Buyout
  isArchive: boolean
  setCurrBuyout: (obj: Buyout) => void
}

export function BuyoutItemCard({item, buyout, setCurrBuyout, isArchive}: Props) {
  const intl = useIntl()

  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const {data: categoryBrands} = useQuery('catBrands', () => {
    return getBuyoutBrandsByCat(+category.id)
  })

  const {
    category,
    qty,
    wgt,
    cost,
    total,
    color,
    sku,
    size,
    link,
    nop,
    price,
    check_control,
    allowEdit,
    allowDelete,
    id,
  } = item

  const editBuyoutContentMutation = useMutation(editBuyout, {
    onSuccess: (data) => {
      if (data) {
        setCurrBuyout(data)
      }
    },

    onError: (error) => {
      console.log(error)
    },
  })

  const handleDelete = () => {
    const dataToSend = {
      ...buyout,
      prods: [...buyout.prods.filter((prod) => prod.id !== id)],
    }
    console.log('dataToSend', dataToSend)

    editBuyoutContentMutation.mutate(dataToSend)
  }

  return (
    <>
      {editModalOpen && categoryBrands && (
        <Portal
          children={
            <EditBuyoutContentCard
              setIsOpen={setEditModalOpen}
              isOpen={editModalOpen}
              dataForEditing={item}
              buyout={buyout}
              readOnly={isArchive === true || buyout.user_confirm === 1 ? true : false}
              setCurrBuyout={setCurrBuyout}
              categoryBrands={categoryBrands}
            />
          }
          className='modal-root'
        />
      )}

      {deleteModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={intl.formatMessage({id: 'BUYOUTS.PROD_CARD.TRASH_CONF'})}
              setModalOpen={setDeleteModalOpen}
              handleAction={() => handleDelete()}
              id={item.id}
              isOpen={deleteModalOpen}
            />
          }
          className='modal-root'
        />
      )}
      <div className='col-md-6 col-xl-4'>
        <div className='card h-100 border border-2 border-gray-300 border-hover'>
          <div className='card-header border-0 pt-9 px-8'>
            <div className='card-title m-0'>
              <div className='symbol symbol-50px w-50px bg-light me-3'>
                <img
                  src={
                    category?.image || toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm001.svg')
                  }
                  alt='card2'
                  className='p-3'
                />
              </div>
              {isArchive === false && (
                <button
                  type='button'
                  className={`btn p-1 me-2 fw-semobold fs-7 StatusBar__status ${statusColor(
                    item.unavaliable == 0 ? true : false
                  )}`}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='bottom-end'
                >
                  {item.unavaliable == 0 ? (
                    <FormattedMessage id='GENERAL.STATUSES.AVAILABLE' />
                  ) : (
                    <FormattedMessage id='GENERAL.STATUSES.UNAVAILABLE' />
                  )}
                </button>
              )}
            </div>

            <div className='card-toolbar d-flex justify-content-end flex-shrink-0'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-gray-900 btn-active-light-primary me-2'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                onClick={() => setEditModalOpen(true)}
              >
                <KTSVG
                  path={
                    allowEdit === 0 || isArchive === true
                      ? '/media/icons/duotune/general/gen021.svg'
                      : '/media/icons/duotune/art/art005.svg'
                  }
                  className='svg-icon-3 svg-icon-gray-900'
                />
              </button>{' '}
              {allowDelete === 1 && isArchive === false && buyout.prods.length > 1 && (
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-gray-900 btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => setDeleteModalOpen(true)}
                >
                  <KTSVG
                    path={'/media/icons/duotune/general/gen027.svg'}
                    className='svg-icon-3 svg-icon-gray-900'
                  />
                </button>
              )}
            </div>
          </div>

          <div className='card-body p-8'>
            <div className='fs-3 fw-bolder text-dark'>
              {' '}
              {category?.name} {item.user_name && `(${item.user_name})`}
              {item.brand && (
                <p className='fs-6 m-0 fw-normal'>
                  {item.brand.name} {item.model && `(${item.model})`}
                </p>
              )}
            </div>
            {/* {buyout.mitnitsya == 1 && (
              <div>
                <div className={`badge badge-light-info fw-bolder px-2 py-2 mb-6`}>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.COM_PROD' />
                </div>
              </div>
            )} */}

            <a
              href={link}
              className='d-block text-gray-400 fw-bold fs-5 mt-1 mb-4'
              target='_blank'
              rel='noreferrer noopener'
            >
              {nop}{' '}
            </a>
            <p className='text-muted fw-semobold text-muted d-block fs-7 m-0'>
              <span className={`p-1 fs-8 fw-semobold  StatusBar__status bg-light`}>
                <FormattedMessage
                  id={+item.used === 1 ? 'GENERAL.STATUSES.USED' : 'GENERAL.STATUSES.NEW'}
                />
              </span>
            </p>

            <div className='d-flex flex-wrap my-5'>
              {sku && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                  <span className='fs-6 text-gray-800 fw-bolder me-2'>
                    <FormattedMessage id='BUYOUTS.FORM.ART_LABEL' />
                  </span>
                  <span className='fw-bold text-gray-400'>{sku}</span>
                </div>
              )}

              {size && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                  <span className='fs-6 text-gray-800 fw-bolder me-2'>
                    <FormattedMessage id='BUYOUTS.FORM.SIZE_LABEL' />
                  </span>
                  <span className='fw-bold text-gray-400'>{size}</span>
                </div>
              )}

              {color && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                  <span className='fs-6 text-gray-800 fw-bolder me-2'>
                    <FormattedMessage id='BUYOUTS.FORM.COLOR_LABEL' />
                  </span>
                  <span className='fw-bold text-gray-400'>{color}</span>
                </div>
              )}

              {!!wgt && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                  <span className='fs-6 text-gray-800 fw-bolder me-2'>
                    <FormattedMessage id='BUYOUTS.FORM.WEIGHT_LABEL' />
                  </span>
                  <span className='fw-bold text-gray-400'>{wgt} кг</span>
                </div>
              )}
            </div>
            <div className='h-4px w-100 bg-light mb-5' data-bs-toggle='tooltip'>
              <div
                className={`bg-black rounded h-4px`}
                role='progressbar'
                style={{width: `${0}%`}}
              ></div>
            </div>

            <div>
              <p>
                <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.EXP_COST' values={{cost}} />
              </p>
              <p>
                <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.REAL_COST' values={{price}} />
              </p>
              <p>
                <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.QTY' values={{qty}} />
              </p>
              <p className='fw-bold'>
                <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.TOTAL_COST' values={{total}} />
              </p>
            </div>

            <div className='border-top-dashed'>
              <p className='mt-5 fw-bold'>
                <FormattedMessage
                  id={
                    check_control === 1
                      ? 'GENERAL.STATUSES.INSPECTION_ORDERED'
                      : 'GENERAL.STATUSES.NO_INSPECTION_ORDERED'
                  }
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
