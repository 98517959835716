import {Track} from '../../core/_models/_tracks-models'
import {TrackStatusItem} from './TrackStatusItem'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  track: Track
}

export function TrackStatus({track}: Props) {
  const intl = useIntl()

  const {status_logs} = track
  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      <div className='card-body py-9 px-20 cardBody'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-190px'>
                  <FormattedMessage id='PARCELS.P_PAGE.STATUSES.DATE_COLUMN' />
                </th>

                <th className='min-w-120px'>
                  <FormattedMessage id='PARCELS.P_PAGE.STATUSES.STATUS_COLUMN' />
                </th>
                <th className='min-w-100px'>
                  <FormattedMessage id='PARCELS.P_PAGE.STATUSES.AUTHOR_COLUMN' />
                </th>
              </tr>
            </thead>

            <tbody>
              {status_logs?.map((item) => (
                <TrackStatusItem key={item.id} log={item} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
