/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {getReceivers} from './_requests'
import {Receiver} from './_models'
import {useQueryRequest} from './QueryRequestProvider'

const QueryResponseContext = createResponseContext<Receiver>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.FULF_RECEIVERS_LIST}-${query}`,
    () => {
      return getReceivers(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()

  if (response && response.totalItems && response.currentPage && response.itemsPerPage) {
    const nPages = Math.ceil(response.totalItems / response.itemsPerPage)
    const prevPage = response.currentPage === 1
    const nextPage = response.currentPage === nPages
    let links = [
      {
        url: prevPage ? null : `/page=${response.currentPage - 1}`,
        label: '❮',
        active: false,
        page: prevPage ? null : response.currentPage - 1,
      },
    ]
    for (let page = response.currentPage - 2; page <= response.currentPage + 2; page++) {
      if (page >= 1 && page <= nPages)
        links.push({
          url: page !== nPages ? null : `/page=${page}`,
          label: `${page}`,
          active: page === response.currentPage,
          page: page,
        })
    }
    links.push({
      url: nextPage ? null : `/page=${response.currentPage + 1}`,
      label: '❯',
      active: false,
      page: nextPage ? null : response.currentPage + 1,
    })
    if (links.length > 5) {
      const add = [
        {url: `/page=1`, active: true, page: 1, label: '1'},
        {url: '', active: false, page: 0, label: '...'},
      ]
      links.splice(1, 1, ...add)
    }
    return {
      page: response?.currentPage,
      total: response?.totalItems,
      itemsPerPage: response?.itemsPerPage,
      links: links,
    }
  }

  return defaultPaginationState

  // if (!response || !response.payload || !response.payload.pagination) {
  //   return defaultPaginationState
  // }

  // return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
