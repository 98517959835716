import {Formik, Form, Field, ErrorMessage} from 'formik'
import {profileContactsSchema} from '../../core/yup/user-profile'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {useState, Dispatch, SetStateAction} from 'react'
import {VectorUserModel} from '../../../../../modules/auth'
import {FormattedMessage, useIntl} from 'react-intl'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {CARDFOOTER_NOTIFICATIONS, KTSVG} from '../../../../../../_metronic/helpers'
import {crauzerProfileApi} from '../../../_requests'
import {TFooterNotification} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IProfileContacts} from '../../core/_models'
import {statusColor} from '../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {ICrauzerUser} from '../../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'

interface Props {
  user: ICrauzerUser | undefined
  setFooterNotification: any
}

export function ProfileEmail({user, setFooterNotification}: Props) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const resendEmail = async () => {
    try {
      setLoading(true)
      await crauzerProfileApi.confirmEmailResend()
      setError('')

      setFooterNotification({
        ...CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED,
        intl: 'GENERAL.TOOLTIPS.SUCCESSFUL_SENT_EMAIL',
      })

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error) {
      console.log('resend err', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {' '}
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
          <FormattedMessage id='PROFILE.PAGE.CONTACTS.EMAIL' />
        </label>
        <div className='col-lg-8 w-50'>
          <div className='row'>
            <Field
              type='email'
              className='form-control form-control-lg form-control-solid'
              name='email'
              placeholder=''
            />
            <div className='text-danger'>
              {error}
              <ErrorMessage name='email' />
            </div>
          </div>
        </div>
        {user?.emailConfirmed === false && (
          <OverlayTrigger
            placement='left'
            overlay={<Tooltip>Повторно відправити лист для підтвердження мейлу.</Tooltip>}
          >
            <button
              type='button'
              className='btn btn-icon btn-light btn-lg ms-2'
              onClick={resendEmail}
              disabled={loading}
            >
              <KTSVG path='/media/icons/duotune/communication/com002.svg' className='svg-icon-1' />
            </button>
          </OverlayTrigger>
        )}
      </div>
      {user?.emailConfirmed === false && (
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
          <div className='col-lg-8 p-0 w-50'>
            {' '}
            <span
              className={`p-1 fs-7 fw-semobold  StatusBar__status cursor-pointer ${statusColor(
                false
              )}`}
            >
              Пошту не підтверджено
            </span>
          </div>
        </div>
      )}
    </>
  )
}
