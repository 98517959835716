import {useFormikContext} from 'formik'
import {FormattedMessage, useIntl} from 'react-intl'
import {ISettingsForm} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {SettingsRadio} from '../../../../../modules/custom/form-elements/radios/SettingsRadio'

const TrackDispatchSetting = () => {
  const intl = useIntl()
  const {setFieldValue, values} = useFormikContext<ISettingsForm>()
  return (
    <div>
      {' '}
      <span className='form-check-label d-flex flex-column align-items-start mb-5'>
        <span className='fw-bolder fs-5 '>
          <FormattedMessage id='PROFILE.PAGE.SETTINGS.DEFAULT_TRACK_STATUS.HEADING' />:
        </span>
      </span>{' '}
      <div>
        {' '}
        <SettingsRadio
          title={intl.formatMessage({
            id: 'PROFILE.PAGE.SETTINGS.DEFAULT_TRACK_STATUS.SEND.TITLE',
          })}
          description={intl.formatMessage({
            id: 'PROFILE.PAGE.SETTINGS.DEFAULT_TRACK_STATUS.SEND.DESCRIPTION',
          })}
          inputName='dispatch'
          formValues={values}
          value={1}
          setFieldValue={setFieldValue}
        />
        <SettingsRadio
          title={intl.formatMessage({
            id: 'PROFILE.PAGE.SETTINGS.DEFAULT_TRACK_STATUS.HOLD.TITLE',
          })}
          description={intl.formatMessage({
            id: 'PROFILE.PAGE.SETTINGS.DEFAULT_TRACK_STATUS.HOLD.DESCRIPTION',
          })}
          inputName='dispatch'
          formValues={values}
          value={0}
          setFieldValue={setFieldValue}
        />
      </div>
    </div>
  )
}

export default TrackDispatchSetting
