import {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {establishOwnerFromDomain} from '../../../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {transformTos} from '../../../../../../_metronic/helpers/custom/funcs/transformTos'
import {useLayout, usePageData} from '../../../../../../_metronic/layout/core'
import {useAuth} from '../../../../../modules/auth'
import {getTOS} from '../../../../../modules/auth/core/_requests'
import {crauzerProfileApi} from '../../../_requests'

interface Props {
  registerProps?: {
    isOpen: boolean
    setIsOpen: (b: boolean) => void
  }
}
const TermsOfServiceModal = ({registerProps}: Props) => {
  const [tos, setTOS] = useState('')

  const {pageCustomData, setPageCustomData} = usePageData()

  const {setCurrentUser, currentUser} = useAuth()
  const {websiteData} = useLayout()

  useEffect(() => {
    document.body.classList.add('modal-open')

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    getTOS(websiteData.id).then((response) => {
      const transformedHTML = transformTos(response.data)

      setTOS(transformedHTML)
    })
  }, [websiteData.id])

  const handleClose = () =>
    registerProps
      ? registerProps.setIsOpen(false)
      : setPageCustomData((prev: any) => ({...prev, tosShow: false}))

  const acceptTos = async () => {
    try {
      const currWebsite = establishOwnerFromDomain()
      const response = await crauzerProfileApi.confirmTos(establishOwnerFromDomain())
      console.log('resp', response)
      if (response) {
        setCurrentUser({...currentUser, ...response, tosConfirmedWebsites: {[+currWebsite]: true}})
        handleClose()
      }
    } catch (error) {
      console.log('err tos', error)
    }
  }

  const closeOverlay = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (currentUser?.tosConfirmedWebsites[establishOwnerFromDomain()] === true || registerProps) {
      if (e.currentTarget === e.target) {
        handleClose()
      }
    } else {
      return
    }
  }

  const handleAnchorClick = (e: Event) => {
    e.preventDefault()
    const target = e.target as HTMLElement
    if (target.tagName === 'A' && target.getAttribute('href')?.startsWith('#')) {
      const id = target.getAttribute('href')?.slice(1)
      const element = document.getElementById(id!)
      if (element) {
        element.scrollIntoView({behavior: 'smooth'})
      }
    }
  }

  useEffect(() => {
    const container = document.querySelector('.TermsOfServiceModal_text-container')
    if (container) {
      container.addEventListener('click', handleAnchorClick)

      return () => {
        container.removeEventListener('click', handleAnchorClick)
      }
    }
  }, [tos])

  return (
    <>
      {(pageCustomData?.tosShow || registerProps?.isOpen) && (
        <>
          <div
            className={`modal fade show d-block`}
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
            onClick={closeOverlay}
          >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered modal-xl'>
              {/* begin::Modal content */}
              <div className='modal-content'>
                <div className='modal-header'>
                  {/* begin::Modal title */}
                  <h4 className='fw-bolder'>
                    <FormattedMessage id='PROFILE.PAGE.OVERVIEW.TERMS_OF_SERVICE' />
                  </h4>
                  {/* end::Modal title */}

                  {/* begin::Close */}
                  {(currentUser?.tosConfirmedWebsites[establishOwnerFromDomain()] === true ||
                    registerProps) && (
                    <div
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                      data-kt-users-modal-action='close'
                      onClick={() => handleClose()}
                      style={{cursor: 'pointer'}}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                  )}
                  {/* end::Close */}
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7 text-center'>
                  <div
                    className='TermsOfServiceModal_text-container'
                    dangerouslySetInnerHTML={{__html: tos}}
                  ></div>{' '}
                  {currentUser?.tosConfirmedWebsites[establishOwnerFromDomain()] ||
                  registerProps ? (
                    <></>
                  ) : (
                    <button
                      type='button'
                      className='btn btn-lg btn-primary'
                      onClick={() => {
                        acceptTos()
                      }}
                    >
                      <FormattedMessage id='GENERAL.BUTTONS.ACCEPT' />
                    </button>
                  )}
                </div>

                {/* end::Modal body */}
              </div>

              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {TermsOfServiceModal}
