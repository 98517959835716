import * as Yup from 'yup'

export const receiverTypeSchema = (intl: any) =>
  Yup.array()
    .min(1, intl.messages.YUP_VALIDATION_RECEIVER_ENTER_A_REC_TYPE)
    .required(intl.messages.YUP_VALIDATION_RECEIVER_ENTER_A_REC_TYPE)
    .label(intl.messages.YUP_VALIDATION_RECEIVER_REC_TYPE)

export const recTypeOfDeliverySchema = (intl: any) =>
  Yup.number().when('receiverType', (receiverType) => {
    return receiverType.includes(2)
      ? Yup.number()
          .required(intl.messages.YUP_VALIDATION_SHIPMENT_ENTER_DELIVERY_TYPE)
          .label(intl.messages.YUP_VALIDATION_SHIPMENT_DELIVERY_TYPE)
      : Yup.number().label(intl.messages.YUP_VALIDATION_SHIPMENT_DELIVERY_TYPE)
  })

export const emailSchema = Yup.string()

export const passportSchema = Yup.mixed()
