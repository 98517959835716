/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Notification} from '../../../../../modules/custom/Notification'
import SocialButton from '../../../../../modules/auth/components/SocialButton'
import {useLayout, usePageData} from '../../../../../../_metronic/layout/core'
import {linkSocial, removeSocial} from '../../../../../../_metronic/helpers/custom/api/profileApi'
import {useAuth} from '../../../../../modules/auth'

export function ProfileSocialMedia() {
  const intl = useIntl()
  const {addNotification} = usePageData()
  const {websiteData} = useLayout()
  const {currentUser, setCurrentUser} = useAuth()
  const socialLink = (res: {_provider: string; _token: {idToken: string; accessToken: string}}) => {
    let social, token
    if (res._provider === 'google') {
      social = 'google'
      token = res._token.idToken
    }
    if (res._provider === 'facebook') {
      social = 'facebook'
      token = res._token.accessToken
    }
    try {
      if (social && token) {
        linkSocial(token, social).then(({data}) => {
          if (data.success) {
            setCurrentUser(data.data)
          } else {
            addNotification({
              status: 'warning',
              body: 'PROFILE.PAGE.SOCIAL_MEDIA.USED_EMAIL',
            })
          }
        })
      }
    } catch (error) {
      console.error(error)
    }
  }
  const socialUnLink = (social: string) => {
    try {
      if (social) {
        removeSocial(social).then(({data}) => {
          if (data.success) {
            setCurrentUser(data.data)
          }
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        {' '}
        <div className='card-body  p-9'>
          <div className='row mb-6 d-flex justify-content-around'>
            <div className='col-lg-8 text-center'>
              {' '}
              <div className='row'>
                <div className='col-lg-6 fv-row '>
                  {currentUser?.socialNetworks?.google ? (
                    <button
                      type='button'
                      className='btn btn-light-danger fw-bolder w-70 mb-10 mt-2'
                      onClick={() => socialUnLink('google')}
                    >
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                        className='h-20px me-3'
                      />
                      <FormattedMessage id='HEADER_USER_MENU.LOGOUT' />{' '}
                      <FormattedMessage id='AUTH.GENERAL.WITH' /> Google
                    </button>
                  ) : (
                    <>
                      {/* begin::Google link */}
                      {websiteData.authParams?.google && (
                        <SocialButton
                          type='button'
                          className='btn btn-light-primary fw-bolder w-70 mb-10 mt-2'
                          provider='google'
                          scope={'email'}
                          appId={websiteData.authParams.google}
                          onLoginSuccess={socialLink}
                          onLoginFailure={console.log}
                        >
                          <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                            className='h-20px me-3'
                          />
                          <FormattedMessage id='AUTH.GENERAL.CONTINUE' />{' '}
                          <FormattedMessage id='AUTH.GENERAL.WITH' /> Google
                        </SocialButton>
                      )}
                      {/* end::Google link */}
                    </>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  {currentUser?.socialNetworks?.facebook ? (
                    <button
                      type='button'
                      className='btn btn-light-danger fw-bolder w-70 mb-10 mt-2'
                      onClick={() => socialUnLink('facebook')}
                    >
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
                        className='h-20px me-3'
                      />
                      <FormattedMessage id='HEADER_USER_MENU.LOGOUT' />{' '}
                      <FormattedMessage id='AUTH.GENERAL.WITH' /> Facebook
                    </button>
                  ) : (
                    <>
                      {/* begin::Facebook link */}
                      {websiteData.authParams?.facebook && (
                        <SocialButton
                          type='button'
                          className='btn fw-bolder w-70 mb-10 btn-light-facebook mt-2'
                          provider='facebook'
                          scope={'email'}
                          appId={websiteData.authParams.facebook}
                          onLoginSuccess={socialLink}
                          onLoginFailure={console.log}
                        >
                          <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
                            className='h-20px me-3'
                          />
                          <FormattedMessage id='AUTH.GENERAL.CONTINUE' />{' '}
                          <FormattedMessage id='AUTH.GENERAL.WITH' /> Facebook
                        </SocialButton>
                      )}
                      {/* end::Facebook link */}
                    </>
                  )}
                </div>
              </div>
              <Notification
                noteText={intl.formatMessage({id: 'PROFILE.PAGE.SOCIAL_MEDIA.PROMPT'})}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
