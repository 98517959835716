/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {
  getSavedFilter,
  initialQueryState,
  isNotEmpty,
  KTSVG,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../../_metronic/helpers'
import Select from 'react-select'
import tracksFilterShipmentOptions from '../../../../../../data/tracks/tracksFilterShipmentOptions.json'
import {useQueryRequest} from '../../../core/QueryRequestProvider'
import {useQueryResponse, useQueryResponsePagination} from '../../../core/QueryResponseProvider'
import {FormattedMessage, useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {customStyles} from '../../../../../modules/custom/form-elements/select/ReactSelectTemplate'
import {filterShipmentOptions} from '../../../core/_funcs'

interface Props {
  warehouseTracks?: boolean
  setWarehouseTracks?: Dispatch<SetStateAction<boolean>>
  unpackedTracks?: boolean
  setUnpackedTracks?: Dispatch<SetStateAction<boolean>>
}

const TracksListFilter = ({
  warehouseTracks,
  setWarehouseTracks,
  unpackedTracks,
  setUnpackedTracks,
}: Props) => {
  const pagination = useQueryResponsePagination()
  const {response} = useQueryResponse()
  const {updateState, state} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const intl = useIntl()
  const [savedFilter, setSavedFilter] = useState<Record<string, any>>({})
  const {type} = useParams()

  useEffect(() => {
    MenuComponent.reinitialization()
    const savedFilter = getSavedFilter(QUERIES.TRACKS_LIST)
    setSavedFilter(savedFilter)
  }, [])

  const resetFilter = () => {
    updateState({
      filter: {archive: 0, type_delive: type === 'air' ? 0 : type === 'sea' ? 1 : null},
      ...initialQueryState,
      itemsPerPage: pagination.itemsPerPage || 10,
    })
    setSavedFilter({})
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(QUERIES.TRACKS_LIST), '{}')
  }

  const filterData = () => {
    const newFilters = {
      in_storage:
        savedFilter.in_storage?.value || savedFilter.in_storage?.value === 0
          ? savedFilter.in_storage?.value
          : undefined,
      dispatch: savedFilter.dispatch,
    }

    updateState({
      ...state,
      filter: {...state.filter, ...newFilters},
    })
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.TRACKS_LIST),
      JSON.stringify(newFilters)
    )
  }

  return (
    <>
      {Object.keys(getSavedFilter(QUERIES.TRACKS_LIST)).length > 0 && (
        <button type='button' className='btn btn-sm btn-light-primary me-3' onClick={resetFilter}>
          <FormattedMessage id='GENERAL.TABLE_FILTER.RESET_OUTER_BUTTON' />{' '}
        </button>
      )}
      <button
        disabled={isLoading}
        type='button'
        className={`btn btn-sm shadow-sm btn-${
          Object.keys(getSavedFilter(QUERIES.TRACKS_LIST)).length > 0
            ? 'primary'
            : 'light-primary btn-color-muted'
        } me-3`}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        <FormattedMessage id='GENERAL.TABLE_FILTER.HEADER' />: {response?.totalItems}
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
        data-kt-menu='true'
        id='filter-menu'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>
            {' '}
            <FormattedMessage id='GENERAL.TABLE_FILTER.HEADER' />
          </div>
        </div>
        <div className='separator border-gray-200'></div>

        <div className='px-7 py-3' data-kt-user-table-filter='form'>
          <div className='mb-10'>
            <label className='form-label fw-bold'>
              <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.DELIV_STATUS' />
            </label>

            <div>
              <Select
                styles={{...customStyles}}
                defaultValue={filterShipmentOptions(tracksFilterShipmentOptions, intl)[0]}
                isLoading={isLoading}
                closeMenuOnSelect={false}
                options={filterShipmentOptions(tracksFilterShipmentOptions, intl)}
                value={
                  !savedFilter.in_storage
                    ? filterShipmentOptions(tracksFilterShipmentOptions, intl)[0]
                    : savedFilter.in_storage
                }
                placeholder={''}
                onChange={(e) => {
                  setSavedFilter((prev) => {
                    return {...prev, in_storage: e}
                  })

                  console.log(',e', e)
                }}
              />
            </div>
          </div>

          <div className='mb-10'>
            <label className='form-label fw-bold'>
              <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS' />
            </label>

            <div className='d-flex'>
              <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name='shipment'
                  type='radio'
                  value={undefined}
                  checked={savedFilter.dispatch === undefined}
                  onChange={() => {
                    setSavedFilter((prev) => {
                      return {...prev, dispatch: undefined}
                    })
                  }}
                />
                <span className='form-check-label'>
                  <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.ALL' />
                </span>
              </label>
              <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name='shipment'
                  type='radio'
                  value={1}
                  checked={+savedFilter.dispatch === 1}
                  onChange={() => {
                    setSavedFilter((prev) => {
                      return {...prev, dispatch: 1}
                    })
                  }}
                />
                <span className='form-check-label'>
                  <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.ALLOWED' />
                </span>
              </label>

              <label className='form-check form-check-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  name='shipment'
                  type='radio'
                  value={0}
                  checked={+savedFilter.dispatch === 0}
                  onChange={() => {
                    setSavedFilter((prev) => {
                      return {...prev, dispatch: 0}
                    })
                  }}
                />
                <span className='form-check-label'>
                  <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.PROH' />
                </span>
              </label>
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              disabled={isLoading}
              onClick={resetFilter}
              className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              <FormattedMessage id='GENERAL.TABLE_FILTER.RESET' />
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-sm btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              <FormattedMessage id='GENERAL.TABLE_FILTER.APPLY' />
            </button>
          </div>
        </div>
      </div>{' '}
      {/* {awaitingCount && awaitingCount > 0 ? (
        <a
          href='#'
          className='text-muted text-hover-primary fw-semibold fs-7'
          onClick={() => {
            filterData({in_storage: 1, dispatch: 0, type_delive: state.deliveryType})
          }}
        >
          <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.AWAIT' values={{items: awaitingCount}} />
        </a>
      ) : (
        <></>
      )} */}
    </>
  )
}

export {TracksListFilter}
