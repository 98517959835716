import {useFormikContext} from 'formik'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IInvoice, IRecentInvoiceReformed} from '../../core/_models/_tracks-models'
import {FormattedMessage} from 'react-intl'

interface Props {
  item: IRecentInvoiceReformed
  handleInvoiceArray: (item: IInvoice, itemIsAdded: boolean, setFieldValue: ISetFieldValue) => void
  addedInvoices: IInvoice[]
}

export function RecentInvoiceRow({item, handleInvoiceArray, addedInvoices}: Props) {
  const {track_code, file, name} = item
  const itemIsAdded = addedInvoices.find((inv) => inv.file.filename === item.file.filename)
  const {setFieldValue} = useFormikContext()
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-45px me-5'>
            <div>
              <div className='symbol symbol-fixed position-relative'>
                <img
                  src={file.base_url + file.filename}
                  width='45'
                  height='45'
                  alt='invoice'
                  style={{objectFit: 'contain'}}
                  className='border border-gray-300  rounded '
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <span className='text-dark fw-bold fs-6'>{track_code}</span>

            <span className='mb-1 fs-8 fw-semobold'>{name}</span>
          </div>
        </div>
      </td>

      <td>
        <button
          type='button'
          className='btn btn-sm btn-secondary me-3'
          onClick={() => {
            handleInvoiceArray({file, name}, itemIsAdded ? true : false, setFieldValue)
          }}
        >
          <FormattedMessage
            id={itemIsAdded ? 'GENERAL.BUTTONS.DELETE' : 'GENERAL.BUTTONS.ADD'}
            values={{item: null}}
          />
        </button>
      </td>
    </tr>
  )
}
