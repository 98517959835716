/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Receiver} from '../../../core/_models'

type Props = {
  receiver: Receiver
}

const ReceiverAddressCell: FC<Props> = ({receiver}) => {
  return (
    <div className='d-flex'>
      <span className='fw-normal text-black fs-6'>
        {receiver.address && receiver.address !== ' ' ? receiver.address : '-'}
      </span>
    </div>
  )
}

export {ReceiverAddressCell}
