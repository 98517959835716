/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {useAuth} from '../core/Auth'
import {getUser, reg_confirm} from '../core/_requests'
import {crauzerProfileApi} from '../../../pages/profile/_requests'

export function RegistrationDone() {
  let params = useParams()
  const location = useLocation()
  // @ts-ignore
  const email = location.state?.email || ''
  const intl = useIntl()
  const {saveAuth, setCurrentUser} = useAuth()
  const [status, setStatus] = useState<string | boolean>(false)

  const authUser = async (token: string) => {
    try {
      const {data: auth} = await reg_confirm(token)
      saveAuth(auth)
      const {data: user} = await getUser()
      const crUser = await crauzerProfileApi.getCurrentUser()
      setCurrentUser({...user, ...crUser})
    } catch (error: any) {
      console.error(error)
      let errMsg = intl.formatMessage({id: 'ERROR'})
      if (error.response.data?.message) {
        const val = error.response.data.message
        errMsg = intl.formatMessage({id: val, defaultMessage: val})
      }
      setStatus(errMsg)
    }
  }

  useEffect(() => {
    if (params.token) {
      authUser(params.token)
    } // eslint-disable-next-line
  }, [])

  return (
    <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-5'>
          <FormattedMessage id='AUTH.REGISTER_DONE.TITLE' />
        </h1>
        {/* end::Title */}

        <Link to='/' className='btn btn-sm btn-primary mb-3'>
          <FormattedMessage id='GENERAL.BUTTONS.RETURN_HOME' />
        </Link>

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          {params.token && status ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{status}</span>
              </div>
            </div>
          ) : (
            <FormattedMessage id='AUTH.REGISTER_DONE.TEXT' values={{email}} />
          )}
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}
      <div className='text-center'>
        <img
          src={toAbsoluteUrl('/media/auth/please-verify-your-email.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
      </div>
    </div>
  )
}
