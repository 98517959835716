/* eslint-disable jsx-a11y/anchor-is-valid */
import {Parcel} from '../../../../core/_models/_parcels-models'
import {useIntl} from 'react-intl'

type Props = {
  parcel: Parcel
}

const ParcelCostCell = ({parcel}: Props) => {
  const intl = useIntl()

  const {flow, status_logs} = parcel
  return (
    <div className='text-center'>
      {flow && status_logs?.some((item) => item.id_status === 9) && (
        <>
          {' '}
          <p className='text-dark fw-boldd-block fs-6 mb-2'>
            {flow.sum.cost && `$${flow.sum.cost}`}
          </p>
          {flow.sum.debt > 0 && (
            <p className={`btn p-1  mb-2 fw-semobold fs-9 StatusBar__status bg-gray-300`}>
              {intl.formatMessage({id: 'PARCELS.LIST.DEBT'})} ${flow.sum.debt}
            </p>
          )}
        </>
      )}
    </div>
  )
}

export {ParcelCostCell}
