import * as Yup from 'yup'
import {trackPartials} from './partials'

const {
  trackCode,
  receiver,
  deliveryType,
  description,
  prodsItem,
  packageType,
  insurance,
  requireInvoice,
  invoiceFile,
  invoiceLinks,
  orderInspection,
  inspectionDescription,
  returnToStore,
} = trackPartials

const trackPageOverviewSchema = (intl: any) =>
  Yup.object({
    track_code: trackCode(intl),
    deliveryType,
    description,
    returnToStore,
  })

const trackPageParcelSchema = (intl: any) =>
  Yup.object({
    package_type: packageType,
    insurance,
  })

const trackPageInvoiceSchema = (intl: any) =>
  Yup.object().shape(
    {
      require_invoice: requireInvoice,
      invoice_file: invoiceFile(intl),
      invoiceLinks: invoiceLinks(intl),
    },
    [['invoice_file', 'invoiceLinks']]
  )

const trackPageInspectionSchema = (intl: any) =>
  Yup.object({
    orderInspection,
    inspectionDescription: inspectionDescription(intl),
  })

const trackPageReceiverSchema = (intl: any) =>
  Yup.object({
    receiver: receiver(intl),
  })

const createTrackPageContentCardSchema = (intl: any, delType: 'avia' | 'sea') => [
  prodsItem(intl, delType),
]

export {
  trackPageOverviewSchema,
  createTrackPageContentCardSchema,
  trackPageInvoiceSchema,
  trackPageInspectionSchema,
  trackPageReceiverSchema,
  trackPageParcelSchema,
}
