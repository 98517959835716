import {Column} from 'react-table'
import {TrackCustomHeader} from './TrackCustomHeader'
import {Track} from '../../../core/_models/_tracks-models'
import {TrackItemsInfoCell} from './cells/TrackItemsInfoCell'
import {TrackSelectionCell} from './cells/selection/TrackSelectionCell'
import {TrackNumberCell} from './cells/TrackNumberCell'
import {TrackStatusCell} from './cells/TrackStatusCell'
import {TrackActionsCell} from './cells/TrackActionsCell'
import {TrackSelectionHeader} from './cells/selection/TrackSelectionHeader'

const tracksColumns: ReadonlyArray<Column<Track>> = [
  // {
  //   Header: (props) => <TrackSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <TrackSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <TrackCustomHeader
        tableProps={props}
        title={'TRACKS_COLUMNS_TRACK_NUMBER'}
        className='min-w-50px mw-300px'
      />
    ),
    id: 'trackNumber',
    Cell: ({...props}) => <TrackNumberCell track={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TrackCustomHeader
        tableProps={props}
        title={'TRACKS_COLUMNS_CONTENT'}
        className='min-w-250px'
      />
    ),
    id: 'items',
    Cell: ({...props}) => <TrackItemsInfoCell track={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TrackCustomHeader
        tableProps={props}
        title={'TRACKS_COLUMNS_STATUS'}
        className='min-w-200px text-center'
      />
    ),
    id: 'status',
    Cell: ({...props}) => <TrackStatusCell track={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <TrackCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-end'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <TrackActionsCell track={props.data[props.row.index]} />,
  },
]

export {tracksColumns}
