import * as Yup from 'yup'
import {cyrillicOnly} from '../../regexs'

export const object_shape_basic_select = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string(),
})

const phoneNumberBase = (intl: any) =>
  Yup.string()
    .min(13, intl.messages.YUP_VALIDATION_GENERAL_MISSING_NUMBERS)
    .max(13, intl.messages.YUP_VALIDATION_GENERAL_MISSING_NUMBERS)

export const phoneNumberSchema = (intl: any, required = true) =>
  required
    ? phoneNumberBase(intl).required(intl.messages.YUP_VALIDATION_GENERAL_ENTER_PHONE_NUMBER)
    : phoneNumberBase(intl)

export const basicNameSchema = (intl: any, rqrdMsg: string) =>
  Yup.string()
    .min(3, intl.messages.YUP_VALIDATION_GENERAL_MIN_SYMBOLS)
    .max(50, intl.messages.YUP_VALIDATION_GENERAL_MAX_SYMBOLS)
    .required(rqrdMsg)
    .matches(cyrillicOnly, intl.messages.YUP_VALIDATION_GENERAL_ENTER_CYRILLIC_NO_SYMBOLS)

export const patronymicOptionalSchema = (intl: any) =>
  Yup.string()
    .matches(cyrillicOnly, intl.messages.YUP_VALIDATION_GENERAL_ENTER_CYRILLIC_NO_SYMBOLS)
    .when('no_pat', {
      is: false,
      then: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_PATRONYMIC),
    })
