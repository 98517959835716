/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {IProfileReferalObject} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {ReferalTable} from './ReferalTable'

interface Props {
  referalData: IProfileReferalObject
}

export function ProfileReferal({referalData}: Props) {
  // const [tableData, setTableData] = useState(referalData.list)
  const intl = useIntl()
  const [copied, setCopied] = useState(false)

  const referalLinkRef = useRef(null)
  const renderNotification = () => {
    console.log('copied')
  }
  const copyToClipboard = () => {
    let linkToCopy = ''
    if (referalLinkRef) {
      // @ts-ignore

      linkToCopy = referalLinkRef.current.outerText
    }
    renderNotification()
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(linkToCopy)
    } else {
      return document.execCommand('copy', true, linkToCopy)
    }
  }

  return (
    <>
      {referalData ? (
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <div className='card-px text-center py-8 mb-10 d-flex flex-column align-items-center m-auto'>
            <h2 className='fs-2x fw-bold mb-10'>
              <FormattedMessage id='PROFILE.PAGE.REFERAL.HEADING' />
            </h2>
            <p
              className=' fs-4 fw-semibold mb-10'
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({id: 'PROFILE.PAGE.REFERAL.MAIN_TEXT'}),
              }}
            ></p>
            <div className='min-w-275px text-center d-flex align-items-center'>
              <span
                className='badge badge-dark text-muted min-w-275px p-4 fs-5 me-2'
                ref={referalLinkRef}
              >
                {referalData.link}
              </span>{' '}
              <div
                onClick={() => {
                  copyToClipboard()
                  setCopied(true)
                }}
              >
                {' '}
                <KTSVG
                  path='/media/icons/duotune/general/gen054.svg'
                  className={`btn svg-icon-3 ${copied ? 'svg-icon-dark' : 'svg-icon-gray-600'}`}
                />
              </div>{' '}
            </div>
          </div>
          {referalData.list.length === 0 ? (
            <div className='card-px text-center py-20 my-10'>
              <p className='text-gray-400 fs-4 fw-semibold mb-10'>
                <FormattedMessage id='PROFILE.PAGE.REFERAL.NO_REGISTERED' />
              </p>
            </div>
          ) : (
            <ReferalTable visibleTracks={referalData.list} />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
