/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQuery, useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../../_metronic/partials'
import {SearchQueryProps} from '../../../../../../modules/custom/sections/shipment/Filter'
import {ShipmentCard} from '../../../../../../modules/custom/sections/shipment/ShipmentCard'
import {ShipmentCreateCard} from '../../../../../../modules/custom/sections/shipment/ShipmentCreateCard'
import {CreateShipmentAddress} from '../../../../../../modules/custom/sections/shipment/_modals/CreateShipmentAddress'
import {
  addReceiverAddress,
  editReceiverAddress,
  getReceiverAddresses,
} from '../../../receivers-list/core/_requests'

interface Props extends SearchQueryProps {
  receiverId: number
}

export function ReceiverShipment({receiverId, searchQuery, setSearchQuery}: Props) {
  const intl = useIntl()
  const [modalOpen, setModalOpen] = useState(false)
  const [tempDeliveryType, setTempDeliveryType] = useState<0 | 1 | 2>(0) // 0 - gen, 1 - home, 2 - post, 3 - postbox??

  const {data: receiverAddresses} = useQuery(QUERIES.FULF_REC_ADDRESS_LIST, () => {
    return getReceiverAddresses(receiverId)
  })

  return (
    <div className='mb-5 mb-xl-10'>
      {receiverAddresses ? (
        <>
          {' '}
          {modalOpen && (
            <Portal
              children={
                <CreateShipmentAddress
                  setIsOpen={setModalOpen}
                  isOpen={modalOpen}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  profileAddresses={receiverAddresses}
                  tempType={tempDeliveryType}
                  apiMutation={{
                    apiRequest: addReceiverAddress,
                    id: receiverId,
                    toInvalidate: QUERIES.FULF_REC_ADDRESS_LIST,
                  }}
                  vector={false}
                />
              }
              className='modal-root'
            />
          )}
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <h3 className='fw-bolder my-2'>
              <FormattedMessage id='RECEIVERS.REC_PAGE.ADDRESS.HEADING' />
            </h3>

            {/* <div className='d-flex align-items-center my-2'>
              <button
                className='btn btn-primary btn-sm'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                id='kt_toolbar_primary_button'
                onClick={() => setModalOpen(true)}
              >
                Добавить адрес{' '}
              </button>
            </div> */}
          </div>
          <div className='row g-6 g-xl-9  flex-gutter'>
            {receiverAddresses.map((item, index) => (
              <ShipmentCard
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                key={index}
                item={item}
                profileAddresses={receiverAddresses}
                apiMutation={{
                  apiRequest: editReceiverAddress,
                  toInvalidate: QUERIES.FULF_REC_ADDRESS_LIST,
                  id: receiverId,
                }}
                vector={false}
              />
            ))}

            <ShipmentCreateCard
              addressType={{
                id: 0,
                label: intl.formatMessage({id: 'RECEIVERS.REC_PAGE.ADDRESS.ADDRESS_LABEL'}),
              }}
              key={1}
              setModalOpen={setModalOpen}
              setTempType={setTempDeliveryType}
            />
          </div>
        </>
      ) : (
        <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
      )}
    </div>
  )
}
