import {Dispatch, SetStateAction} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ImgThumb} from '../../../../modules/custom/images/ImgThumb'
import {ModalNotification} from '../../../../modules/custom/modals/ModalNotification'
import {IInvoice} from '../../core/_models/_tracks-models'
import {FormattedMessage} from 'react-intl'
import {useFormikContext} from 'formik'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {TUploadedFile} from '../../../../../_metronic/helpers/custom/tsHelpers/fileHelpers'
import getFileType from '../../../../../_metronic/helpers/custom/funcs/getFileType'
import ImgDocPreview from '../../../../modules/custom/form-elements/file-inputs/ImgDocPreview'

interface Props {
  icon: string
  id: string
  title: string
  fileType: string // 0 - image, 1 - pdf
  linkEndpoint: string
  handleDelete: Dispatch<SetStateAction<Array<IInvoice>>>
  handleInvoiceArray: (item: IInvoice, itemIsAdded: boolean, setFieldValue: ISetFieldValue) => void
  uploadedFile?: TUploadedFile
  loading: boolean
  large?: boolean
  invoiceArray: IInvoice[]
}

export function InvoiceCard({
  icon,
  id,
  title,
  fileType,
  linkEndpoint,
  handleDelete,
  uploadedFile,
  loading,
  invoiceArray,
  large = true,
  handleInvoiceArray,
}: Props) {
  const {setFieldValue} = useFormikContext()
  const fileNumber = getFileType(fileType)

  const itemIsAdded = uploadedFile
    ? invoiceArray.find((inv) => inv.file.filename === uploadedFile.link)
    : null

  return (
    <div className='card w-95px InvoiceCard__container'>
      <div className='card-body d-flex justify-content-center text-center flex-column p-1'>
        <div className='text-gray-800 text-hover-primary d-flex flex-column align-items-center'>
          <div className={`${fileNumber === 1 ? 'symbol' : ''} mb-3`}>
            {uploadedFile && (
              <ImgDocPreview
                linkEndpoint={linkEndpoint}
                loading={loading}
                title={title}
                uploadedFile={uploadedFile}
                marginClass='m-0'
                large={large}
              />
            )}

            <button
              className='btn btn-icon btn-bg-white btn-active-color-primary btn-sm InvoiceCard__delete'
              onClick={() => {
                handleInvoiceArray(
                  {
                    file: uploadedFile
                      ? {base_url: uploadedFile.base, filename: uploadedFile.link}
                      : {base_url: '', filename: ''},
                    name: title,
                  },
                  itemIsAdded ? true : false,
                  setFieldValue
                )
                // handleDelete((prev) => prev.filter((inv) => inv.file.filename !== id))
              }}
            >
              <ModalNotification
                svg='/media/icons/duotune/general/gen027.svg'
                addSvgClass='svg-icon-6'
                addClass='ModalNotification__button-position'
                children={
                  <div className='fs-7 fw-normal text-black'>
                    <FormattedMessage id='GENERAL.BUTTONS.DELETE' />
                  </div>
                }
              />
            </button>
          </div>

          <i className='fs-9 BasicTooltip' data-bs-toggle='tooltip' title={title}>
            {' '}
            <div className='fw-bolder mb-2'>
              {title.length > 15 ? title.slice(0, 15) + '...' : title}
            </div>
          </i>
        </div>
      </div>
    </div>
  )
}
