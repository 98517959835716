import {SelectInputTemplate} from './SelectInputTemplate'
import {CountrySelect} from '../../mock-components/CountrySelect'
import {
  IReactSelectProps,
  ISelectProps,
} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ReactSelectTemplate} from './ReactSelectTemplate'

interface Props {
  selectProps: ISelectProps
}

export function SelectTemplateWrapper({selectProps}: Props) {
  return (
    <>
      {selectProps.mock === true && (
        <>
          {selectProps.contentType === 'countries' && (
            <CountrySelect selectName={selectProps.selectName} />
          )}

          {selectProps.contentType !== 'countries' && <SelectInputTemplate props={selectProps} />}
        </>
      )}
    </>
  )
}
