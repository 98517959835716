/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../../_metronic/partials'
import {useAuth} from '../../../../../../modules/auth'
import {ActionConfirmation} from '../../../../../../modules/custom/modals/ActionConfirmation'
import {useListView} from '../../../../core/ListViewProvider'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {Parcel} from '../../../../core/_models/_parcels-models'
import {archiveParcel} from '../../../../core/_requests'

type Props = {
  parcel: Parcel
}

const ParcelActionsCell = ({parcel}: Props) => {
  const intl = useIntl()
  const [archiveModalOpen, setArchiveModalOpen] = useState(false)
  const {isArchive} = useListView()
  const {refetch} = useQueryResponse()
  const {currentUser} = useAuth()

  const archiveMutation = useMutation<any, Error>(
    async () => {
      return await archiveParcel(parcel.id, isArchive === true ? 0 : 1)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setArchiveModalOpen(false)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  return (
    <div className='d-flex flex-gutter justify-content-end flex-shrink-0'>
      {currentUser?.settings?.parcel_archive !== 1 && (
        <>
          {archiveModalOpen && (
            <Portal
              children={
                <ActionConfirmation
                  actionName={intl.formatMessage({id: 'TRACKS.PAGE.LIST.ACTIONS.ARCHIVE_CONF'})}
                  setModalOpen={setArchiveModalOpen}
                  handleAction={() => archiveMutation.mutate()}
                  id={parcel.id}
                  isOpen={archiveModalOpen}
                />
              }
              className='modal-root'
            />
          )}
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.ARCHIVE'})}</Tooltip>}
          >
            <a
              href='#'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setArchiveModalOpen(true)}
            >
              <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-3' />
            </a>
          </OverlayTrigger>
        </>
      )}
      <a
        href='#'
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm show-chat'
        data-id={parcel.id}
        data-type='Parcel'
        data-name={parcel.c_code}
      >
        {/*<KTSVG path='/media/icons/duotune/communication/com003.svg' className='svg-icon-3' />*/}
      </a>
    </div>
  )
}

export {ParcelActionsCell}
