import React, {useEffect, useState} from 'react'
import {Formik, Form} from 'formik'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {ITrackPageInvoiceTab} from '../../core/_models/_track-page-models'
import {Notification} from '../../../../modules/custom/Notification'
import {IInvoice, IRecentInvoiceRes, Track} from '../../core/_models/_tracks-models'
import {MultipleFileInput} from '../../../../modules/custom/form-elements/file-inputs/MultipleFileInput'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {handleFileUpload} from '../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {editTrack, uploadInvoice} from '../../core/_requests'
import {ListOfRecentInvoices} from '../../modules/invoices/ListOfRecentInvoices'
import {useQueryClient} from 'react-query'
import {trackPageInvoiceSchema} from '../../core/yup/track-page'
import {InvoiceCard} from '../../modules/invoices/InvoiceCard'
import {FormattedMessage, useIntl} from 'react-intl'
import {TrackInvoiceLinks} from '../../modules/invoices/TrackInvoiceLinks'

interface Props {
  track: Track
  setCurrTrack: (obj: Track) => void
  isArchive: boolean
}

export function TrackInvoice({track, setCurrTrack, isArchive}: Props) {
  const intl = useIntl()

  const {mitnitsya, invoice_file, invoice, track_package} = track

  const [invoiceArray, setInvoiceArray] = useState<IInvoice[] | []>([])

  useEffect(() => {
    setInvoiceArray(invoice_file || [])
  }, [invoice_file])
  const queryClient = useQueryClient()

  const recentInvoicesData: IRecentInvoiceRes[] = queryClient.getQueryData('recentInvoices') || []

  // const categoriesRequiringInvoice = prods.some(({name}) => +name.require_invoice === 1)
  const initialValues = {
    parcelType: mitnitsya === 1 ? 1 : 0,
    invoice_file: null,

    invoiceLinks: invoice && invoice.length > 0 ? ['', ...invoice] : [''],

    require_invoice: mitnitsya === 1 ? 1 : 0,
  }

  const [isLoading, setIsLoading] = useState(false)
  const [invoiceIsLoading, setInvoiceIsLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState(false)

  const handleInvoiceArray = (
    item: IInvoice,
    itemIsAdded: boolean,
    setFieldValue: ISetFieldValue
  ) => {
    setFieldValue('invoice_file', item)

    itemIsAdded === true
      ? setInvoiceArray((prev) => prev.filter((inv) => inv.file.filename !== item.file.filename))
      : setInvoiceArray((prev) => [...prev, item])
  }

  const saveInvoice = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue,
    targetValue: string
  ) => {
    setIsLoading(true)
    setInvoiceIsLoading(true)

    const linkResponse = await handleFileUpload(
      e,
      setFieldValue,
      'invoice_file',
      'file',
      uploadInvoice
    )

    if (linkResponse) {
      const localFileName = targetValue.split('\\')[targetValue.split('\\').length - 1]
      const newObject = {
        name: localFileName,
        file: {base_url: linkResponse.base_url, filename: linkResponse.filename},
      }
      if (invoiceArray === []) {
        setInvoiceArray([newObject])
      } else {
        setInvoiceArray((prev) => [...prev, newObject])
      }

      setFieldValue('invoice_file', newObject)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    } else {
      console.log('ошибка')
    }
    setInvoiceIsLoading(false)
    setIsLoading(false)
  }

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: ITrackPageInvoiceTab) => {
    console.log('track overview values', values)

    setLoading(true)
    const response = await editTrack({
      ...track,
      receiver: track?.receiver?.id ? +track?.receiver?.id : null,
      invoice_file:
        invoiceArray.map((item) => {
          return {name: item.name, tmp_name: item.file.filename}
        }) || undefined,
      invoice: values?.invoiceLinks,
    })

    if (response) {
      setCurrTrack(response)
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        validationSchema={trackPageInvoiceSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                  <FormattedMessage id='TRACKS.COMPONENTS.TrackInvoiceLinks.LABEL' />
                </label>
                <div className='col-lg-8 d-flex align-items-center'>
                  <div className='row w-75'>
                    {isArchive === true || track_package.length > 0 ? (
                      values?.invoiceLinks && values?.invoiceLinks?.length > 0 ? (
                        values?.invoiceLinks?.map((item, index) => (
                          <a
                            key={index}
                            href={item}
                            className={`form-control form-control-lg form-control-solid`}
                            target='_blank'
                            rel='noreferrer noopener'
                          >
                            {item}
                          </a>
                        ))
                      ) : (
                        '-'
                      )
                    ) : (
                      <TrackInvoiceLinks
                        withTitle={false}
                        invoiceLinks={
                          values?.invoiceLinks && typeof values?.invoiceLinks === 'object'
                            ? Object.entries(values?.invoiceLinks).map(([k, v]) => v)
                            : values?.invoiceLinks || ['']
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                  <FormattedMessage id='TRACKS.ADD_MODAL.FORM.INVOICE_TITLE' />
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-5 fv-row'>
                      <input
                        name={'require_invoice'}
                        className='form-control form-control-lg form-control-solid MultipleFileInput visually-hidden'
                        type='text'
                        disabled={
                          isArchive === true ? true : track_package.length > 0 ? true : false
                        }
                      />
                      <MultipleFileInput
                        inputId='invoice-input'
                        disabled={
                          isArchive === true ? true : track_package.length > 0 ? true : false
                        }
                        loading={invoiceIsLoading}
                        required={
                          values?.require_invoice && +values.require_invoice === 0
                            ? false
                            : values?.invoiceLinks && values.invoiceLinks[0] === ''
                            ? true
                            : false
                        }
                        inputName='invoice_file'
                        uploadedFiles={invoiceArray}
                        setFieldValue={setFieldValue}
                        uploadFunction={saveInvoice}
                      />
                    </div>
                    <div className='col-lg-7 d-flex flex-gutter flex-wrap'>
                      {invoiceArray.map((item, index) => (
                        <InvoiceCard
                          key={index}
                          title={item.name}
                          id={item.file.filename}
                          icon='/media/svg/files/pdf.svg'
                          handleDelete={setInvoiceArray}
                          fileType={
                            item.file.filename.split('.')[item.file.filename.split('.').length - 1]
                          }
                          uploadedFile={{
                            base: item.file.base_url || '',
                            link: item.file.filename || '',
                          }}
                          linkEndpoint='/files'
                          loading={false}
                          invoiceArray={invoiceArray}
                          handleInvoiceArray={handleInvoiceArray}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {isArchive === false && track_package.length === 0 && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'></label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      {' '}
                      <Notification
                        noteText={intl.formatMessage({
                          id: 'TRACKS.ADD_MODAL.FORM.INVOICE_FORMATS_EXPL',
                        })}
                      />
                    </div>
                  </div>
                </div>
              )}

              {track_package.length > 0 && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'></label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      {' '}
                      <Notification
                        noteText={intl.formatMessage({
                          id: 'TRACK_PAGE.INVOICE_TAB.INVOICE_ALR_ADDED',
                        })}
                      />{' '}
                    </div>
                  </div>
                </div>
              )}
              {isArchive === false && track_package.length === 0 && recentInvoicesData.length > 0 && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'></label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <ListOfRecentInvoices
                        recentInvoicesData={recentInvoicesData}
                        handleInvoiceArray={handleInvoiceArray}
                        addedInvoices={invoiceArray}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>{' '}
            {isArchive === false && track_package.length === 0 && (
              <CardFooter loading={loading} success={success} />
            )}{' '}
          </Form>
        )}
      </Formik>{' '}
    </div>
  )
}
