/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedMessage, useIntl} from 'react-intl'
import {IParcelProds, Parcel} from '../../core/_models/_parcels-models'

type Props = {
  items: IParcelProds[]
  item: IParcelProds
  parcel: Parcel
}

export function ParcelItemCard({items, item, parcel}: Props) {
  const intl = useIntl()
  const {name, qty} = item

  return (
    <>
      <div className='col-sm-6 col-xl-4'>
        <div className='card h-100 border border-2 border-gray-300 border-hover'>
          <div className='card-body p-9'>
            <div className='d-flex justify-content-between align-items-center mb-7'>
              <a href='#' className='fs-3 fw-bolder text-gray-700 '>
                {name}
              </a>
            </div>

            <div className='d-flex flex-wrap mb-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <span className='fs-6 text-gray-800 fw-bolder me-2'>{qty}</span>
                <span className='fw-bold text-gray-400'>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.CARD_QTY' />
                </span>
              </div>

              {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                <span className='fs-6 text-gray-800 fw-bolder me-2'>${cost}</span>
                <span className='fw-bold text-gray-400'>
                  {intl.formatMessage({id: 'TRACK_PAGE.CONTENT_TAB.CARD_COST'})}
                </span>
              </div> */}

              {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <span className='fs-6 text-gray-800 fw-bolder me-2'>${total}</span>
                <span className='fw-bold text-gray-400'>
                  {intl.formatMessage({id: 'TRACK_PAGE.CONTENT_TAB.CARD_TOTAL'})}
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
