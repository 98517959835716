import {useEffect, useRef} from 'react'
import {Tab} from 'bootstrap'
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
  ScrollTopComponent,
  StickyComponent,
  ToggleComponent,
  SwapperComponent,
} from '../assets/ts/components'
import {ThemeModeComponent} from '../assets/ts/layout'

import {useLayout} from './core'
import {getWebsiteData} from '../../app/modules/general/core/_requests'
import {establishOwnerFromDomain} from '../helpers/custom/funcs/establishOwnerFromDomain'

export function MasterInit() {
  const {config, setWebsiteData} = useLayout()
  const isFirstRun = useRef(true)

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }
  }
  useEffect(() => {
    enableSplashScreen()
  }, [])
  const pluginsInitialization = () => {
    isFirstRun.current = false
    ThemeModeComponent.init()
    setTimeout(() => {
      ToggleComponent.bootstrap()
      ScrollTopComponent.bootstrap()
      DrawerComponent.bootstrap()
      StickyComponent.bootstrap()
      MenuComponent.bootstrap()
      ScrollComponent.bootstrap()
      SwapperComponent.bootstrap()
      document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
        Tab.getOrCreateInstance(tab)
      })
    }, 500)
  }
  const getWD = () => {
    let owner = establishOwnerFromDomain()

    getWebsiteData(owner)
      .then(({data}) => {
        data.logoPrep = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

        setWebsiteData(data)
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      getWD()

      pluginsInitialization()
    } // eslint-disable-next-line
  }, [config])

  return <></>
}
