/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../../../../modules/custom/modals/ActionConfirmation'
import {ModalNotification} from '../../../../../../../modules/custom/modals/ModalNotification'
import {useQueryResponse} from '../../../core/QueryResponseProvider'
import {Receiver} from '../../../core/_models'
import {deleteReceiver, updateReceiver} from '../../../core/_requests'

type Props = {
  receiver: Receiver
}

const ReceiverActionsCell: FC<Props> = ({receiver}) => {
  const intl = useIntl()
  const {refetch} = useQueryResponse()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const deleteMutation = useMutation<any, Error>(
    async () => {
      return await deleteReceiver(receiver.id)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setDeleteModalOpen(false)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const enableDisable = async (boolean: boolean) => {
    await updateReceiver({enabled: boolean}, receiver.id)

    refetch()
  }

  return (
    <div className='d-flex justify-content-center align-items-center flex-shrink-0'>
      {deleteModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={intl.formatMessage({id: 'RECEIVERS.PAGE.LIST.DELETE_CONF'})}
              setModalOpen={setDeleteModalOpen}
              handleAction={() => deleteMutation.mutate()}
              id={receiver.id}
              isOpen={deleteModalOpen}
            />
          }
          className='modal-root'
        />
      )}
      <Link
        to={`/fulfillment/receivers/edit/overview/${receiver.id}`}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </Link>
      <OverlayTrigger
        placement='left'
        overlay={
          <Tooltip>
            {intl.formatMessage({
              id: receiver.enabled === true ? 'GENERAL.BUTTONS.BLOCK' : 'GENERAL.BUTTONS.ACTIVATE',
            })}
          </Tooltip>
        }
      >
        <button
          className='btn btn-icon btn-bg-light  btn-sm me-1'
          type='button'
          onClick={() => enableDisable(!receiver.enabled)}
        >
          {' '}
          <KTSVG
            path={
              receiver.enabled
                ? '/media/icons/duotune/general/gen040.svg'
                : '/media/icons/duotune/general/gen043.svg'
            }
            className={`svg-icon-3 svg-icon-${
              receiver.enabled ? 'danger' : 'success'
            } svg-bg-white`}
          />
        </button>
      </OverlayTrigger>
      {receiver.removable === 1 && (
        <button
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => setDeleteModalOpen(true)}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      )}
    </div>
  )
}

export {ReceiverActionsCell}
