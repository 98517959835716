import {FormattedMessage} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {Track} from '../../core/_models/_tracks-models'
import WarningNote from '../../tracks-list/components/WarningNote'

interface Props {
  track: Track
  isArchive: boolean
}

export const TrackHeaderNav = ({track, isArchive}: Props) => {
  const location = useLocation()
  const {id, prods, field_state} = track

  const pagePath = isArchive === true ? '/tracks/info' : '/tracks/edit'

  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `${pagePath}/overview/${id}` && 'active')
            }
            to={`${pagePath}/overview/${id}`}
          >
            <FormattedMessage id='TRACK_PAGE.GEN_TAB' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `${pagePath}/content/${id}` && 'active')
            }
            to={`${pagePath}/content/${id}`}
          >
            <FormattedMessage id='TRACK_PAGE.CONTENT_TAB' values={{qty: prods.length}} />{' '}
            {(field_state.model === false || field_state.user_name === false) && (
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className={
                  `svg-icon-3 svg-icon-danger me-1 ` +
                  (location.pathname === `${pagePath}/invoice/${id}`)
                }
              />
            )}
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `${pagePath}/parcel/${id}` && 'active')
            }
            to={`${pagePath}/parcel/${id}`}
          >
            <FormattedMessage id='TRACK_PAGE.TYPE_TAB' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `${pagePath}/invoice/${id}` && 'active')
            }
            to={`${pagePath}/invoice/${id}`}
          >
            <span className='me-2'>
              <FormattedMessage id='TRACK_PAGE.INVOICE_TAB' />
            </span>

            {field_state.invoice === false && (
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon-3 svg-icon-danger me-1'
              />
            )}
          </Link>
        </li>{' '}
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `${pagePath}/control/${id}` && 'active')
            }
            to={`${pagePath}/control/${id}`}
          >
            <span className='me-2'>
              <FormattedMessage id='TRACK_PAGE.INSPECTION_TAB' />
            </span>
            {track.check_control === 1 && !track.check_control_answer ? (
              <WarningNote problematicEntries={[['check', false]]} />
            ) : (
              <KTSVG
                path='/media/icons/duotune/technology/teh002.svg'
                className={
                  `svg-icon-4 svg-icon-grey me-1 ` +
                  (location.pathname === `${pagePath}/control/${id}` && 'svg-icon-success')
                }
              />
            )}
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `${pagePath}/status/${id}` && 'active')
            }
            to={`${pagePath}/status/${id}`}
          >
            <FormattedMessage id='TRACK_PAGE.STATUSES_TAB' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `${pagePath}/all-parcels/${id}` && 'active')
            }
            to={`${pagePath}/all-parcels/${id}`}
          >
            <FormattedMessage id='TRACK_PAGE.PARCELS_TAB' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `${pagePath}/receiver/${id}` && 'active')
            }
            to={`${pagePath}/receiver/${id}`}
          >
            <span className='me-2'>
              <FormattedMessage id='TRACK_PAGE.RECEIVER_TAB' />
            </span>
            <KTSVG
              path='/media/icons/duotune/communication/com006.svg'
              className={
                `svg-icon-4 svg-icon-grey me-1 ` +
                (location.pathname === `${pagePath}/receiver/${id}` && 'svg-icon-success')
              }
            />
          </Link>
        </li>
      </ul>
    </div>
  )
}
