import {nanoid} from 'nanoid'
import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useQuery, useQueryClient} from 'react-query'
import {QUERIES} from '../../../_metronic/helpers'
import {getWarehouseData} from './core/_requests'
import {WarehouseItem} from './WarehouseItem'

export function Warehouse() {
  const [copied, setCopied] = useState<string[]>([])
  const queryClient = useQueryClient()

  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const {data: whAddressesData} = useQuery('warehouseAddress', getWarehouseData)

  return (
    <>
      {whAddressesData?.map(
        ({
          id,
          countryConfig,
          addressData,
          addressHidden,
          secondaryAddressData,
          secondaryAddressHidden,
          addressName,
          secondaryAddressName,
        }) =>
          (addressHidden === false && addressData.length > 0) ||
          (secondaryAddressHidden === false && secondaryAddressData?.length > 0) ? (
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view' key={id}>
              <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                  <h3 className='fw-bold m-0'>
                    {/* <FormattedMessage id='USA_WAREHOUSE.PAGE.PAGE_TITLE' />{' '} */}
                    {countries[countryConfig.country]}
                  </h3>
                </div>
              </div>
              <div className='card-body p-9 d-flex justify-content-left'>
                {!addressHidden && (
                  <div className={`${secondaryAddressHidden === true ? 'col-lg-6' : 'col'}`}>
                    <h4 className={`mb-5 text-center w-100 ${addressName ? '' : 'opacity-0'}`}>
                      {addressName || 'placeholder'}
                    </h4>
                    {countryConfig.locationAddressFields?.map((fieldName, index) => {
                      const value = addressData[index]
                      return (
                        <WarehouseItem
                          title={fieldName}
                          value={value}
                          key={nanoid()}
                          copied={copied}
                          setCopied={setCopied}
                        />
                      )
                    })}
                  </div>
                )}
                {!secondaryAddressHidden && secondaryAddressData?.length > 0 && (
                  <div className={`${addressHidden === true ? 'col-lg-6' : 'col'}`}>
                    <h4
                      className={`mb-5 text-center w-100 ${
                        secondaryAddressName ? '' : 'opacity-0'
                      }`}
                    >
                      {secondaryAddressName || 'placeholder'}
                    </h4>

                    {countryConfig.locationAddressFields?.map((fieldName, index) => {
                      const value = secondaryAddressData[index]
                      return (
                        <WarehouseItem
                          title={fieldName}
                          value={value}
                          key={nanoid()}
                          copied={copied}
                          setCopied={setCopied}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )
      )}
    </>
  )
}
