import {IDashboardItem} from './core/_models'
import {DashboardItem} from './DashboardItem'

interface Props {
  items: IDashboardItem[]
}

export const DashboardColumnWrapper = ({items}: Props) => {
  return (
    <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
      {items.map((item, index) => (
        <DashboardItem item={item} key={index} className='h-md-50 mb-5 mb-xl-10' />
      ))}
    </div>
  )
}
