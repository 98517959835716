import React, {FC} from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../helpers'

interface Props {
  withTooltip?: boolean
  placement?: 'left' | 'right' | 'top' | 'bottom'
}

const ToggleReportDrawer = ({withTooltip = true, placement = 'left'}: Props) =>
  withTooltip === true ? (
    <OverlayTrigger
      placement={placement}
      overlay={
        <div
          className='fs-7 fw-normal px-3 py-2 bg-primary text-white me-2'
          style={{boxShadow: '0px 1px 5px #8f8f8f', borderRadius: '5px'}}
        >
          <FormattedMessage id='DRAWERS.COMPLAIN.TOGGLE' />
        </div>
      }
    >
      <button
        id='kt_report_toggle'
        className='btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm rounded'
      >
        <KTSVG path='/media/icons/duotune/communication/com002.svg' className='svg-icon-1' />
      </button>
    </OverlayTrigger>
  ) : (
    <div className='d-flex flex-gutter justify-content-left'>
      <button
        id='kt_report_toggle'
        className='fs-7 fw-normal px-3 py-2 bg-primary text-white me-2 btn-icon bg-hover-white text-hover-primary shadow-sm'
        style={{boxShadow: '0px 1px 5px #8f8f8f', borderRadius: '5px'}}
      >
        <KTSVG path='/media/icons/duotune/communication/com002.svg' className='svg-icon-1' />{' '}
        <FormattedMessage id='DRAWERS.COMPLAIN.TOGGLE' />
      </button>
    </div>
  )

export {ToggleReportDrawer}
