import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'
import {ParcelsListFilter} from './ParcelsListFilter'

const ParcelsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddParcelModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <ParcelsListFilter /> */}

      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}

      {/* <button type='button' className='btn btn-primary me-2' onClick={openAddParcelModal}>
        Оплатить
      </button>
      <span className='symbol symbol-50px'>
        <span className={`symbol-label fs-5 fw-bold bg-light-danger text-danger`}>$348</span>
      </span> */}
    </div>
  )
}

export {ParcelsListToolbar}
