import axios, {AxiosResponse} from 'axios'
import {ITtn, TtnsQueryResponse} from './_models'

const PRODS_URL = `${process.env.REACT_APP_API_PATH}/ttn`

const getProducts = (query: string): Promise<TtnsQueryResponse> => {
  return axios.get(`${PRODS_URL}?${query}`).then((d: AxiosResponse<TtnsQueryResponse>) => d.data)
}

const getProductsByParcel = (parcelCode: string): Promise<ITtn | undefined> => {
  return axios
    .get(`${PRODS_URL}?parcel.code=${parcelCode}`)
    .then((response: AxiosResponse<ITtn>) => response.data)
}

export {getProducts, getProductsByParcel}
