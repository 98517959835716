import {KTSVG} from '../../../../_metronic/helpers'

interface Props {
  symbol?: string
  children: any
  svg?: string
  addClass?: string
  containerClass?: string
  addSvgClass?: string
  svgContainerClass?: string
}

export function ModalNotification({
  symbol,
  svg,
  children,
  addClass = '',
  containerClass = '',
  addSvgClass = '',
  svgContainerClass = '',
}: Props) {
  return (
    <div className={`ModalNotification text-primary ${containerClass}`}>
      {symbol && (
        <div className={'ModalNotification__label fw-bold position-relative'}>
          <span> {symbol}</span>

          <div className={`ModalNotification__extended bg-white ${addClass}`}>{children}</div>
        </div>
      )}
      {svg && (
        <div className={`position-relative ${svgContainerClass}`}>
          <KTSVG path={svg} className={`svg-icon-2 ${addSvgClass}`} />
          <div className={`ModalNotification__extended bg-white ${addClass}`}>{children}</div>
        </div>
      )}

      {!svg && !symbol && (
        <div className={`ModalNotification__extended bg-white ${addClass}`}>{children}</div>
      )}
    </div>
  )
}
