/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedMessage} from 'react-intl'
import {Buyout} from '../../../../core/_models/_buyout-models'

type Props = {
  buyout: Buyout
}

const BuyoutItemsInfoCell = ({buyout}: Props) => {
  const {prods} = buyout
  return (
    <div>
      {prods.length > 4 ? (
        <>
          {prods?.slice(0, 4).map(({category, qty, cost, name}, index) => (
            <span key={index} className='text-muted fw-semobold text-muted d-block fs-7'>
              {!category?.name && !cost && !name
                ? ''
                : `${category?.name || name ? `${category?.name || name}` : '-'} x ${
                    qty ? `${qty}` : '-'
                  } x ${cost ? `$${cost}` : '-'}`}
            </span>
          ))}
          <span className='text-muted fw-semobold text-muted d-block fs-7'>
            <FormattedMessage
              id='GENERAL.COUNTABLES.MORE.GOODS'
              values={{itemCount: prods.length - 4}}
            />
          </span>
        </>
      ) : (
        prods?.map(({category, qty, cost, name}, index) => (
          <span key={index} className='text-muted fw-semobold text-muted d-block fs-7'>
            {!category?.name && !cost && !name
              ? ''
              : `${category?.name || name ? `${category?.name || name}` : '-'} x ${
                  qty ? `${qty}` : '-'
                } x ${cost ? `$${cost}` : '-'}`}
          </span>
        ))
      )}
    </div>
  )
}

export {BuyoutItemsInfoCell}
