import CopyToClipboard from 'react-copy-to-clipboard'
import {KTSVG} from '../../../_metronic/helpers'

interface Props {
  title: string
  value: string
  copied: string[]
  setCopied: (arr: string[]) => void
}

export function WarehouseItem({title, value, copied, setCopied}: Props) {
  const replacedTags = value?.replace(/<br\s?\/?>/g, ' ')
  return (
    <div className='row mb-7 d-flex align-items-center'>
      <label className='col fw-semibold text-muted'>
        <p className='m-0' dangerouslySetInnerHTML={{__html: title}}></p>
      </label>

      <div className='col ws-preline'>
        <span
          className='fw-bold fs-6 text-gray-800'
          dangerouslySetInnerHTML={{__html: value}}
        ></span>
        <CopyToClipboard
          text={replacedTags}
          onCopy={() => {
            setCopied([replacedTags])
          }}
        >
          <button type='button' className='highlight-copy btn'>
            <KTSVG
              path='/media/icons/duotune/general/gen054.svg'
              className={`svg-icon-3 ${
                copied.includes(replacedTags) ? 'svg-icon-dark' : 'svg-icon-gray-300'
              }`}
            />
          </button>
        </CopyToClipboard>
      </div>
    </div>
  )
}
