import {Field, useFormikContext} from 'formik'
import {FormattedMessage} from 'react-intl'

interface Props {
  checked: boolean
  disabled?: boolean
  value: number
  label: string
  fontSizeClass?: string
}

export const TypeOfDelivery = ({
  checked,
  disabled = false,
  value,
  label,
  fontSizeClass = 'fs-7',
}: Props) => {
  const {setFieldValue} = useFormikContext()
  return (
    <div className='col'>
      <label
        className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-4'
        data-kt-button='true'
      >
        <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start'>
          <Field
            className='form-check-input'
            type='radio'
            name='typeOfDelivery'
            value={value}
            disabled={disabled}
            checked={checked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('typeOfDelivery', +e.target.value)
            }}
          />
        </span>

        <span className={`${fontSizeClass} ms-2 fw-bold text-gray-800 d-block`}>
          <FormattedMessage id={label} />{' '}
        </span>
      </label>
    </div>
  )
}
