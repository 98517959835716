/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, ErrorMessage, useFormikContext} from 'formik'
import InputMask from 'react-input-mask'
import {ICrauzerUser} from '../../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'

type TProps = {
  user: Partial<ICrauzerUser> | undefined
  inputName?: string
  value?: string
  telegram?: boolean
}
type DynamicValues = Partial<ICrauzerUser> & {[key: string]: any}

function ProfileNumberItem({user, inputName = 'phoneNumber', value, telegram = true}: TProps) {
  const {setFieldValue, values} = useFormikContext<DynamicValues>()

  // const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {}
  return (
    <div className='position-relative p-0'>
      <Field
        name={inputName}
        render={() => (
          <InputMask
            name={inputName}
            type='tel'
            maskChar={null}
            mask='+38 (099) 999-99-99'
            className={`form-control form-control-lg form-control-solid mb-5`}
            value={value || values[inputName]}
            onChange={(e) => {
              setFieldValue(inputName, e.target.value.replace(/[^0-9+]/g, ''))
            }}
          />
        )}
      />
      {/* {!!telegram && !!user.hasTelegram && user.phoneNumberTelegramConfirmed && (
        <span className={`fs-6 fw-normal InputTemplate_addSymbol `}>
          <KTSVG path='/media/custom/svg/telegram-app.svg' className='svg-icon-1' />
        </span>
      )} */}

      <div className='text-danger'>
        <ErrorMessage name={inputName} />
      </div>
    </div>
  )
}

export {ProfileNumberItem}
