import {useListView} from '../../core/ListViewProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import ListSearch from '../../../../../modules/custom/lists/ListSearch'

const TtnsListHeader = () => {
  const {selected} = useListView()
  const {updateState} = useQueryRequest()
  return (
    <div className='card-header border-0 pt-6'>
      <ListSearch updateState={updateState} />
    </div>
  )
}

export {TtnsListHeader}
