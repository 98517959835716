import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {Field} from 'formik'

interface Props {
  setFieldValue: ISetFieldValue
  title: string
  value: boolean
}

function PatronymicCheckbox({setFieldValue, title, value}: Props) {
  return (
    <div className='d-flex'>
      <label
        className='d-flex position-relative fs-6 justify-content-left     
         fw-bold mb-3 me-1'
      >
        <Field
          className='form-check-input me-2'
          type='checkbox'
          name='noPatronymic'
          value={1}
          onChange={(e: any) => {
            setFieldValue('noPatronymic', e.target.checked)
          }}
          checked={value}
        />

        <div className='mb-2'>
          {' '}
          <span className='fs-6'>{title}</span>
        </div>
      </label>
    </div>
  )
}

export {PatronymicCheckbox}
