import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {useQuery} from 'react-query'
import {CustomRow} from './columns/CustomRow'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../core/QueryResponseProvider'
import {parcelsColumns} from './columns/_columns'
import {ParcelsListLoading} from '../components/loading/ParcelsListLoading'
import {ParcelsListPagination} from '../components/pagination/ParcelsListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {DateRow, Parcel} from '../../core/_models/_parcels-models'
import {FormattedMessage, useIntl} from 'react-intl'

const ParcelsTable = () => {
  const intl = useIntl()

  const parcels = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const reformedParcels = parcels
    .map((item) => [
      {
        debt: item.debt,
        on_border: item.on_border,
        send_date: item.send_date,
        delivered: item.delivered,
        settings: item.settings,
        ttns: item.packages?.some((item: Parcel) => {
          return item.ttn
        }),
      },
      ...item.packages,
    ])
    .flat(2)

  const data = useMemo(() => reformedParcels, [reformedParcels])
  const columns = useMemo(() => parcelsColumns, [])

  const {response: parcelsTotal} = useQueryResponse()

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='d-none d-md-block'>
        {parcelsTotal?.totalItems !== undefined && parcelsTotal?.itemsPerPage !== undefined && (
          <ParcelsListPagination
            showPagination={parcelsTotal?.totalItems > parcelsTotal?.itemsPerPage}
            showItemsPerPage={parcelsTotal?.totalItems <= 10 ? false : true}
          />
        )}
      </div>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Parcel | DateRow>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Parcel | DateRow>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    <FormattedMessage id='PARCELS.LIST.NO_RESULTS' />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {parcelsTotal?.totalItems !== undefined && parcelsTotal?.itemsPerPage !== undefined && (
        <ParcelsListPagination
          showPagination={parcelsTotal?.totalItems > parcelsTotal?.itemsPerPage}
          showItemsPerPage={parcelsTotal?.totalItems <= 10 ? false : true}
          addClass='mt-4 mt-md-0'
        />
      )}
      {isLoading && <ParcelsListLoading />}
    </KTCardBody>
  )
}

export {ParcelsTable}
