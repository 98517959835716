import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Parcel} from '../../core/_models/_parcels-models'
import {ParcelHeaderInfo} from './ParcelHeaderInfo'
import {ParcelHeaderShipment} from './ParcelHeaderShipment'
import {useState} from 'react'
import {ImageLightbox} from '../../../../modules/custom/images/ImageLightbox'
import ParcelHeaderNav from './ParcelHeaderNav'

interface Props {
  parcel: Parcel
}

const ParcelHeader = ({parcel}: Props) => {
  const {preview_files, c_code} = parcel

  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-center justify-content-md-between overflow-hidden overflow-md-auto'>
          {' '}
          <div className='d-flex flex-row w-100'>
            <div className='me-0 me-md-7 mb-4'>
              {preview_files && preview_files?.length > 0 ? (
                <div className='me-7 mb-4 cursor-zoom-in'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img
                      src={preview_files[0]}
                      width='45'
                      height='45'
                      alt='parcel'
                      onClick={openLightbox}
                      style={{objectFit: 'contain'}}
                    />

                    <button
                      type='button'
                      className='btn btn-sm position-absolute'
                      style={{top: '75%', right: '10px'}}
                      onClick={openLightbox}
                    >
                      <KTSVG
                        path='/media/icons/duotune/files/fil012.svg'
                        className='svg-icon-1 svg-icon-gray-900 me-1 position-absolute'
                      />
                      <span className='position-absolute top-75 end-0 text-white fs-8'>
                        {preview_files.length}
                      </span>
                    </button>
                    <ImageLightbox
                      viewerIsOpen={viewerIsOpen}
                      closeLightbox={closeLightbox}
                      currentImage={currentImage}
                      images={preview_files.map((item, index) => {
                        return {src: item, title: `${c_code}-${index + 1}`}
                      })}
                    />
                  </div>
                </div>
              ) : (
                <div className='symbol symbol-75px symbol-lg-160px symbol-fixed position-relative'>
                  {' '}
                  <img
                    src={toAbsoluteUrl('/media/icons/duotune/general/gen017.svg')}
                    alt='Parcel'
                  />
                </div>
              )}
            </div>
            <div className='d-flex flex-column'>
              <ParcelHeaderInfo parcel={parcel} />{' '}
              <div className='d-none d-md-block mw-md-450px'> {/* <HeaderMessage /> */}</div>
            </div>
          </div>
          <div className='d-md-none'> {/* <HeaderMessage /> */}</div>
          <ParcelHeaderShipment parcel={parcel} />
        </div>

        <ParcelHeaderNav parcel={parcel} />
      </div>
    </div>
  )
}

export {ParcelHeader}
