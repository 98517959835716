import {useEffect, useRef, useState} from 'react'
import {Overlay, Tooltip} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {copyToClipboard} from '../../../../../../../_metronic/helpers/custom/funcs/copyToClipboard'
import {ImageLightbox} from '../../../../../../modules/custom/images/ImageLightbox'
import {useListView} from '../../../../core/ListViewProvider'
import {Track} from '../../../../core/_models/_tracks-models'
import WarningNote from '../../../components/WarningNote'

type Props = {
  track: Track
}

const TrackNumberCell = ({track}: Props) => {
  useEffect(() => {
    if (track.track_package.length > 1) {
      console.log('here', track.track_code)
    }
  }, [])
  return (
    <div className='d-flex align-items-center mw-300px'>
      <Img track={track} />
      <div className='d-flex justify-content-start flex-column'>
        <NumberLine track={track} />
        <Receiver track={track} />
        <ProhibitedStatus track={track} />
        <Description track={track} />
      </div>
    </div>
  )
}

export {TrackNumberCell}

const Img = ({track}: Props) => {
  const {track_code, preview_files} = track
  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <div className='symbol symbol-45px me-5'>
      {preview_files && preview_files.length > 0 ? (
        <div className='cursor-zoom-in'>
          <div className='symbol symbol-fixed position-relative'>
            <img
              src={preview_files[0]}
              width='45'
              height='45'
              alt='track'
              onClick={openLightbox}
              style={{objectFit: 'contain'}}
            />

            <ImageLightbox
              viewerIsOpen={viewerIsOpen}
              closeLightbox={closeLightbox}
              currentImage={currentImage}
              images={preview_files.map((item, index) => {
                return {src: item, title: `${track_code}-${index + 1}`}
              })}
            />
          </div>
        </div>
      ) : (
        <img src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm010.svg')} alt='track' />
      )}{' '}
    </div>
  )
}
const NumberLine = ({track}: Props) => {
  const intl = useIntl()
  const {track_code, type_delive, dispatch, track_package, missing_fields, wp_data} = track

  const {setItemIdForUpdate, itemIdForUpdate} = useListView()
  const handleClick = async () => {
    if (itemIdForUpdate === track.id) {
      setItemIdForUpdate(undefined)
    } else {
      await setItemIdForUpdate(undefined)
      setItemIdForUpdate(track.id)
    }
  }

  const getFalseFields = (entries: Record<string, boolean>) => {
    return Object.entries(entries).filter(([key, value]) => value === false)
  }

  const falseEntries = getFalseFields(track.field_state)

  const [show, setShow] = useState(false)
  const target = useRef(null)

  const notComplControl = track.check_control === 1 && !track.check_control_answer
  const codeValueRef = useRef(null)
  const showWarning =
    (missing_fields && wp_data?.current?.finish !== 1 && track_package.length === 0) ||
    notComplControl === true

  return (
    <div className='d-flex align-items-center mb-1'>
      <div className='d-flex me-2 mt-2 cursor-pointer h-25px' onClick={handleClick}>
        <span className='me-1 text-dark fw-bold fs-6'>
          {type_delive == 0 ? (
            <KTSVG path='/media/icons/flight.svg' className='svg-icon-primary svg-icon-3' />
          ) : (
            <KTSVG path='/media/icons/anchor.svg' className='svg-icon-primary svg-icon-3' />
          )}
        </span>
        <span className='text-dark fw-bold fs-6' ref={codeValueRef}>
          {track_code}
        </span>{' '}
      </div>{' '}
      {!dispatch && showWarning === true && (
        <WarningNote
          problematicEntries={
            notComplControl === true ? [...falseEntries, ['check', false]] : falseEntries
          }
        />
      )}
      <div
        ref={target}
        onClick={() => {
          copyToClipboard(codeValueRef, setShow)
          setTimeout(() => {
            setShow(false)
          }, 1000)
        }}
      >
        <KTSVG
          path='/media/icons/duotune/general/gen054.svg'
          className={`svg-icon-3 ${show ? 'svg-icon-dark' : 'svg-icon-gray-600 cursor-pointer'}`}
        />
      </div>{' '}
      <Overlay target={target.current} show={show} placement='right'>
        {(props) => (
          <Tooltip {...props}>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})} </Tooltip>
        )}
      </Overlay>
    </div>
  )
}
const Receiver = ({track}: Props) => {
  const {receiver} = track
  return (
    <>
      {receiver && receiver.id !== 0 && (
        <span className='mb-1 fs-8 fw-semobold'>{`${receiver.lastName} ${receiver.firstName} ${
          receiver.patronymic || ''
        }`}</span>
      )}
    </>
  )
}
const ProhibitedStatus = ({track}: Props) => {
  const {dispatch, track_package, wp_data, description} = track

  return (
    <>
      {dispatch === 0 && wp_data?.current?.finish !== 1 && track_package?.length === 0 && (
        <span
          className={`text-muted fw-semobold text-muted d-block fs-7 ${description ? 'mb-2' : ''}`}
        >
          <span className={`p-1 fs-8 fw-semobold StatusBar__status StatusBar_blocked`}>
            <FormattedMessage id='TRACKS.PAGE.LIST.NUMBER.PROH_STATUS' />
          </span>
        </span>
      )}
    </>
  )
}
const Description = ({track}: Props) => {
  const {description} = track
  return (
    <>
      {description && (
        <span className='text-muted fw-semobold text-muted fs-8 pe-3 truncate'>{description}</span>
      )}
    </>
  )
}
