import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {IParcelTrack, Parcel} from '../../core/_models/_parcels-models'

interface Props {
  parcel: Parcel
}

export function ParcelHeaderShipment({parcel}: Props) {
  const {ttn, tracks} = parcel
  const [activeTab, setActiveTab] = useState(ttn ? 'ttn' : 'tracks')

  const tableTracks = tracks
    .map((item: IParcelTrack) => item.track_code)
    .filter((item) => item !== null)
  return (
    <div className='min-w-25'>
      <div className='d-flex flex-column align-items-center align-items-md-end fs-8 h-lg-50 my-3 my-md-0'>
        {(ttn || tracks.length > 0) && (
          <>
            <div className='card-toolbar mb-2'>
              <ul className='nav flex-nowrap'>
                <li className='nav-item d-block me-1'>
                  {' '}
                  <button
                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                      activeTab === 'tracks' ? 'active' : ''
                    } fw-bold px-4`}
                    onClick={() => {
                      setActiveTab('tracks')
                    }}
                  >
                    <FormattedMessage id='PARCELS.P_PAGE.TRACKS_SECTION' />
                  </button>
                </li>
                {ttn && (
                  <li className='nav-item'>
                    {' '}
                    <button
                      className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                        activeTab === 'ttn' ? 'active' : ''
                      } fw-bold px-4`}
                      onClick={() => {
                        setActiveTab('ttn')
                      }}
                    >
                      <FormattedMessage id='PARCELS.P_PAGE.TTN_SECTION' />
                    </button>
                  </li>
                )}
              </ul>
            </div>
            <div className='text-end fs-7'>
              {activeTab === 'ttn' && <p className='pe-2'>{ttn}</p>}
              {activeTab === 'tracks' && (
                <>
                  {tracks.slice(0, 4).map((item, index) => (
                    <p key={index} className='pe-2 mb-2'>
                      {item.track_code}
                    </p>
                  ))}

                  {tracks.length > 4 && (
                    <div onClick={scrollToTabsStart}>
                      <Link
                        to={`/parcels/info/tracks/${parcel.id}`}
                        className='text-muted fw-semobold text-muted d-block fs-7'
                      >
                        <FormattedMessage
                          id='GENERAL.COUNTABLES.MORE.TRACKS'
                          values={{itemCount: tracks.length - 4}}
                        />
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className='d-flex flex-wrap flex-stack'>
        {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Идёт отгрузка на складе...</span>
                 
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
                <span className='fs-8'>Осталось 5 дней бесплатного хранения на складе</span>
              </div> */}
      </div>
    </div>
  )
}
