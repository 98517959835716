import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {TtnsTable} from './table/TtnsTable'

import {useEffect} from 'react'
import {getSavedPagination, initialQueryState, KTCard} from '../../../../_metronic/helpers'
import {TtnsListHeader} from './components/header/TtnsListHeader'

const TtnsList = () => {
  const {updateState} = useQueryRequest()
  const {itemIdForUpdate, selected, showSelectSummary, setShowSelectSummary} = useListView()

  useEffect(() => {
    const retrievedData = getSavedPagination()

    updateState({
      ...initialQueryState,
      itemsPerPage: retrievedData,
    })
  }, [])

  return (
    <>
      <KTCard>
        <TtnsListHeader />
        <TtnsTable />
      </KTCard>
    </>
  )
}

const TtnsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TtnsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TtnsListWrapper}
