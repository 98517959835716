import React from 'react'
import {Formik, Form, FormikValues, ErrorMessage} from 'formik'
import {handleFileUpload} from '../../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {useState} from 'react'
import {receiverOverviewSchema} from '../../../receivers-list/core/yup/receiver-page'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {CheckboxSwitcher} from '../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {ReceiverTypeCheckbox} from '../../../elements/ReceiverTypeCheckbox'
import {NewReceiverFullForm, Receiver} from '../../../receivers-list/core/_models'
import {addPassportScan, createReceiver} from '../../../receivers-list/core/_requests'
import {PatronymicCheckbox} from '../../../elements/PatronymicCheckbox'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {FileInput} from '../../../../../modules/custom/form-elements/file-inputs/FileInput'

interface Props {
  receiver: Receiver
  setCurrReceiver: (obj: Receiver) => void
}

export function ReceiverOverview({receiver, setCurrReceiver}: Props) {
  const intl = useIntl()
  const initialValues = {
    active: +receiver.active || '',
    firstName: receiver.firstName || '',
    lastName: receiver.lastName || '',
    patronymic: receiver.patronymic || '',
    noPatronymic: receiver.no_pat === 1 ? true : false,
    passport: receiver.passport,
    receiverType: [
      +receiver?.international === 1 ? 1 : 0,
      +receiver?.receiver === 1 ? 2 : 0,
    ].filter((item) => +item !== 0),
  }

  const [imageIsLoading, setImageIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    setLoading(true)
    setImageIsLoading(true)

    await handleFileUpload(e, setFieldValue, 'passport', 'file', addPassportScan, true)

    setImageIsLoading(false)
    setLoading(false)
  }

  const deleteImage = async (setFieldValue: ISetFieldValue) => {
    setFieldValue('passport', '')
  }

  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: Partial<NewReceiverFullForm>, actions: FormikValues) => {
    console.log('values', values)
    const {receiverType, noPatronymic, firstName, lastName, patronymic, passport} = values
    setLoading(true)
    const activeChanged = values.active !== initialValues.active

    const payload: Partial<Receiver> = {
      firstName,
      lastName,
      patronymic,
      // @ts-ignore
      passport: passport?.filename || null,
      id: receiver.id,
      international: receiverType?.includes(1) ? 1 : 0,
      receiver: receiverType?.includes(2) ? 1 : 0,
      no_pat: noPatronymic === true ? 1 : 0,
    }

    if (activeChanged) {
      payload.active = values.active
    }

    const response = await createReceiver(payload)

    if (response) {
      setCurrReceiver(response)
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <>
      {receiver ? (
        <div className='card mb-5 mb-xl-10'>
          <Formik
            validationSchema={receiverOverviewSchema(intl)}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({values, setFieldValue, errors}) => (
              <Form noValidate className='form'>
                {' '}
                {/* {JSON.stringify(values)} */}
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Статус</label>
                    <div className='col-lg-8'>
                      <div className='row flex-nowrap'>
                        <div className='col-lg-6 fv-row me-4'>
                          <CheckboxSwitcher
                            inputName='active'
                            firstOption={intl.formatMessage({id: 'GENERAL.STATUSES.BLOCKED'})}
                            secondOption={intl.formatMessage({id: 'GENERAL.STATUSES.ACTIVE'})}
                            // @ts-ignore
                            value={+values.active}
                            setFieldValue={setFieldValue}
                            bgColor={{first: 'var(--kt-success)', second: 'var(--kt-danger)'}}
                          />
                        </div>{' '}
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      <FormattedMessage id='RECEIVERS.REC_PAGE.FULL_NAME_LABEL' />
                    </label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row'>
                          <InputTemplate
                            inputName='firstName'
                            required={false}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'RECEIVERS.REC_PAGE.NAME_PLACEHOLDER',
                            })}
                            addSymbol={
                              <KTSVG
                                path='/media/icons/duotune/communication/com006.svg'
                                className='svg-icon-3'
                              />
                            }
                            symbolMarginTop={false}
                          />
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <InputTemplate
                            inputName='lastName'
                            required={false}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'RECEIVERS.REC_PAGE.LAST_NAME_PLACEHOLDER',
                            })}
                            addSymbol={
                              <KTSVG
                                path='/media/icons/duotune/communication/com006.svg'
                                className='svg-icon-3'
                              />
                            }
                            symbolMarginTop={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>{' '}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row'>
                          <InputTemplate
                            inputName='patronymic'
                            required={false}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'RECEIVERS.REC_PAGE.PATRONYMIC_PLACEHOLDER',
                            })}
                            addSymbol={
                              <KTSVG
                                path='/media/icons/duotune/communication/com006.svg'
                                className='svg-icon-3'
                              />
                            }
                            symbolMarginTop={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        {' '}
                        <PatronymicCheckbox
                          setFieldValue={setFieldValue}
                          title={intl.formatMessage({
                            id: 'RECEIVERS.ADD_REC_MODAL.NO_PATRONYMIC_CHECKBOX',
                          })}
                          value={values.noPatronymic || false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mb-6'>
                    <FileInput
                      title={intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE',
                      })}
                      inputName='passport'
                      inputId={`${receiver.id}-receiver-file-input`}
                      uploadedFile={{
                        base: values.passport?.base_url || '',
                        link: values.passport?.filename || '',
                      }}
                      setFieldValue={setFieldValue}
                      uploadFunction={saveImage}
                      deleteFunction={deleteImage}
                      placeholder={intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE',
                      })}
                      notification={{
                        bool: true,
                        text: intl.formatMessage({
                          id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE.PROMPT',
                        }),
                        borderTop: false,
                      }}
                      required={true}
                      loading={imageIsLoading}
                      linkEndpoint='/passport'
                    />
                  </div>
                  <div className='row'>
                    <label className='d-flex col-lg-4 col-form-label fw-bold fs-6 position-relative required'>
                      <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.REC_TYPE' />
                    </label>
                    <div className='col-lg-8'>
                      <div className='row '>
                        {[
                          {
                            val: 1,
                            label: intl.formatMessage({
                              id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.INTER',
                            }),
                            noteText: intl.formatMessage({
                              id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.INTER.DESC',
                            }),
                          },
                          {
                            val: 2,
                            label: intl.formatMessage({
                              id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.FINAL',
                            }),
                            noteText: intl.formatMessage({
                              id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.FINAL.DESC',
                            }),
                          },
                        ].map(({val, label, noteText}) => (
                          <ReceiverTypeCheckbox
                            key={val}
                            chosenTypes={values.receiverType || []}
                            setFieldValue={setFieldValue}
                            title={label}
                            typeValue={val}
                            noteText={noteText}
                          />
                        ))}
                      </div>{' '}
                      <div className='text-danger'>
                        <ErrorMessage name='receiverType' />
                      </div>
                    </div>
                  </div>
                </div>
                <CardFooter loading={loading} success={success} />
              </Form>
            )}
          </Formik>{' '}
        </div>
      ) : (
        <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
      )}
    </>
  )
}
