/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {FormattedDate, FormattedMessage, FormattedTime} from 'react-intl'
import {statusColor} from '../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {ITtn} from '../../../core/_models'

type Props = {
  ttn: ITtn
}

const TtnStatusCell: FC<Props> = ({ttn}) => {
  return (
    <div>
      {ttn?.status && (
        <div>
          <span
            className={`p-1 fs-8 fs-md-7 me-2 mb-1 fw-semobold  StatusBar__status ${statusColor(
              ttn.status.name
            )}`}
          >
            <FormattedMessage id={`TTNS.STATUS_COL.${ttn.status.name.toUpperCase()}`} />
          </span>
          {ttn.status.date && (
            <div className='d-flex flex-column'>
              <span className='text-dark fw-bold fs-6'>
                <FormattedDate
                  year='numeric'
                  month='numeric'
                  day='numeric'
                  value={new Date(ttn.status.date)}
                />
              </span>
              <span className='text-muted fw-normal fs-6'>
                <FormattedTime
                  value={new Date(ttn.status.date)}
                  hour='numeric'
                  minute='numeric'
                  second='numeric'
                />
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export {TtnStatusCell}
