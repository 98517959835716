import {useListView} from '../../../core/ListViewProvider'
import {ParcelsListToolbar} from './ParcelsListToolbar'
import {ParcelsListGrouping} from './ParcelsListGrouping'
import {useQueryRequest} from '../../../core/QueryRequestProvider'
import ListSearch from '../../../../../modules/custom/lists/ListSearch'

const ParcelsListHeader = () => {
  const {selected} = useListView()
  const {updateState} = useQueryRequest()

  return (
    <div className='card-header border-0 pt-6 justify-content-between'>
      <ListSearch updateState={updateState} />

      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ParcelsListGrouping /> : <ParcelsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ParcelsListHeader}
