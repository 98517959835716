import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProvider'
import {prodsColumns} from './columns/_columns'
import {TtnsListLoading} from '../components/loading/TtnsListLoading'
import {TtnsListPagination} from '../components/pagination/TtnsListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {ITtn} from '../core/_models'
import {FormattedMessage} from 'react-intl'

const TtnsTable = () => {
  const prods = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => prods, [prods])
  const columns = useMemo(() => prodsColumns, [])
  console.log('d', prods)

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const {response: goodsTotal} = useQueryResponse()

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_goods'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<ITtn>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<ITtn>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    <FormattedMessage id='TTNS.LIST.NO_RESULTS' />{' '}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {goodsTotal?.totalItems !== undefined && goodsTotal?.itemsPerPage !== undefined && (
        <TtnsListPagination
          showPagination={goodsTotal?.totalItems > goodsTotal?.itemsPerPage}
          showItemsPerPage={goodsTotal?.totalItems <= 10 ? false : true}
        />
      )}
      {isLoading && <TtnsListLoading />}
    </KTCardBody>
  )
}

export {TtnsTable}
