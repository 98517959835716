/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {Formik, Form, FormikValues} from 'formik'
import {AddressShipment} from './address-shipment/AddressShipment'
import {PostShipment} from './post-shipment/PostShipment'
import {Modal} from 'react-bootstrap'
import {
  IShipmentAddress,
  IShipmentAddressForm,
} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {editShipmentAddressSchema} from '../../../../../../_metronic/helpers/custom/yup/yup-schemas/addresses'
import {SearchQueryProps} from '../Filter'
import {useQueryClient, useMutation} from 'react-query'
import {FormattedMessage, useIntl} from 'react-intl'
import {shapeAddressData} from '../../../../../../_metronic/helpers/custom/funcs/shapeAddressData'

interface Props extends SearchQueryProps {
  setIsOpen: (bool: boolean) => void
  isOpen: boolean
  dataForEditing: IShipmentAddress
  profileAddresses: IShipmentAddress[]
  id: number | string
  tempType: boolean
  apiMutation: {
    apiRequest: (data: any, id?: string | number) => Promise<any>
    toInvalidate: string
    id?: string | number
  }
  vector: boolean
}

const EditShipmentAddress = ({
  setIsOpen,
  isOpen,
  id,
  dataForEditing,
  tempType,
  apiMutation,
  vector,
  profileAddresses,
}: Props) => {
  const {address, mainOffice, reserveOffice, city, post, mainAddress} = dataForEditing

  const dynamicInits = post
    ? {
        mainOfficeCity: mainOffice?.city.id || '',
        mainOfficeNumber: mainOffice?.id,
        reserveOfficeCity: reserveOffice?.city.id || '',
        reserveOfficeNumber: reserveOffice?.id,
      }
    : {
        houseNumber: address?.houseNumber || '',
        houseLetter: address?.houseLetter || '',
        street: address?.street.id || '',
        premisesType: address?.apartment ? 'apartment' : 'office',
        apartment: address?.apartment || '',
        office: address?.office || '',
      }

  const inits: IShipmentAddressForm = {
    typeOfDelivery: post ? 2 : 1,
    deliveryService: {value: 1, label: 'Нова Пошта'},
    city: city.id,
    mainAddress: mainAddress === true,
    ...dynamicInits,
  }

  const [initValues] = useState<IShipmentAddressForm>(inits)
  const [submittedValues, setSubmittedValues] = useState<IShipmentAddress>(dataForEditing)
  const [submittedForm, setSubmittedForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()
  const queryClient = useQueryClient()

  const editAddressMutation = useMutation<any, Error>(
    async () => {
      if (apiMutation.toInvalidate === QUERIES.REC_ADDRESS_LIST) {
        return await apiMutation.apiRequest({...submittedValues, receiver_id: apiMutation.id})
      } else if (apiMutation.toInvalidate === QUERIES.FULF_REC_ADDRESS_LIST) {
        return await apiMutation.apiRequest({...submittedValues, id: dataForEditing.id})
      } else {
        return await apiMutation.apiRequest(submittedValues)
      }
    },
    {
      onSuccess: () => {
        if (apiMutation.toInvalidate === QUERIES.REC_ADDRESS_LIST) {
          queryClient.invalidateQueries(QUERIES.REC_ADDRESS_LIST)
        } else if (apiMutation.toInvalidate === QUERIES.FULF_REC_ADDRESS_LIST) {
          queryClient.invalidateQueries(QUERIES.FULF_REC_ADDRESS_LIST)
        } else {
          queryClient.invalidateQueries('profileAddresses')
        }

        setIsOpen(false)
      },
      onMutate: () => {},
      onError: (error) => {
        console.log(error)
      },
      onSettled: () => {
        setIsLoading(false)
      },
    }
  )

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleSubmit = async (values: IShipmentAddressForm, actions: FormikValues) => {
    console.log('values', values)
    setIsLoading(true)

    const dataToSend = await shapeAddressData(values, vector)

    // @ts-ignore
    setSubmittedValues({...dataToSend, id: dataForEditing.id})
    setSubmittedForm(true)

    actions.resetForm()
  }

  useEffect(() => {
    if (submittedForm) {
      editAddressMutation.mutate()
    }
  }, [submittedValues])

  return (
    <Modal
      className='modal fade'
      show={isOpen}
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      id='kt_modal_edit_sh_address'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>
            <FormattedMessage id='COMPONENTS.EditShipmentAddress.HEADING' />
          </h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={closeModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body py-lg-10 px-0 px-lg-10'>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <Formik
              validationSchema={editShipmentAddressSchema(intl)}
              initialValues={initValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({values, setFieldValue, errors}) => (
                <Form className='form' noValidate id='kt_modal_edit_sh_address_form'>
                  {/* {JSON.stringify(errors)}
                  {JSON.stringify(values)} */}

                  <div className='w-100'>
                    {' '}
                    <>
                      {+values.typeOfDelivery === 1 && (
                        <AddressShipment
                          setFieldValue={setFieldValue}
                          values={values}
                          editing={true}
                          dataForEditing={dataForEditing}
                          vector={vector}
                        />
                      )}
                      {+values.typeOfDelivery === 2 && (
                        <PostShipment
                          values={values}
                          editing={true}
                          dataForEditing={dataForEditing}
                          vector={vector}
                        />
                      )}
                    </>
                  </div>
                  <div className='d-flex flex-stack pt-10'>
                    <div>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary me-3'
                        disabled={isLoading}
                      >
                        {isLoading === true ? (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Подождите...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : (
                          <span className='indicator-label'>
                            {' '}
                            <FormattedMessage id='GENERAL.BUTTONS.SAVE' />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {EditShipmentAddress}
