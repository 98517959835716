import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {FloatingInputTemplate} from '../../form-elements/FloatingInputTemplate'
import {InputTemplate} from '../../form-elements/InputTemplate'

interface Props {
  inputName: string
  disabled: boolean
  value: string
  required: boolean
  placeholder?: string | null
  filterFunc: any
}

const UserName = ({inputName, disabled, value, required, placeholder, filterFunc}: Props) => {
  const intl = useIntl()
  return (
    <div>
      <InputTemplate
        inputName={inputName}
        type='text'
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        title={intl.formatMessage({id: 'COMPONENTS.UserName.TITLE'})}
        value={value}
        handleBlur={filterFunc}
      />
    </div>
  )
}

export default UserName
