export const transformTos = (htmlString: string): string => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')

  doc.querySelectorAll('a').forEach((link) => {
    const href = link.getAttribute('href')
    if (href) {
      if (href.startsWith('%')) {
        const passage = document.createElement('p')
        passage.setAttribute('id', href.slice(1))

        while (link.firstChild) {
          passage.appendChild(link.firstChild)
        }

        link?.parentNode?.replaceChild(passage, link)
      } else if (href.startsWith('#')) {
        link.removeAttribute('target')
        link.removeAttribute('rel')
      }
    }
  })

  return doc.body.innerHTML
}
