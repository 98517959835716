import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {AddTrackForm, ITrackFormProds} from '../../core/_models/_tracks-models'
import {Accordion} from 'react-bootstrap'
import {TrackGoodsItemBody} from './TrackGoodsItemBody'
import {TrackGoodsItemMinifiedHeader} from './TrackGoodsItemMinifiedHeader'
import {useListView} from '../../core/ListViewProvider'
import {useFormikContext} from 'formik'

interface Props {
  item: ITrackFormProds
  index: number
  arrayHelpers: any
  prods: ITrackFormProds[]
  setFieldValue: ISetFieldValue
  values: AddTrackForm
}

export function TrackGoodsItem({item, index, arrayHelpers, prods, setFieldValue, values}: Props) {
  const {itemIdForUpdate} = useListView()
  const {errors} = useFormikContext<AddTrackForm>()

  // console.log('er', errors)

  return (
    <>
      {index !== prods.length - 1 || itemIdForUpdate ? (
        <Accordion>
          <Accordion.Item eventKey={index.toString()}>
            <div
              className={`custom-recent-invoices-accordion-header ${
                errors.prods && errors.prods[index] ? 'error' : ''
              }`}
            >
              <Accordion.Header
                style={{
                  cursor: 'pointer',
                }}
              >
                <TrackGoodsItemMinifiedHeader item={item} />
              </Accordion.Header>
            </div>

            <Accordion.Body>
              <TrackGoodsItemBody
                index={index}
                item={item}
                arrayHelpers={arrayHelpers}
                prods={prods}
                setFieldValue={setFieldValue}
                values={values}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <TrackGoodsItemBody
          index={index}
          item={item}
          arrayHelpers={arrayHelpers}
          prods={prods}
          setFieldValue={setFieldValue}
          values={values}
        />
      )}
    </>
  )
}
