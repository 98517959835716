import {useRef, useState} from 'react'
import {Overlay, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {copyToClipboard} from '../../../../../_metronic/helpers/custom/funcs/copyToClipboard'
import {statusColor} from '../../../../../_metronic/helpers/custom/funcs/statusColor'
import {Track} from '../../core/_models/_tracks-models'

interface Props {
  track: Track
  isArchive: boolean
}
export function TrackHeaderInfo({track, isArchive}: Props) {
  const {
    track_code,
    type_delive,
    dispatch,
    weight,
    length,
    width,
    height,
    cost,
    prods,
    track_package,
  } = track
  const intl = useIntl()

  const codeRef = useRef(null)
  const [show, setShow] = useState(false)
  const target = useRef(null)
  return (
    <div className='flex-grow-1 w-100'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column overflow-auto overflow-md-unset overflow-lg-unset'>
          <div className='d-flex align-items-md-center mb-4 align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <div className='d-flex align-items-center mb-2 mb-md-0'>
              {+type_delive === 0 ? (
                <KTSVG
                  path='/media/icons/flight.svg'
                  className='svg-icon-primary svg-icon-3 me-1'
                />
              ) : (
                <KTSVG
                  path='/media/icons/anchor.svg'
                  className='svg-icon-primary svg-icon-3 me-1'
                />
              )}
              <p
                className='text-gray-800 m-0 fs-6 fs-md-2 fw-bolder me-3 d-none d-md-block'
                ref={codeRef}
              >
                {track_code}
              </p>
              <div className='d-md-none d-lg-none'>
                <OverlayTrigger placement={'top'} overlay={<Tooltip>{track_code}</Tooltip>}>
                  <p className='text-gray-800 m-0 fs-6 fs-md-2 fw-bolder me-3'>{track_code}</p>
                </OverlayTrigger>
              </div>
            </div>
            <div
              ref={target}
              onClick={() => {
                copyToClipboard(codeRef, setShow)
                setTimeout(() => {
                  setShow(false)
                }, 1000)
              }}
              className='me-2 mb-2 mb-md-0'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen054.svg'
                className={`svg-icon-3 ${
                  show ? 'svg-icon-dark' : 'svg-icon-gray-600 cursor-pointer'
                }`}
              />
            </div>{' '}
            <Overlay target={target.current} show={show} placement='top'>
              {(props) => (
                <Tooltip id='overlay-example' {...props}>
                  {intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})}{' '}
                </Tooltip>
              )}
            </Overlay>
            {isArchive === false && (
              <span
                className={`p-1 fs-8 fs-md-7 me-2 fw-semobold  StatusBar__status d-block ${statusColor(
                  dispatch
                )}`}
              >
                {dispatch === 1 ? (
                  <FormattedMessage id='GENERAL.STATUSES.SHIPMENT_ALLOWED' />
                ) : (
                  <FormattedMessage id='GENERAL.STATUSES.SHIPMENT_PROHIBITED' />
                )}
              </span>
            )}
          </div>

          <div className='d-flex flex-wrap fw-bold fs-7 mb-4 pe-2'>
            <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
              <KTSVG
                path='/media/icons/duotune/electronics/elc008.svg'
                className='svg-icon-4 me-1'
              />
              {weight ? `${weight} кг` : '--,'}
            </span>

            <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
              {`${height ? `${height}см` : '--'} x ${width ? `${width}см` : '--'} x ${
                length ? `${length}см` : '--'
              }`}
            </span>

            {prods.length > 0 ? (
              <div className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                <div className='me-2'>
                  {' '}
                  <KTSVG
                    path='/media/icons/duotune/ecommerce/ecm005.svg'
                    className={`svg-icon-3  svg-icon-gray-600 cursor-pointer me-1`}
                  />
                  <span>$</span>
                  <span>{cost}</span>
                </div>
                <div>
                  <KTSVG
                    path='/media/icons/duotune/ecommerce/ecm006.svg'
                    className={`svg-icon-3  svg-icon-gray-600 cursor-pointer me-1`}
                  />
                  <span>$</span>
                  <span>
                    {track_package.reduce((acc, i) => acc + +i.cost.delivery + +i.cost.mitn, 0)}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>{' '}
      </div>
    </div>
  )
}
