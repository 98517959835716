import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {Buyout} from '../../core/_models/_buyout-models'

interface Props {
  buyout: Buyout
}

export function BuyoutHeaderShipment({buyout}: Props) {
  const {tracking} = buyout
  const {parcels, tracks, ttn} = tracking

  const [activeTab, setActiveTab] = useState('track')

  const showTable = parcels.length > 0 || tracks.length > 0 || ttn.length > 0

  return (
    <div className='min-w-25'>
      <div className='d-flex flex-column align-items-center align-items-md-end fs-8 h-lg-50 my-3 mb-md-5'>
        {' '}
        {showTable === true && (
          <>
            <div className='card-toolbar mb-2'>
              <ul className='nav'>
                {tracks.length > 0 && (
                  <li className='nav-item me-1'>
                    {' '}
                    <button
                      className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                        activeTab === 'track' ? 'active' : ''
                      } fw-bold px-4`}
                      onClick={() => {
                        setActiveTab('track')
                      }}
                    >
                      <FormattedMessage id='GENERAL.ITEM_PAGE.TRACK_COLUMN' />
                    </button>
                  </li>
                )}
                {parcels.length > 0 && (
                  <li className='nav-item me-1'>
                    {' '}
                    <button
                      className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                        activeTab === 'parcel' ? 'active' : ''
                      } fw-bold px-4`}
                      onClick={() => {
                        setActiveTab('parcel')
                      }}
                    >
                      <FormattedMessage id='GENERAL.ITEM_PAGE.PARCEL_COLUMN' />
                    </button>
                  </li>
                )}

                {ttn.length > 0 && (
                  <li className='nav-item'>
                    <button
                      className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                        activeTab === 'ttn' ? 'active' : ''
                      } fw-bold px-4`}
                      onClick={() => {
                        setActiveTab('ttn')
                      }}
                    >
                      <FormattedMessage id='GENERAL.ITEM_PAGE.TTN_COLUMN' />
                    </button>
                  </li>
                )}
              </ul>
            </div>
            <div className='text-center'>
              {activeTab === 'track' &&
                tracks.map(({id, code}) => (
                  <p key={id} className='pe-2'>
                    {code}
                  </p>
                ))}

              {activeTab === 'parcel' &&
                parcels.map(({id, code}) => (
                  <p key={id} className='pe-2'>
                    {code}
                  </p>
                ))}
              {activeTab === 'ttn' &&
                ttn.map(({code}, index) => (
                  <p key={index} className='pe-2'>
                    {code}
                  </p>
                ))}
            </div>
          </>
        )}
      </div>

      {/* <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Идёт отгрузка на складе...</span>
                
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
                <span className='fs-8'>Осталось 5 дней бесплатного хранения на складе</span>
              </div>
            </div> */}
    </div>
  )
}
