/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Receiver} from '../../receivers-list/core/_models'
import {FormattedMessage} from 'react-intl'
import {ReceiverHeaderName} from './ReceiverHeaderName'
import {ReceiverHeaderInfo} from './ReceiverHeaderInfo'
import {scrollToTabsStart} from '../../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {ReceiverHeaderNav} from './ReceiverHeaderNav'

interface Props {
  receiver: Receiver
}

const ReceiverHeader = ({receiver}: Props) => {
  const location = useLocation()

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='Receiver' />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <ReceiverHeaderName receiver={receiver} />{' '}
              <div className='d-none d-md-block'>
                {' '}
                {/* <HeaderMessage messageText={admin_comment} author={'Админ'} /> */}
                <ReceiverHeaderInfo receiver={receiver} />
              </div>
            </div>
          </div>
          <div className='d-md-none'>
            {' '}
            {/* <HeaderMessage messageText={admin_comment} author={'Админ'} /> */}
            <ReceiverHeaderInfo receiver={receiver} />
          </div>
        </div>

        <ReceiverHeaderNav receiver={receiver} />
      </div>
    </div>
  )
}

export {ReceiverHeader}
