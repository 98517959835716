import axios from 'axios'
import {establishOwnerFromDomain} from '../../../../helpers/custom/funcs/establishOwnerFromDomain'
import {getConfig} from '../../../../i18n/Metronici18n'
import {AddFeedbackForm} from './_models'

const API = process.env.REACT_APP_API_PATH
const CRAUZER_BASE = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API}`

const addFeedback = async ({formData, website}: {formData: AddFeedbackForm; website: number}) => {
  const {selectedLang} = getConfig()

  const res = await axios.post(
    `${CRAUZER_BASE}/website_forms/submit`,
    {
      website: establishOwnerFromDomain(),
      form_type: 'feedback',
      data: formData,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )
  console.log('feedback res', res)
}

export {addFeedback}
