import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {ITtn} from '../../core/_models'
import {TtnNumberCell} from './cells/TtnNumberCell'
import {TtnStatusCell} from './cells/TtnStatusCell'
import {TtnReceiverCell} from './cells/TtnReceiverCell'
import {TtnParcelsCell} from './cells/TtnParcelsCell'

const prodsColumns: ReadonlyArray<Column<ITtn>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='TTNS.CODE_COL' className='min-w-50px' />
    ),
    id: 'code',
    Cell: ({...props}) => <TtnNumberCell ttn={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='TTNS.RECEIVER_COL' />,
    id: 'receiver',
    Cell: ({...props}) => <TtnReceiverCell ttn={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='TTNS.PARCELS_COL' />,
    id: 'parcels',
    Cell: ({...props}) => <TtnParcelsCell ttn={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='TTNS.STATUS_COL' className='min-w-150px' />
    ),
    id: 'status',
    Cell: ({...props}) => <TtnStatusCell ttn={props.data[props.row.index]} />,
  },

  //   {
  //     Header: (props) => (
  //       <CustomHeader tableProps={props} title='Действия' className='min-w-170px' />
  //     ),
  //     id: 'generalActions',
  //     // Cell: ({...props}) => <UserGeneralActions id={props.data[props.row.index].id} />,
  //   },
]

export {prodsColumns}
