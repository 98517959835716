import {useQueryClient} from 'react-query'
import {TPaymentOptions} from '../../../../app/pages/balance/core/_requests'
import {QUERIES} from '../../../helpers'
import {usePageData} from '../../../layout/core'

type TProps = {formik: any}

const Options = ({formik}: TProps) => {
  const queryClient = useQueryClient()
  const paymentOptions: TPaymentOptions = queryClient.getQueryData(QUERIES.PAYMENT_OPTIONS) || {}

  const {pageCustomData} = usePageData()

  return (
    <div>
      {Object.entries(paymentOptions).map(([key, value]) => (
        <label
          className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'
          key={key}
        >
          <span className='d-flex align-items-center me-2'>
            <span className='d-flex flex-column'>
              <span className='fw-bolder fs-6'>{value.title}</span>
              {/* <span className='fs-7 text-muted'>
                Creating a clear text structure is just one SEO
              </span> */}
            </span>
          </span>

          <span className='form-check form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='radio'
              name='paymentOption'
              value={key}
              checked={formik.values.paymentOption === key}
              onChange={() => {
                formik.setFieldValue('paymentOption', key)

                if (paymentOptions[key].currencies?.usd && pageCustomData?.checkoutSum) {
                  formik.setFieldValue(
                    'sumHrv',
                    parseFloat(
                      (+pageCustomData?.checkoutSum * +paymentOptions[key]?.currencies?.usd)
                        .toFixed(2)
                        .toString()
                    )
                  )
                }
              }}
            />
          </span>
        </label>
      ))}
    </div>
  )
}

export default Options
