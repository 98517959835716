export const handleFileUpload = async (
  event: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  fieldName: string,
  fileName: string,
  apiRequest: (name: string, file: File) => any | null,
  eraseTargetValue: boolean = true,
  setFieldValueProperty?: string
) => {
  const fileObj = event.target.files && event.target.files[0]

  if (!fileObj || !apiRequest) {
    return
  }

  const response = await apiRequest(fileName, fileObj)
  if (setFieldValueProperty) {
    setFieldValue(fieldName, response[setFieldValueProperty])
  } else if (response.filename === '') {
    setFieldValue(fieldName, [response])
  } else {
    setFieldValue(fieldName, response)
  }

  if (eraseTargetValue === true) {
    event.target.value = ''
  }
  return response
}
