/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../../../_metronic/helpers'
import {FormattedMessage} from 'react-intl'
import {ImageLightbox} from '../../images/ImageLightbox'
import {useState} from 'react'
import {IBuyoutLastMessage} from '../../../../pages/buyouts/vector/core/_models/_buyout-models'

interface Props {
  message: IBuyoutLastMessage | undefined
  id: string | number
}

export function HeaderMessage({id, message}: Props) {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <div className='d-flex flex-column flex-grow-1'>
      {message !== undefined && (
        <div className='d-flex flex-wrap flex-column border border-gray-300 border-dashed rounded mb-1'>
          {message.type === 'in' && (
            <div className='py-3 px-4'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='fs-5 fw-bolder'>
                  <FormattedMessage id='COMPONENTS.HeaderMessage.ADMIN' />{' '}
                </div>
              </div>

              <div className='fw-bold fs-7 text-gray-400 w-200px h-55px w-md-300px overflow-hidden'>
                {message.body}
              </div>
            </div>
          )}
          {message.type === 'out' && (
            <>
              <div className='pt-3 px-4 mb-3 d-flex justify-content-end'>
                <div className='text-end'>
                  <div className='d-flex align-items-center justify-content-end'>
                    <div className='fs-5 fw-bolder'>
                      <FormattedMessage id='COMPONENTS.HeaderMessage.CLIENT' />{' '}
                    </div>
                  </div>

                  <div className='fw-bold fs-7 text-gray-400 w-200px h-55px w-md-300px overflow-hidden'>
                    {message.body}
                  </div>
                  {message.attachment && (
                    <>
                      <a
                        href='#'
                        className='fs-9 text-hover-primary text-gray-500'
                        onClick={openLightbox}
                      >
                        <FormattedMessage id='GENERAL.BUTTONS.SEE_FILES' />{' '}
                      </a>
                      <ImageLightbox
                        viewerIsOpen={viewerIsOpen}
                        closeLightbox={closeLightbox}
                        currentImage={currentImage}
                        images={[{title: `unread`, src: message.attachment}]}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}{' '}
      {message ? (
        <div className='d-flex justify-content-end'>
          {' '}
          <button
            className='show-chat custom-chat-button btn btn-sm h-auto btn-color-gray-400 btn-active-color-primary fs-8 p-0 mt-1'
            data-id={id}
            data-type='Buyout'
            data-name={id}
          >
            <FormattedMessage id='COMPONENTS.HeaderMessage.OPEN_CHAT_BUTTON' />
            <KTSVG path='/media/icons/duotune/arrows/arr095.svg' className='svg-icon-2' />
          </button>
        </div>
      ) : (
        <div className='d-flex justify-content-end'>
          <button
            className='show-chat custom-chat-button btn btn-sm h-auto btn-color-gray-400 btn-active-color-primary fs-8 p-0 mt-1 text-start'
            data-id={id}
            data-type='Buyout'
            data-name={id}
          >
            <FormattedMessage id='COMPONENTS.HeaderMessage.START_CHAT_BUTTON' />
            <KTSVG path='/media/icons/duotune/arrows/arr095.svg' className='svg-icon-2' />
          </button>
        </div>
      )}
    </div>
  )
}
