import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {ITrackPackageItem, Track} from '../../core/_models/_tracks-models'

interface Props {
  track: Track
}

export function TrackHeaderShipment({track}: Props) {
  const {dispatch, track_package, on_storage, wp_data, id} = track
  const {total, current} = wp_data

  const ttns = track_package
    .map((item: ITrackPackageItem) => item.ttn)
    .filter((item) => item !== null)

  const parcels = track_package
    .map((item: ITrackPackageItem) => item.c_code)
    .filter((item) => item !== null)

  const [activeTab, setActiveTab] = useState(ttns.length > 0 ? 'ttn' : 'parcel')

  return (
    <div className='d-flex flex-column overflow-auto'>
      <div className='d-flex flex-column align-items-center align-items-md-end fs-8 my-3 mb-md-5'>
        {(ttns.length > 0 || parcels.length > 0) && (
          <>
            <div className='card-toolbar mb-2'>
              <ul className='nav'>
                {ttns.length > 0 && (
                  <li className='nav-item'>
                    {' '}
                    <button
                      className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                        activeTab === 'ttn' ? 'active' : ''
                      } fw-bold px-4`}
                      onClick={() => {
                        setActiveTab('ttn')
                      }}
                    >
                      <FormattedMessage id='GENERAL.ITEM_PAGE.TTN_COLUMN' />
                    </button>
                  </li>
                )}
                {parcels.length > 0 && (
                  <li className='nav-item'>
                    {' '}
                    <button
                      className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                        activeTab === 'parcel' ? 'active' : ''
                      } fw-bold px-4`}
                      onClick={() => {
                        setActiveTab('parcel')
                      }}
                    >
                      <FormattedMessage id='GENERAL.ITEM_PAGE.PARCEL_COLUMN' />
                    </button>
                  </li>
                )}
              </ul>
            </div>
            <div className='text-right fs-7'>
              {activeTab === 'parcel' && (
                <>
                  {parcels.slice(0, 4).map((item, index) => (
                    <p key={index} className='pe-2 mb-2'>
                      {item}
                    </p>
                  ))}

                  {parcels.length > 4 && (
                    <div onClick={scrollToTabsStart}>
                      <Link
                        to={`/tracks/edit/all-parcels/${id}`}
                        className='text-muted fw-semobold text-muted d-block fs-7'
                      >
                        <FormattedMessage
                          id='GENERAL.COUNTABLES.PARCELS'
                          values={{itemCount: parcels.length - 4}}
                        />
                      </Link>
                    </div>
                  )}
                </>
              )}
              {activeTab === 'ttn' &&
                ttns.length > 0 &&
                ttns.map((item, index) => (
                  <p key={index} className='pe-2'>
                    {item}
                  </p>
                ))}
            </div>
          </>
        )}
      </div>
      <div className='d-flex flex-wrap flex-stack mb-6 mb-md-0'>
        <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
          {current && (
            <>
              <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                <span className='fw-bold fs-6 text-gray-400'>{current.name}</span>
                {/* <span className='fw-bolder fs-6'>50%</span> */}
              </div>
              <div className='h-5px mx-3 w-100 bg-light mb-3'>
                <div
                  className='bg-success rounded h-5px'
                  role='progressbar'
                  style={{
                    width: `${Math.ceil((current.waypoint / total) * 100)}%`,
                  }}
                ></div>
              </div>
            </>
          )}
          {dispatch === 0 && on_storage && (
            <span className='fs-8'>
              <FormattedMessage id='GENERAL.COUNTABLES.DAYS_LEFT' values={{dayCount: on_storage}} />
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
