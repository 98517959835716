import {Field, Form, Formik, FormikHelpers} from 'formik'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {useMutation} from 'react-query'
import {useAuth} from '../../../../../app/modules/auth'
import {pollsApi} from '../../../../helpers/custom/api/pollsApi'
import {IPoll, ISavePoll, ISavePollVote} from '../../../../helpers/custom/tsHelpers/pollsHelpers'
import {getConfig} from '../../../../i18n/Metronici18n'
import {usePageData} from '../../../../layout/core'

const PollForm = ({poll}: {poll: IPoll}) => {
  const {selectedLang} = getConfig()
  const {addNotification} = usePageData()
  const {setCurrentUser, currentUser} = useAuth()

  const inits: Record<string, string | number> = poll.questions.reduce(
    (acc, q) => ({...acc, [q.id]: !!q.answers[0].answer ? +q.answers[0].id : ''}),
    {}
  )
  const submitPollMutation = useMutation(pollsApi.submitPoll, {
    onMutate: () => {
      // setIsLoading(true)
    },
    onSuccess: (data) => {
      addNotification({
        status: 'success',
        body: 'COMPONENTS.PollForm.SUCCESS_NOTIFICATION',
      })
      const closeButton: HTMLButtonElement | null = document.querySelector('#kt_poll_close')
      if (closeButton) {
        closeButton.click()
      }
      if (currentUser) {
        setCurrentUser({
          ...currentUser,
          availablePolls: currentUser?.availablePolls?.filter((i) => i !== poll.id) || [],
        })
      }
    },
    onError: (error) => {
      console.log(error)
    },
    onSettled: () => {
      // setIsLoading(false)
    },
  })

  const handleSubmit = (
    values: Record<string, string | number>,
    formikHelpers: FormikHelpers<Record<string, string | number>>
  ) => {
    const data: ISavePoll = {
      poll: {id: poll.id},
      votes: Object.entries(values)
        .filter(([k]) => !k.includes('-own'))
        .map(([k, v]) => {
          const final: ISavePollVote = {
            question: {id: k},
          }

          if (!v) {
            final.ownAnswer = values[`${k}-own`].toString()
          } else {
            final.answer = {id: v}
          }

          return final
        }),
    }

    console.log('to send', data)
    submitPollMutation.mutate(data)
    formikHelpers.resetForm()
  }
  return (
    <Formik
      // validationSchema={createFeedbackSchema(intl)}
      initialValues={inits}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({values, setFieldValue, errors, isSubmitting}) => (
        <Form>
          <div>
            {/* {JSON.stringify(values)} */}
            {poll.questions.map((q) => (
              <div key={q.id}>
                <h2 className='mb-3'>{q.translations[selectedLang]?.question}</h2>
                {q.answers
                  .filter((a) => a.translations[selectedLang]?.answer)
                  .map((a) => (
                    <label className='d-flex flex-stack mb-5 cursor-pointer'>
                      <span className='d-flex align-items-center me-2'>
                        {a.imageUrl && (
                          <span className='symbol symbol-75px me-6'>
                            <span className='symbol-label'>
                              <img
                                src={`${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${a.imageUrl}`}
                                width='75'
                              />
                            </span>
                          </span>
                        )}

                        <span className='d-flex flex-column'>
                          <span className='fw-bold text-gray-800 text-hover-primary fs-5'>
                            {a.translations[selectedLang]?.answer}
                          </span>
                        </span>
                      </span>

                      <span className='form-check form-check-custom form-check-solid'>
                        <Field
                          className='form-check-input'
                          type='radio'
                          name={q.id}
                          value={+a.id}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(q.id.toString(), +e.target.value)
                          }
                        />
                      </span>
                    </label>
                  ))}
                {q.allowOwnAnswer && (
                  <>
                    <label className='d-flex flex-stack mb-5 cursor-pointer'>
                      <span className='d-flex align-items-center me-2'>
                        <span className='d-flex flex-column'>
                          <span className='fw-bold text-gray-800 text-hover-primary fs-5'>
                            Свій варіант
                          </span>
                        </span>
                      </span>

                      <span className='form-check form-check-custom form-check-solid'>
                        <Field
                          className='form-check-input'
                          type='radio'
                          name={q.id}
                          value={''}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(q.id.toString(), '')
                          }
                        />
                      </span>
                    </label>
                    {values[q.id] === '' && (
                      <Field
                        className='form-control form-control-lg form-control-solid'
                        type='textarea'
                        name={`${q.id}-own`}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue(`${q.id}-own`, e.target.value)
                        }
                      />
                    )}
                  </>
                )}
              </div>
            ))}
            <div className='d-flex justify-content-center pt-7'>
              <button type='submit' disabled={isSubmitting} className='btn btn-primary'>
                {isSubmitting ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <FormattedMessage id='GENERAL.BUTTONS.WAIT' />

                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <FormattedMessage id='GENERAL.BUTTONS.SEND' />
                )}
              </button>
            </div>
          </div>{' '}
        </Form>
      )}
    </Formik>
  )
}

export default PollForm
