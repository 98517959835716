import {establishCountryFromOwner} from '../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'

export const pathnameValue = (type: string | undefined, intl: any) => {
  let result: {
    pageTitle: string
    deliveryType: null | 1 | 0
    trackPagePath: string
    creationIsAllowed: boolean
  } = {
    pageTitle: intl.formatMessage({id: 'TRACKS.PAGE.PAGE_HEADING'}),
    deliveryType: null,
    trackPagePath: 'edit/*',
    creationIsAllowed: true,
  }
  // console.log('type func', type === 'archive')

  if (type === 'archive') {
    result = {
      pageTitle: intl.formatMessage({id: 'TRACKS.PAGE.PAGE_HEADING_ARCH'}),
      deliveryType: null,
      trackPagePath: 'info/*',
      creationIsAllowed: false,
    }
  }
  if (type === 'sea') {
    result = {
      deliveryType: 1,
      pageTitle: intl.formatMessage({id: 'TRACKS.PAGE.PAGE_HEADING_SEA'}),
      trackPagePath: 'edit/*',
      creationIsAllowed: true,
    }
  }

  if (type === 'air') {
    result = {
      deliveryType: 0,
      pageTitle: intl.formatMessage({id: 'TRACKS.PAGE.PAGE_HEADING_AIR'}),
      trackPagePath: 'edit/*',
      creationIsAllowed: true,
    }
  }

  return result
}

export const filterShipmentOptions = (
  data: {value: string | number; label: string}[],
  intl: any
) => {
  return data.map((item) => {
    return {
      value: item.value,
      label: intl.formatMessage({id: item.label}, {country: establishCountryFromOwner().name}),
    }
  })
}
