import {FormattedMessage} from 'react-intl'
import {statusColor} from '../../../../../_metronic/helpers/custom/funcs/statusColor'
import {Receiver} from '../../receivers-list/core/_models'

interface Props {
  receiver: Receiver
}

export function ReceiverHeaderName({receiver}: Props) {
  return (
    <div className='flex-grow-1'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center mb-4 align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            {' '}
            <div className='d-flex align-items-center mb-2 mb-md-none'>
              <p className='text-gray-800 text-hover-primary fs-2 fw-bolder m-0 me-3'>
                {`${receiver.firstName} ${receiver.lastName} ${receiver.patronymic || ''}`}{' '}
              </p>
            </div>{' '}
            <span
              className={`p-1 me-2 fw-semobold  StatusBar__status ${statusColor(
                +receiver.verified
              )}`}
            >
              {+receiver.verified === 1 && <FormattedMessage id='GENERAL.STATUSES.VERIFIED' />}
              {+receiver.verified === 0 && <FormattedMessage id='GENERAL.STATUSES.VERIF_WAITING' />}
            </span>
          </div>{' '}
        </div>
      </div>
    </div>
  )
}
