import {FormattedMessage} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {Receiver} from '../../receivers-list/core/_models'

interface Props {
  receiver: Receiver
}

export function ReceiverHeaderNav({receiver}: Props) {
  const location = useLocation()
  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/fulfillment/receivers/edit/overview/${receiver.id}` &&
                'active')
            }
            to={`/fulfillment/receivers/edit/overview/${receiver.id}`}
          >
            <FormattedMessage id='RECEIVERS.REC_PAGE.PROFILE' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/fulfillment/receivers/edit/contacts/${receiver.id}` &&
                'active')
            }
            to={`/fulfillment/receivers/edit/contacts/${receiver.id}`}
          >
            <FormattedMessage id='RECEIVERS.REC_PAGE.CONTACTS' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/fulfillment/receivers/edit/shipment/${receiver.id}` &&
                'active')
            }
            to={`/fulfillment/receivers/edit/shipment/${receiver.id}`}
          >
            <FormattedMessage id='RECEIVERS.REC_PAGE.ADDRESS' />
          </Link>
        </li>
      </ul>
    </div>
  )
}
