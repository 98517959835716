import {KTSVG} from '../../../../../_metronic/helpers'
import getFileType from '../../../../../_metronic/helpers/custom/funcs/getFileType'
import {TUploadedFile} from '../../../../../_metronic/helpers/custom/tsHelpers/fileHelpers'
import {ImgThumb} from '../../images/ImgThumb'

interface Props {
  uploadedFile: TUploadedFile
  title: string
  loading: boolean
  linkEndpoint: string // e.g. '/passport'
  large?: boolean
  marginClass?: string
}

const ImgDocPreview = ({
  uploadedFile,
  title,
  loading,
  linkEndpoint,
  large = true,
  marginClass,
}: Props) => {
  const fileType = getFileType(
    uploadedFile?.link?.split('.')[uploadedFile?.link?.split('.').length - 1]
  )

  if (fileType === 1) {
    return (
      <a
        href={`${uploadedFile.base}${uploadedFile.link}`}
        target='_blank'
        rel='noreferrer noopener'
      >
        <KTSVG
          path='/media/svg/files/pdf.svg'
          className={`svg-icon-${large === true ? '4' : '3'}x svg-icon-gray-700`}
        />
      </a>
    )
  }
  return (
    <ImgThumb
      base={false}
      link={uploadedFile && uploadedFile.link ? uploadedFile.base + uploadedFile.link : ''}
      alt={title}
      loading={loading}
      linkEndpoint={linkEndpoint}
      marginClass={marginClass}
      dimensions={large === true ? 100 : 40}
    />
  )
}

export default ImgDocPreview
