import {useFormikContext} from 'formik'
import {FormattedMessage, useIntl} from 'react-intl'
import {IPostShimpentProps} from '../../../../../../../_metronic/helpers/custom/tsHelpers/locationsHelpers'
import {InputTemplate} from '../../../../form-elements/InputTemplate'

export const PostMain = ({
  citiesLoading,
  citiesResults,
  offLoading,
  offResults,
  values,
  filterCities,
  setQuery,
  titles,
  withHeadTitles,
}: IPostShimpentProps) => {
  const intl = useIntl()
  const {setFieldValue} = useFormikContext()

  return (
    <>
      {withHeadTitles === true && (
        <h3>
          <FormattedMessage id={titles.title} />
        </h3>
      )}

      <div className='d-flex justify-content-around flex-column'>
        <InputTemplate
          inputName='mainOfficeCity'
          required={true}
          type='select'
          title={intl.formatMessage({id: titles.cityTitle})}
          marginClass='me-4'
          value={values.mainOfficeCity}
          reactSelectprops={{
            options: citiesResults,
            isLoading: citiesLoading,
            onInputChange: filterCities,
            isClearable: true,
            selectName: 'mainOfficeCity',
            setFieldValue: setFieldValue,
            placeholder: intl.formatMessage({id: 'COMPONENTS.PostShipment.CITY_PLACEHOLDER'}),
            currentValue: citiesResults.find((item: any) => item.value === values.mainOfficeCity),
          }}
        />

        <InputTemplate
          inputName='mainOfficeNumber'
          required={true}
          type='select'
          title={intl.formatMessage({id: titles.officeTitle})}
          value={values.mainOfficeNumber}
          reactSelectprops={{
            options: offResults,
            isLoading: offLoading,
            onInputChange: setQuery,
            isClearable: true,
            selectName: 'mainOfficeNumber',
            setFieldValue: setFieldValue,
            placeholder: intl.formatMessage({id: titles.choosePlaceholder}),
            currentValue: offResults.find((item: any) => item.value === values.mainOfficeNumber),
          }}
        />
      </div>
    </>
  )
}
