import {useFormikContext} from 'formik'
import {FormattedMessage, useIntl} from 'react-intl'
import {ISettingsForm} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {useAuth} from '../../../../../modules/auth'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {Notification} from '../../../../../modules/custom/Notification'

const ForceClientDispatchSetting = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {setFieldValue, values} = useFormikContext<ISettingsForm>()
  return (
    <div>
      <span className='form-check-label d-flex flex-column align-items-start mb-5'>
        <span className='fw-bolder fs-5 '>
          <FormattedMessage id='PROFILE.PAGE.SETTINGS.SHIPMENT_PAID_BY_CLIENT' />:
        </span>
      </span>{' '}
      <div className='d-flex mw-500px '>
        <InputTemplate
          inputName='uast_force_dispatch'
          type='radio'
          title={intl.formatMessage({id: 'GENERAL.ACTIONS.ALLOW'})}
          checkboxProps={{
            checked: values.uast_force_dispatch === 1,
            disabled: false,
            setFieldValue: setFieldValue,
            value: 1,
            inputName: 'uast_force_dispatch',
            type: 'radio',
          }}
        />
        <InputTemplate
          inputName='uast_force_dispatch'
          type='radio'
          title={intl.formatMessage({id: 'GENERAL.ACTIONS.PROHIBIT'})}
          checkboxProps={{
            checked: values.uast_force_dispatch === 0,
            disabled: false,
            setFieldValue: setFieldValue,
            value: 0,
            inputName: 'uast_force_dispatch',
            type: 'radio',
          }}
        />{' '}
      </div>{' '}
      <Notification
        noteText={intl.formatMessage(
          {
            id: 'PROFILE.PAGE.SETTINGS.FORCE_DISPATCH_UA.NOTE',
          },
          {sum: currentUser?.min_package_uast}
        )}
      />
    </div>
  )
}

export default ForceClientDispatchSetting
