import {Dispatch, SetStateAction} from 'react'

const renderNotification = (setShow: Dispatch<SetStateAction<boolean>>) => {
  setShow(true)
  console.log('copied')
}

export const copyToClipboard = (
  ref: React.RefObject<HTMLInputElement>,
  setShow: Dispatch<SetStateAction<boolean>>
) => {
  let linkToCopy = ''
  if (ref && ref.current !== null) {
    linkToCopy = ref.current.outerText
  }
  renderNotification(setShow)
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(linkToCopy)
  } else {
    return document.execCommand('copy', true, linkToCopy)
  }
}
