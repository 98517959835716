import {FormattedMessage, useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {User} from '../../../../../modules/apps/user-management/users-list/core/_models'

interface Props {
  user: User
}

export function ProfileHeaderInfo({user}: Props) {
  return (
    <>
      {' '}
      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
        <Id user={user} />
        <Balance user={user} />
        <Address user={user} />
      </div>
      <div className='d-flex flex-wrap flex-stack'>
        <div className='d-flex flex-column flex-grow-1 pe-md-8'>
          <OrdersLinks user={user} />
        </div>
      </div>
    </>
  )
}

const Id = ({user}: Props) => {
  const intl = useIntl()
  const {externalId, id} = user
  return (
    <p className='d-flex align-items-center text-gray-400 text-hover-primary m-0 me-5 mb-2'>
      <KTSVG path='/media/icons/duotune/communication/com006.svg' className='svg-icon-4 me-1' />
      {intl.formatMessage({id: 'GENERAL.ID_LABEL'})}: {externalId ? externalId : id}
    </p>
  )
}
const Balance = ({user}: Props) => {
  const {balance, currency} = user
  return (
    <p className='d-flex align-items-center text-gray-400 text-hover-primary m-0 me-5 mb-2'>
      <span className='me-1'>
        {' '}
        <span> {currency}</span>
      </span>{' '}
      <span style={{color: balance ? (+balance >= 0 ? 'green' : 'red') : ''}}> {balance}</span>{' '}
    </p>
  )
}
const Address = ({user}: Props) => {
  const {city, street, house} = user
  return (
    <p className='d-flex align-items-center text-gray-400 text-hover-primary m-0 mb-2'>
      <KTSVG path='/media/icons/duotune/general/gen018.svg' className='svg-icon-4 me-1' />
      {`${street}, ${house ? house.toString().split(' ').join('') : ''}, ${city}`}
    </p>
  )
}
const OrdersLinks = ({user}: Props) => {
  const {packagesCount, ordersCount, tracksCount} = user

  const items = [
    {link: '/buyouts', count: ordersCount, titleId: 'BUYOUTS.PAGE.PAGE_TITLE'},
    {link: '/tracks/list/air', count: tracksCount, titleId: 'TRACKS.PAGE.PAGE_TITLE'},
    {link: '/parcels/list/air', count: packagesCount, titleId: 'PARCELS.PAGE.PAGE_TITLE'},
  ]

  return (
    <div className='d-flex flex-wrap flex-gutter'>
      {items.map((item, index) => (
        <OrdersLinksItem index={index} item={item} key={index} />
      ))}
    </div>
  )
}

type TProps = {
  item: {link: string; count: number | undefined; titleId: string}
  index: number
}
const OrdersLinksItem = ({item, index}: TProps) => {
  return (
    <div
      key={index}
      className='border border-gray-300 border-dashed rounded min-w-75px min-w-md-125px py-3 px-4 me-md-6 mb-3'
    >
      <Link to={item.link}>
        <div className='d-flex align-items-center'>
          <div className='fs-2 fw-bolder text-black text-hover-primary'>{item.count || 0}</div>
        </div>
      </Link>

      <div className='fw-bold fs-6 text-gray-400'>
        <FormattedMessage id={item.titleId} />
      </div>
    </div>
  )
}
