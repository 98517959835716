import {useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FormattedDate, FormattedTime, useIntl} from 'react-intl'
import {ITrackPackageItem, TTrackPackageItemProd} from '../../core/_models/_tracks-models'

interface TProps {
  track_package: ITrackPackageItem
}

function AllTrackParcelsRow({track_package}: TProps) {
  const intl = useIntl()
  return (
    <tr>
      <td>
        <div className='d-flex flex-column'>
          <span className='text-dark fw-bold fs-6'>
            <FormattedDate
              year='numeric'
              month='numeric'
              day='numeric'
              value={new Date(+track_package.sended_date * 1000)}
            />
          </span>
          <span className='text-muted fw-normal fs-6'>
            <FormattedTime
              value={new Date(+track_package.sended_date * 1000)}
              hour='numeric'
              minute='numeric'
              second='numeric'
            />
          </span>
        </div>
      </td>
      <td>
        <span className='text-dark text-hover-primary d-block fs-6'>{track_package.c_code}</span>
      </td>
      <td>
        <TrackProds prods={track_package.prods} />
      </td>
      <td>
        <span className='text-dark text-hover-primary d-block fs-6'>
          {track_package.weight} {intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}
        </span>
      </td>
      <td>
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip>{intl.formatMessage({id: `TRACK_PAGE.PARCELS_TAB.STAT.DEL`})} </Tooltip>
          }
        >
          <span className='text-dark text-hover-primary d-block fs-6'>
            {`$${+track_package.cost.delivery}`}
          </span>
        </OverlayTrigger>

        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip>{intl.formatMessage({id: `TRACK_PAGE.PARCELS_TAB.STAT.CUSTOMS`})} </Tooltip>
          }
        >
          <span className='text-dark text-hover-primary d-block fs-6'>
            {`$${+track_package.cost.mitn}`}
          </span>
        </OverlayTrigger>
      </td>
      <td>
        <p className='text-dark text-hover-primary d-block fs-6'>
          {track_package.tracks && track_package.tracks.map((i) => <span key={i}>{i}</span>)}
        </p>
      </td>
    </tr>
  )
}

export {AllTrackParcelsRow}

type TProdsProps = {prods: TTrackPackageItemProd[]}
const TrackProds = ({prods}: TProdsProps) => {
  const [showAllGoods, setShowAllGoods] = useState(false)

  return (
    <>
      {prods.length > 2 && !showAllGoods ? (
        <>
          {prods?.slice(0, 2).map(({cid, qty, cost, name}) => (
            <TrackProdsItem key={cid} name={name} qty={+qty} cost={+cost} cid={cid} />
          ))}
          <a
            className='text-muted fw-normal text-muted d-block fs-7 cursor-pointer text-hover-primary'
            onClick={() => setShowAllGoods(true)}
          >
            ...
          </a>
        </>
      ) : (
        <>
          {prods?.map(({cid, qty, cost, name}) => (
            <TrackProdsItem key={cid} name={name} qty={+qty} cost={+cost} cid={cid} />
          ))}

          {showAllGoods && (
            <a
              className='text-muted fw-normal text-muted d-block fs-7 cursor-pointer text-hover-primary'
              onClick={() => setShowAllGoods(false)}
            >
              Сховати
            </a>
          )}
        </>
      )}
    </>
  )
}

type TProdProps = {
  cid: string
  qty: number | string
  name: string
  cost: number | string
}
export const TrackProdsItem = ({name, qty, cost, cid}: TProdProps) => {
  return (
    <span className='text-muted fw-semobold text-muted d-block fs-7'>
      {!name && !name && !cost
        ? ''
        : `${name ? `${name}` : `${name}`}, ${qty ? `${qty}` : '-'} ${cost ? ` x$${cost}` : ''}`}
    </span>
  )
}
