import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import {usePageData} from '../../../../../_metronic/layout/core'
import {useListView} from '../../core/ListViewProvider'
import {ITrackCodeStatus} from '../../core/_models/_tracks-models'

interface Props {
  trackNumber: string
  trackCodeStatus: ITrackCodeStatus
  isLoading: boolean
  isValid: boolean
}

const OnePageModalHeader = ({trackNumber, trackCodeStatus, isLoading, isValid}: Props) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {setPageCustomData} = usePageData()

  return (
    <div
      className='modal-header px-6 py-2 bg-white position-fixed top-0 w-100 w-md-500px d-flex justify-content-between'
      style={{zIndex: 1, overflowX: 'hidden'}}
    >
      <h2 className='fw-bold fs-3 p-3'>
        <FormattedMessage
          id={itemIdForUpdate ? 'TRACKS.ADD_MODAL.HEADER.EDIT' : 'TRACKS.ADD_MODAL.HEADER.CREATE'}
          values={{trackNumber: ''}}
        />
        <p className='fw-normal fs-6 m-0 mt-1'>{trackNumber ? `${trackNumber}` : ''}</p>
      </h2>

      <div className='d-flex flex-gutter'>
        {!itemIdForUpdate && (
          <div>
            {trackCodeStatus.id && trackCodeStatus.id !== '' ? (
              <Link to={`/tracks/edit/overview/${trackCodeStatus.id}`}>
                <button type='button' className='btn btn-sm btn-primary minify-button fs-7 fs-md-5'>
                  <FormattedMessage id='GENERAL.BUTTONS.EDIT' />
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
                </button>
              </Link>
            ) : (
              <button
                type='submit'
                disabled={
                  !!itemIdForUpdate || isLoading === true || trackCodeStatus.success === false
                    ? true
                    : false
                }
                className='btn btn-sm btn-primary me-3 minify-button fs-7 fs-md-5'
              >
                <span className='indicator-label'>
                  {isLoading === true ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <FormattedMessage
                      id={itemIdForUpdate ? 'GENERAL.BUTTONS.SAVE' : 'GENERAL.BUTTONS.CREATE'}
                    />
                  )}
                </span>
              </button>
            )}
          </div>
        )}
        {itemIdForUpdate && (
          <Link to={`/tracks/edit/overview/${itemIdForUpdate}`}>
            <button type='button' className='btn btn-sm btn-primary minify-button fs-7 fs-md-5'>
              <FormattedMessage id='GENERAL.BUTTONS.EDIT' />
            </button>
          </Link>
        )}
        <div
          className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
          // data-kt-users-modal-action='close'
          onClick={() => {
            setItemIdForUpdate(undefined)
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </div>
      </div>

      {/* end::Close */}
    </div>
  )
}

export {OnePageModalHeader}
