import {Route, Routes, Outlet, Navigate, useParams} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'

import {useIntl} from 'react-intl'
import {TtnsListWrapper} from './ttns-list/TtnsList'

const TtnsPage = () => {
  const intl = useIntl()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle>{intl.formatMessage({id: 'TTNS.PAGE_TITLE'})}</PageTitle>
              <TtnsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to={`/ttns/list`} />} />
    </Routes>
  )
}

export default TtnsPage
