/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {FULF_RECEIVER_VERIFICATION_STATUS, ID} from '../../../../../../../../_metronic/helpers'
import {statusColor} from '../../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {Receiver} from '../../../core/_models'

type Props = {
  receiver: Receiver
}
const {VERIFIED, CHECKED, WAITING} = FULF_RECEIVER_VERIFICATION_STATUS

const ReceiverNameCell: FC<Props> = ({receiver}) => {
  const zeroPad = (num: ID, places: number) => String(num).padStart(places, '0')
  const forStatusColor = (stat: string) => (stat === VERIFIED ? 1 : stat === WAITING ? 2 : 0)
  return (
    <div className='d-flex align-items-center'>
      <Link to={`/fulfillment/receivers/edit/overview/${receiver.id}`}>
        {' '}
        <div className='d-flex flex-column'>
          <span className='fw-semobold text-black d-block fs-6 me-1'>
            {zeroPad(receiver.id, 5) || '-'}
          </span>{' '}
          <span className='fw-normal text-black d-block fs-7'>
            {receiver.firstName} {receiver.lastName}{' '}
            {receiver.patronymic ? receiver.patronymic : ''}
          </span>
          <div className='d-flex flex-gutter'>
            {receiver.verifStatus && (
              <span
                className={`p-1  fs-8 fw-semobold  StatusBar__status ${statusColor(
                  forStatusColor(receiver.verifStatus)
                )}`}
              >
                {receiver.verifStatus === VERIFIED && (
                  <FormattedMessage id='GENERAL.STATUSES.VERIFIED' />
                )}

                {receiver.verifStatus === CHECKED && (
                  <FormattedMessage id='GENERAL.STATUSES.CHECKED' />
                )}
                {receiver.verifStatus === WAITING && (
                  <FormattedMessage id='GENERAL.STATUSES.VERIF_WAITING' />
                )}
              </span>
            )}
            <span
              className={`p-1 fs-8 me-auto fw-semobold  StatusBar__status cursor-pointer ${statusColor(
                receiver.enabled
              )}`}
            >
              {receiver.enabled ? (
                <FormattedMessage id='GENERAL.STATUSES.ACTIVE' />
              ) : (
                <FormattedMessage id='GENERAL.STATUSES.BLOCKED' />
              )}
            </span>
          </div>
        </div>
      </Link>
    </div>
  )
}

export {ReceiverNameCell}
