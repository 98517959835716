import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProvider'
import {receiversColumns} from './columns/_columns'
import {Receiver} from '../core/_models'
import {ReceiversListLoading} from '../components/loading/ReceiversListLoading'
import {ReceiversListPagination} from '../components/pagination/ReceiversListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {FormattedMessage, useIntl} from 'react-intl'

const ReceiversTable = () => {
  const receivers = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => receivers, [receivers])
  const columns = useMemo(() => receiversColumns, [])
  const intl = useIntl()
  const {response: receiversTotal} = useQueryResponse()

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='d-none d-md-block'>
        {' '}
        {receiversTotal?.totalItems !== undefined && receiversTotal?.itemsPerPage !== undefined && (
          <ReceiversListPagination
            showPagination={receiversTotal?.totalItems > receiversTotal?.itemsPerPage}
            showItemsPerPage={receiversTotal?.totalItems <= 10 ? false : true}
          />
        )}
      </div>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Receiver>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Receiver>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    <FormattedMessage id='RECEIVERS.PAGE.LIST.NO_RESULTS' />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {receiversTotal?.totalItems !== undefined && receiversTotal?.itemsPerPage !== undefined && (
        <ReceiversListPagination
          showPagination={receiversTotal?.totalItems > receiversTotal?.itemsPerPage}
          showItemsPerPage={receiversTotal?.totalItems <= 10 ? false : true}
          addClass='mt-4'
        />
      )}
      {isLoading && <ReceiversListLoading />}
    </KTCardBody>
  )
}

export {ReceiversTable}
