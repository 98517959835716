import {ErrorMessage, useFormikContext} from 'formik'
import {useIntl} from 'react-intl'
import {receiverTypes} from '../receivers-list/core/data'
import {NewReceiverFullForm} from '../receivers-list/core/_models'
import {ReceiverTypeCheckbox} from './ReceiverTypeCheckbox'

interface Props {
  values: Partial<NewReceiverFullForm>
}

export const ReceiverType = ({values}: Props) => {
  const {setFieldValue} = useFormikContext()
  const intl = useIntl()
  return (
    <>
      <div className='row '>
        {receiverTypes(intl).map(({val, label, noteText}) => (
          <ReceiverTypeCheckbox
            key={val}
            chosenTypes={values.receiverType || []}
            setFieldValue={setFieldValue}
            title={label}
            typeValue={val}
            noteText={noteText}
          />
        ))}
      </div>
      <div className='text-danger'>
        <ErrorMessage name='receiverType' />
      </div>
    </>
  )
}
