import React, {useState, Dispatch, SetStateAction} from 'react'
import {Formik, Form} from 'formik'
import {VectorUserModel} from '../../../../../modules/auth'
import {profileApi} from '../../../../../../_metronic/helpers/custom/api/profileApi'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {handleFileUpload} from '../../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {TermsOfServiceModal} from './TermsOfServiceModal'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useIntl} from 'react-intl'
import {FileInput} from '../../../../../modules/custom/form-elements/file-inputs/FileInput'
import {crauzerProfileApi} from '../../../_requests'
import {ICrauzerUser} from '../../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'

interface Props {
  user: VectorUserModel
  setCurrentUser: Dispatch<SetStateAction<VectorUserModel | undefined>>
  crUser: ICrauzerUser | undefined
}

export function PassportTempTab({user, setCurrentUser, crUser}: Props) {
  const intl = useIntl()

  const initialValues: Partial<VectorUserModel> = {
    passport: user?.passport || {base_url: '', filename: ''},
  }

  const [loading, setLoading] = useState(false)
  const [imageIsLoading, setImageIsLoading] = useState(false)

  const [success, setSuccess] = useState(false)

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    setLoading(true)
    setImageIsLoading(true)

    await handleFileUpload(e, setFieldValue, 'passport', 'file', profileApi.addPassportScan, true)

    setImageIsLoading(false)
    setLoading(false)
  }

  const deleteImage = async (setFieldValue: ISetFieldValue) => {
    setFieldValue('passport', '')
  }

  const handleSubmit = async (values: Partial<VectorUserModel>) => {
    console.log('values', values)
    try {
      setLoading(true)
      const response = await profileApi.editProfileOverview({
        passport: values?.passport?.filename || null,
      })
      console.log('resp', response)

      if (response) {
        setCurrentUser(response)
        setLoading(false)

        setSuccess(true)

        setTimeout(() => {
          setSuccess(false)
        }, 3000)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <Formik
          //   validationSchema={profileOverviewSchema(intl)}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, errors, setFieldValue}) => (
            <Form noValidate className='form'>
              {/* {JSON.stringify(errors)} */}

              <div className='card-body border-top p-9'>
                <div className='mb-5'>
                  <FileInput
                    title={intl.formatMessage({id: 'PROFILE.PAGE.OVERVIEW.FIRST_PASSPORT_PAGE'})}
                    inputName='passport'
                    inputId={`${user.id}-profile-file-input`}
                    uploadedFile={{
                      base: values?.passport?.base_url || '',
                      link: values?.passport?.filename || '',
                    }}
                    setFieldValue={setFieldValue}
                    uploadFunction={saveImage}
                    deleteFunction={deleteImage}
                    placeholder={intl.formatMessage({
                      id: 'PROFILE.PAGE.OVERVIEW.FIRST_PASSPORT_PAGE',
                    })}
                    notification={{
                      bool: true,
                      text: intl.formatMessage({
                        id: 'PROFILE.PAGE.OVERVIEW.FIRST_PASSPORT_PAGE.PROMPT',
                      }),
                      borderTop: false,
                    }}
                    required={true}
                    loading={imageIsLoading}
                    linkEndpoint='/passport'
                  />
                </div>
              </div>
              <CardFooter loading={loading} success={success} />
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}
