import axios from 'axios'
import {getConfig} from '../../../i18n/Metronici18n'
import {establishBackendFromUrl} from '../funcs/establishOwnerFromDomain'
import {
  ICityStreet,
  ICrauzerAddressCities,
  ICrauzerNovaPoshtaOffice,
  INovaPoshtaOffice,
} from '../tsHelpers/locationsHelpers'
import {IAddressCities} from '../tsHelpers/locationsHelpers'

// API URL ELEMENTS
axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${establishBackendFromUrl()}`

const API = process.env.REACT_APP_API_PATH

const CRAUZER_BASE = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API}`

const ADDRESS_ENDPOINT = '/address'

// cities
type TApiCities = {city: string; vector: boolean; warehouse?: number; big?: number}
const getAddressCities = async ({city, vector, warehouse, big}: TApiCities) => {
  const {selectedLang} = getConfig()

  // const finalUrl =
  //   vector === true
  //     ? `${API}${ADDRESS_ENDPOINT}/cities`
  //     : `${API}/cities?translations.name=Чернігів`

  // const res = await axios.post(
  //   `${API}${ADDRESS_ENDPOINT}/cities`,
  //   {
  //     city: city,
  //     warehouse: warehouse || null,
  //     big: big || null,
  //   },
  //   {
  //     headers: {
  //       'Accept-Language': selectedLang,
  //     },
  //   }
  // )

  let finalUrl: string
  let requestData: object | null = null

  if (vector === true) {
    finalUrl = `${API}${ADDRESS_ENDPOINT}/cities`
    requestData = {
      city,
      warehouse: warehouse || null,
      big: big || null,
    }
  } else {
    finalUrl = `${CRAUZER_BASE}/cities?translations.name=${city}`
  }

  const res = await axios.request({
    method: vector ? 'post' : 'get',
    url: finalUrl,
    data: requestData,
    headers: {
      'Accept-Language': selectedLang,
    },
  })

  const reformedData = res.data.map((item: IAddressCities | ICrauzerAddressCities) => {
    let value
    if (vector === true) {
      const label = item.name.split(', ')
      value = {
        value: item.id,
        label: `${label.slice(0, label.length - 1).join(', ')} (${item.region})`,
      }
    } else {
      value = {
        value: item.id,
        label: `${item.name} (${item.region})`,
      }
    }
    return value
  })
  return reformedData
}

const getCityById = async (id: string) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/city`,
    {
      id: id,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  return data
}

// streets
const getCityStreets = async (query: string, cityId: string | number) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/streets`,
    {
      search: query,
      city: cityId,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )
  const reformedData = data.map((item: ICityStreet) => {
    return {
      value: item.ref,
      label: item.name,
    }
  })
  return reformedData
}

const getStreetById = async (id: string, city_id: string) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/street`,
    {
      id,
      city_id,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  return data
}

// NP offices
// types:
// 1 Parcel Shop
// 2 Поштове відділення
// 3 Поштомат приват банку
// 4 Вантажне отделение
// 5 Поштомат
const getNovaPoshtaOffices = async (
  query: string,
  cityId: string,
  vector: boolean,
  filter?: number[]
) => {
  const {selectedLang} = getConfig()

  let finalUrl: string
  let requestData: object | null = null

  const filterStringified = filter
    ? filter.length > 1
      ? filter.map((item) => `&type[]=${item}`).join('')
      : `&type=${filter}`
    : ''

  console.log(filterStringified)

  if (vector === true) {
    finalUrl = `${API}${ADDRESS_ENDPOINT}/warehouses`
    requestData = {
      search: query,
      city: cityId,
    }
  } else {
    finalUrl = `${CRAUZER_BASE}/warehouses?city=${cityId}&translations.name=${query}${filterStringified}`
  }

  const {data} = await axios.request({
    method: vector ? 'post' : 'get',
    url: finalUrl,
    data: requestData,
    headers: {
      'Accept-Language': selectedLang,
    },
  })

  const reformedData = data.map((item: INovaPoshtaOffice | ICrauzerNovaPoshtaOffice) => {
    return typeof item === 'object' && 'big' in item
      ? {value: item.id, label: item.name, big: item.big}
      : // big: true is temporary till Yevgen adds it to the api
        {value: item.id, label: item.name, big: true}
  })

  return reformedData
}

const getWarehouseById = async (id: string) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/warehouse`,
    {
      id: id,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  return data
}

// countries

const fetchDirectionCountriesList = async () => {
  const {selectedLang} = getConfig()
  const {data} = await axios.get(`${CRAUZER_BASE}/public/directions/countries`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return data
}

export const locationsApi = {
  getAddressCities,
  getCityStreets,
  getNovaPoshtaOffices,
  fetchDirectionCountriesList,
  getCityById,
  getWarehouseById,
  getStreetById,
}
