import axios, {AxiosResponse} from 'axios'
import {getConfig} from '../../../i18n/Metronici18n'
import {ID} from '../../crud-helper/models'
import {IPoll, IRejectPoll, ISavePoll} from '../tsHelpers/pollsHelpers'

const API_URL = process.env.REACT_APP_API_PATH

const ENDPOINT = `/polls`
const RESULTS_ENDPOINT = `/poll_results`

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API_URL}${ENDPOINT}`

const RESULTS_BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API_URL}${RESULTS_ENDPOINT}`

const getPollById = (id: ID): Promise<IPoll | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<IPoll>) => d.data)
}

const submitPoll = async (data: ISavePoll) => {
  const {data: response} = await axios.post(`${RESULTS_BASE_URL}`, data)

  // console.log(response)
  return response
}

const rejectPoll = async (data: IRejectPoll) => {
  const {data: response} = await axios.post(`${RESULTS_BASE_URL}`, data)

  // console.log(response)
  return response
}

const reschedulePoll = async (id: ID) => {
  const {data: response} = await axios.post(`${BASE_URL}/${id}/reschedule`, {})

  // console.log(response)
  return response
}

export const pollsApi = {
  getPollById,
  submitPoll,
  rejectPoll,
  reschedulePoll,
}
