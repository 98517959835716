import {FormattedMessage} from 'react-intl'
import {Receiver} from '../../../core/_models'

type Props = {
  receiver: Receiver
}

const ReceiverTypeCell = ({receiver}: Props) => {
  return (
    <div className='text-center'>
      {receiver.withinCountry === true && (
        <p className={`p-1 fs-7 fw-semobold bg-light StatusBar__status`}>
          <FormattedMessage id='RECEIVERS.STATUSES.LOCAL_REC' />
        </p>
      )}
      {receiver.international === true && (
        <p className={`p-1 fs-7 fw-semobold StatusBar__status bg-light`}>
          <FormattedMessage id='RECEIVERS.STATUSES.INTERN_REC' />
        </p>
      )}
      {receiver.final === true && (
        <p className={`p-1 fs-7 fw-semobold StatusBar__status bg-light`}>
          <FormattedMessage id='RECEIVERS.STATUSES.FINAL_REC' />
        </p>
      )}
    </div>
  )
}

export {ReceiverTypeCell}
