import React from 'react'
import {TypeOfDelivery} from '../../../../../pages/fulfillment/goods/_modals/SendGoods/inputs/TypeOfDelivery'

interface Props {
  values: {typeOfDelivery: number}
  fontSizeClass?: string
}

const DeliveryChoice = ({values, fontSizeClass}: Props) => {
  return (
    <>
      <TypeOfDelivery
        checked={values.typeOfDelivery === 1 ? true : false}
        value={1}
        label={'FULFILLMENT.SEND_GOODS_FORM.ADDRESS_TITLE'}
        fontSizeClass={fontSizeClass}
      />
      <TypeOfDelivery
        checked={values.typeOfDelivery === 2 ? true : false}
        value={2}
        label={'FULFILLMENT.SEND_GOODS_FORM.OFF_TITLE'}
        fontSizeClass={fontSizeClass}
      />
      <TypeOfDelivery
        checked={values.typeOfDelivery === 3 ? true : false}
        value={3}
        label={'FULFILLMENT.SEND_GOODS_FORM.CELL_TITLE'}
        fontSizeClass={fontSizeClass}
      />
    </>
  )
}

export default DeliveryChoice
