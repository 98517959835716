/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {setLanguage, useLang} from '../../../../_metronic/i18n/Metronici18n'
import {Dropdown} from 'react-bootstrap'

const languages = [
  // {
  //   lang: 'en',
  //   name: 'English',
  //   flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  // },
  {
    lang: 'ru',
    name: 'Русский',
    flag: toAbsoluteUrl('/media/flags/russia.svg'),
  },
  {
    lang: 'uk',
    name: 'Українська',
    flag: toAbsoluteUrl('/media/flags/ukraine.svg'),
  },
]

const Languages: FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  return (
    <Dropdown className='menu-state-bg menu-gray-800 menu-state-primary'>
      <Dropdown.Toggle variant='light' size='sm' id='dropdown-lang'>
        {currentLanguage?.name}{' '}
        <img className='w-15px h-15px rounded-1 ms-2' src={currentLanguage?.flag} alt='metronic' />
      </Dropdown.Toggle>

      <Dropdown.Menu className='menu-sub menu-sub-dropdown w-175px py-4 fw-bold'>
        {languages.map((l) => (
          <Dropdown.Item className='menu-item px-3' key={l.lang} as='div'>
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
              onClick={() => {
                setLanguage(l.lang)
              }}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export {Languages}
