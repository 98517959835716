import {Field, ErrorMessage} from 'formik'

interface Props {
  inputName: string
  placeholder?: string
  backgroundClass?: string
  inputFontSize?: string
  disabled?: boolean
}

export function TextareaTemplate({
  inputName,
  placeholder,
  backgroundClass,
  inputFontSize,
  disabled = false,
}: Props) {
  return (
    <Field
      as='textarea'
      // type='text'
      disabled={disabled}
      className={`form-control form-control-lg form-control-solid ${
        inputFontSize ? inputFontSize : ''
      } ${backgroundClass ? backgroundClass : ''}`}
      name={inputName}
      placeholder={placeholder ? placeholder : ''}
      onFocus={(e: React.FocusEvent<HTMLInputElement>) => (e.target.placeholder = '')}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
        (e.target.placeholder = `${placeholder ? placeholder : ''}`)
      }
    />
  )
}
