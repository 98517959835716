import {FieldArray} from 'formik'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {AddTrackForm, ITrackFormProds} from '../../core/_models/_tracks-models'
import {TrackGoodsItem} from './TrackGoodsItem'
import {FormattedMessage} from 'react-intl'
import {Dispatch, SetStateAction} from 'react'
import {useListView} from '../../core/ListViewProvider'
import _ from 'lodash'

interface Props {
  prods: ITrackFormProds[]
  setFieldValue: ISetFieldValue
  values: AddTrackForm
}

export function TrackGoods({prods, setFieldValue, values}: Props) {
  const {itemIdForUpdate} = useListView()

  return (
    <div className='d-flex flex-column'>
      <div className='w-100'>
        <FieldArray
          name='prods'
          render={(arrayHelpers) => (
            <div className='d-flex flex-column flex-col-gutter'>
              {prods.map((item, index) => (
                <TrackGoodsItem
                  key={index}
                  index={index}
                  item={item}
                  arrayHelpers={arrayHelpers}
                  prods={prods}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              ))}
              {!itemIdForUpdate && (
                <>
                  <div className='separator mb-3'></div>

                  <div>
                    <button
                      className='btn btn-sm btn-secondary d-flex align-items-center color-primary'
                      type='button'
                      onClick={() => {
                        arrayHelpers.push({
                          group: {label: '', value: ''},
                          name: {label: '', value: '', require_invoice: 0, brands: []},
                          brand: {value: '', label: ''},
                          qty: 1,
                          cost: '',
                          total: '',
                          repack: false,
                          used: false,
                          model: '',
                          user_name: '',
                        })
                      }}
                    >
                      <FormattedMessage id='GENERAL.BUTTONS.ADD_GOODS' />
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        />
      </div>
    </div>
  )
}
