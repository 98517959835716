import {
  GoodsCategoriesQueryResponse,
  IBuyoutCategorySelectOption,
  ICatBrandSelectOption,
  IGoodsCategoryItem,
  IGoodsGroupSelectOption,
  ITrackCategorySelectOption,
} from '../../../../../_metronic/helpers/custom/tsHelpers/categoriesHelpers'
import {useCallback, useEffect, useRef, useState} from 'react'
import {SearchCategorySelect} from './SearchCategorySelect'
import {SearchBrandSelect} from './SearchBrandSelect'
import {IBuyoutFormProds} from '../../../../pages/buyouts/vector/core/_models/_buyout-models'
import {ITrackFormProds} from '../../../../pages/tracks/core/_models/_tracks-models'
import UserName from './UserName'
import BrandModel from './BrandModel'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ID, selectDebounce} from '../../../../../_metronic/helpers'
import {getTrackBrandsByCat} from '../../../../pages/tracks/core/_requests'
import {findDOMNode} from 'react-dom'

interface Props {
  setFieldValue: ISetFieldValue
  inputName: {categories: string; groups: string; brands: string; user_name: string; model: string}
  noOptionsMessage?: string
  disabled?: boolean
  selectValues: {
    groups: IGoodsGroupSelectOption
    categories: IBuyoutCategorySelectOption | ITrackCategorySelectOption
    brands: ICatBrandSelectOption
  }
  apiRequest: (cat: string, groupId: string | number) => Promise<GoodsCategoriesQueryResponse>
  values: IBuyoutFormProds | ITrackFormProds
  delType: 'avia' | 'sea'
}

export function CategoriesWrapper({
  setFieldValue,
  inputName,
  noOptionsMessage = 'Не знайдено',
  disabled = false,
  apiRequest,
  selectValues,
  values,
  delType,
}: Props) {
  const [resultsLoading, setResultsLoading] = useState(false)

  const {categories, groups, brands} = selectValues

  const defaultBrand = categories.brands?.find((item) => item.default === 1)

  // const [groupsResults, setGroupsResults] = useState<IGoodsGroupSelectOption[]>([])
  const [categoriesResults, setCategoriesResults] = useState<
    IBuyoutCategorySelectOption[] | ITrackCategorySelectOption[]
  >([])

  // console.log('currbr', brands)

  const [brandsResults, setBrandsResults] = useState<ICatBrandSelectOption[]>(
    categories.brands && categories.brands.length > 0 ? categories.brands : []
  )

  const isFirstRun = useRef(true)

  // useEffect(() => {
  //   if (isFirstRun.current) {
  //     isFirstRun.current = false
  //     return
  //   }

  //   if (
  //     !groupsResults
  //       .find((item) => +item.value === +groups.value)
  //       ?.items?.find((item) => item.id === categories.value)
  //   ) {
  //     setFieldValue(inputName.categories, {value: '', label: ''})
  //   }
  //   filterCategories('')
  // }, [groups.value])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    setFieldValue(inputName.brands, {value: '', label: ''})
    // console.log('v', categories.value)

    // if (groups.value === '' && categories.group) {
    //   setFieldValue(inputName.groups, categories.group)
    // }
    // console.log('c', categories, categoriesResults)

    if (categories.brands && categories.brands?.length > 0) {
      console.log('brands', brands)
      console.log('length', categories.brands?.length)
      console.log('val', categories.value)
      console.log('def', defaultBrand)

      filterBrands(categories.brands)
      setFieldValue(inputName.brands, brands?.value ? brands : defaultBrand ? defaultBrand : brands)
      // } else if (brands && brands.value !== '' && categories.value !== '') {
      //   setBrandsResults([brands])

      //   setFieldValue(inputName.brands, brands)
    }
    // else {
    //   // console.log('set as empty')

    //   setBrandsResults([])
    //   // setFieldValue(inputName.brands, {value: '', label: ''})
    // }
  }, [categories.value, categories.brands])

  const getBrands = useCallback(
    selectDebounce((query: string) => {
      setResultsLoading(true)
      getTrackBrandsByCat(+values.name.value, query)
        .then((res) => {
          console.log('new brands res', res)

          if (res.length > 0 && selectValues.brands.id === defaultBrand?.id) {
            setFieldValue(inputName.brands, {
              ...res[0],
              value: res[0].id || '',
              label:
                res[0].commission && res[0].commission > 0
                  ? `${res[0].name} (+${res[0].commission}%)`
                  : res[0].name || '',
            })
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [values]
  )

  const filterBrands = (brands: ICatBrandSelectOption[]) => {
    const reformedBrands = brands.map((item) => {
      return {
        ...item,
        value: item.id || '',
        label:
          item.commission && item.commission > 0
            ? `${item.name} (+${item.commission}%)`
            : item.name || '',
      }
    })
    setBrandsResults(reformedBrands)
  }

  const filterCategories = useCallback(
    selectDebounce((query: string, callback?: any) => {
      setResultsLoading(true)
      apiRequest(query, '')
        .then((res) => {
          // const reformedGroups = res.map((item) => {
          //   return {
          //     ...item,
          //     value: item.id,
          //     label: item.name,
          //   }
          // })
          // setGroupsResults(reformedGroups)

          let reformedCategories: IBuyoutCategorySelectOption[] | ITrackCategorySelectOption[]
          const items = res
            .flatMap((group) =>
              group.items.map((item) => {
                return {...item, group: {value: group.id, label: group.name}}
              })
            )
            .filter((item) => item !== null)

          reformedCategories = items.map((item: IGoodsCategoryItem) => {
            return {
              ...item,
              value: item.id,
              label: item.translate,
              brands: item.brands.map((item) => {
                return {
                  ...item,
                  label:
                    item.commission && item.commission > 0
                      ? `${item.name} (+${item.commission}%)`
                      : item.name,
                  value: item.id,
                }
              }),
            }
          })

          setCategoriesResults(reformedCategories)
          if (callback) {
            callback(reformedCategories)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [apiRequest]
  )

  useEffect(() => {
    filterCategories('')
  }, [filterCategories])

  useEffect(() => {
    if (categories.brands) {
      filterBrands(categories.brands)
    }
  }, [categories.brands])

  return (
    <>
      {/* <SearchGroupSelect
        inputName={inputName.groups}
        setFieldValue={setFieldValue}
        value={groups}
        options={groupsResults}
        isLoading={resultsLoading}
      /> */}

      <SearchCategorySelect
        inputName={inputName.categories}
        setFieldValue={setFieldValue}
        value={categories}
        options={categoriesResults}
        isLoading={resultsLoading}
        onInputChange={filterCategories}
        selectValues={selectValues}
        apiRequest={apiRequest}
        setCategoriesResults={setCategoriesResults}
        setResultsLoading={setResultsLoading}
        disabled={disabled}
      />
      <UserName
        inputName={inputName.user_name}
        disabled={disabled}
        value={values?.user_name}
        required={categories.require_name ? +categories.require_name[delType] === 1 : false}
        placeholder={categories.name_hint}
        filterFunc={getBrands}
      />
      {/* {JSON.stringify(brandsResults)} */}
      {brandsResults.length > 0 && (
        <>
          {' '}
          <SearchBrandSelect
            inputName={inputName.brands}
            setFieldValue={setFieldValue}
            value={brands}
            options={brandsResults}
            isLoading={resultsLoading}
            disabled={disabled}
          />
          {brands?.require_model && +brands.require_model === 1 ? (
            <BrandModel inputName={inputName.model} disabled={disabled} value={values?.model} />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )
}
