/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {Portal} from '../../../../../../_metronic/partials'
import {BuyoutItemCard} from './BuyoutItemCard'
import {CreateBuyoutContentCard} from './_modals/CreateBuyoutContentCard'
import {Buyout} from '../../core/_models/_buyout-models'
import {CreateItemCard} from '../../../../../modules/custom/sections/CreateItemCard'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  buyout: Buyout
  isArchive: boolean
  setCurrBuyout: (obj: Buyout) => void
}

export function BuyoutContent({buyout, setCurrBuyout, isArchive}: Props) {
  const {prods, user_confirm} = buyout
  const intl = useIntl()

  const sortedProds = Object.values(prods).sort((a, b) => a.unavaliable - b.unavaliable)

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      {modalOpen && (
        <Portal
          children={
            <CreateBuyoutContentCard
              setIsOpen={setModalOpen}
              isOpen={modalOpen}
              buyout={buyout}
              setCurrBuyout={setCurrBuyout}
            />
          }
          className='modal-root'
        />
      )}
      <div className='d-flex flex-wrap flex-stack mb-10 '>
        <h3 className='fw-bolder my-2'>
          <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.HEADING' />
        </h3>
        <div className='d-flex flex-wrap'></div>
      </div>{' '}
      <div className='row g-6 g-xl-9'>
        {sortedProds.map((item) => (
          <BuyoutItemCard
            key={item.id}
            item={item}
            buyout={buyout}
            setCurrBuyout={setCurrBuyout}
            isArchive={isArchive}
          />
        ))}
        {isArchive === false && user_confirm === 0 && (
          <CreateItemCard
            itemName={intl.formatMessage({id: 'BUYOUT_PAGE.CONTENT_TAB.CREATE_GOODS'})}
            setModalOpen={setModalOpen}
          />
        )}
      </div>
    </>
  )
}
