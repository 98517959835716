import {ICatBrandSelectOption} from '../../../../../_metronic/helpers/custom/tsHelpers/categoriesHelpers'
import {
  IBasicSelect,
  ISetFieldValue,
} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FormattedMessage, useIntl} from 'react-intl'
import Select, {components, createFilter} from 'react-select'
import {customStyles} from '../../form-elements/select/ReactSelectTemplate'
import {ErrorMessage} from 'formik'

interface Props {
  setFieldValue: ISetFieldValue
  value: ICatBrandSelectOption
  options: ICatBrandSelectOption[]
  inputName: string
  isLoading: boolean
  noOptionsMessage?: string
  disabled?: boolean
}

export function SearchBrandSelect({
  setFieldValue,
  value,
  inputName,
  isLoading,
  noOptionsMessage = 'Не найдено',
  disabled = false,
  options,
}: Props) {
  const intl = useIntl()

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>{noOptionsMessage}</span>
      </components.NoOptionsMessage>
    )
  }

  const handleOnChange = (selectedValue: IBasicSelect | null) => {
    if (selectedValue !== null) {
      setFieldValue(inputName, selectedValue)
    } else {
      setFieldValue(inputName, {value: '', label: ''})
    }
  }
  return (
    <>
      <div className={`fv-row d-flex w-100 mb-5 flex-column`}>
        <label
          className={`d-flex position-relative  fs-5 flex-column fw-bold mb-2 w-100`}
          htmlFor={inputName}
        >
          <div className='mb-2'>
            {' '}
            <span className={`fs-6 required`}>
              <FormattedMessage id='COMPONENTS.SearchBrandSelect.BRAND' />
            </span>
          </div>
          <Select
            formatOptionLabel={({value, label, image}) => (
              <div className='tagify__dropdown__item d-flex align-items-center' tabIndex={0}>
                <div className='tagify__dropdown__item__avatar-wrap me-2'>
                  <img
                    alt=''
                    className='rounded-circle w-50px me-2'
                    src={image || '/media/icons/duotune/ecommerce/ecm005.svg'}
                  />
                </div>

                <div className='d-flex flex-column text-gray-800'>
                  <strong>{label}</strong>
                </div>
              </div>
            )}
            styles={customStyles}
            options={options}
            form={inputName}
            isClearable={!value?.value ? false : true}
            isDisabled={disabled}
            components={{NoOptionsMessage}}
            loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
            isLoading={isLoading}
            value={value}
            filterOption={createFilter()}
            onChange={handleOnChange}
          />
        </label>

        <div className='text-danger'>
          <ErrorMessage name={`${inputName}.value`} />
        </div>
      </div>
    </>
  )
}
