import {locationsApi} from '../api/locationsApi'
import {IShipmentAddressForm} from '../tsHelpers/profileHelpers'

export const shapeAddressData = async (values: IShipmentAddressForm, vector: boolean) => {
  const {
    apartment,
    city,
    houseLetter,
    houseNumber,
    mainAddress,
    office,
    street,
    premisesType,
    mainOfficeCity,
    mainOfficeNumber,
    reserveOfficeCity,
    reserveOfficeNumber,
  } = values

  let dataToSend = {}

  if (vector === true) {
    if (+values.typeOfDelivery === 1) {
      const cityObj = await locationsApi.getCityById(city)
      const streetObj = await locationsApi.getStreetById(street || '', city)
      console.log('streetObj', streetObj)

      dataToSend = {
        post: false,
        mainAddress,
        city: {name: cityObj.name, id: cityObj.id},
        region: cityObj.region,
        address: {
          apartment: apartment && premisesType === 'apartment' ? apartment : null,
          houseLetter: houseLetter ? houseLetter : null,
          houseNumber,
          office: office && premisesType === 'office' ? office : null,
          street: streetObj ? {id: streetObj.ref, name: streetObj.name} : {id: null, name: street},
        },
        mainOffice: null,
        reserveOffice: null,
      }
    } else if (+values.typeOfDelivery === 2) {
      const cityObj = await locationsApi.getCityById(mainOfficeCity || '')
      const resCityObj = await locationsApi.getCityById(reserveOfficeCity || '')

      dataToSend = {
        post: true,
        mainAddress,
        city: {name: cityObj.name, id: cityObj.id},
        region: cityObj.region,
        address: null,
        mainOffice: {
          city: {name: cityObj.name, id: cityObj.id},
          id: mainOfficeNumber,
        },
        reserveOffice: {
          city: {name: resCityObj.name, id: resCityObj.id},
          id: reserveOfficeNumber,
        },
      }
    } else {
      return
    }
  } else {
    if (+values.typeOfDelivery === 1) {
      dataToSend = {
        city,
        street,
        building: `${houseNumber}${houseLetter ? '-' + houseLetter : ''}`,
        flat: apartment && premisesType === 'apartment' ? apartment : null,
        warehouse: null,
        courierDelivery: true,
        main: mainAddress,
      }
    } else if (+values.typeOfDelivery === 2 || +values.typeOfDelivery === 3) {
      dataToSend = {
        city: mainOfficeCity,
        street: null,
        building: null,
        flat: null,
        warehouse: mainOfficeNumber,
        courierDelivery: false,
        main: mainAddress,
      }
    } else {
      return
    }
  }
  console.log(dataToSend)

  return dataToSend
}
