import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {User} from '../../../../../modules/apps/user-management/users-list/core/_models'
import {ProfileHeaderInfo} from './ProfileHeaderInfo'
import {ProfileHeaderName} from './ProfileHeaderName'
import {ProfileHeaderNav} from './ProfileHeaderNav'

interface Props {
  user: User
}

const ProfileHeader = ({user}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body p-4 pt-md-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='avatar_blank' />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <ProfileHeaderName user={user} />{' '}
              <div className='d-none d-md-block'>
                <ProfileHeaderInfo user={user} />
              </div>
            </div>
          </div>
          <div className='d-md-none'>
            <ProfileHeaderInfo user={user} />
          </div>
        </div>

        <ProfileHeaderNav />
      </div>
    </div>
  )
}

export {ProfileHeader}
