import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {Field} from 'formik'
import {ModalNotification} from '../../../modules/custom/modals/ModalNotification'

interface Props {
  setFieldValue: ISetFieldValue
  typeValue: number
  chosenTypes: number[]
  title: string
  noteText: string
}

function ReceiverTypeCheckbox({setFieldValue, chosenTypes, typeValue, title, noteText}: Props) {
  return (
    <div className='d-flex'>
      <label
        className='d-flex position-relative fs-6 justify-content-left     
         fw-bold mb-3 me-1'
      >
        <Field
          className='form-check-input me-2'
          type='checkbox'
          name='receiverType'
          value={typeValue}
          onChange={(e: any) => {
            const val = parseInt(e.target.value)
            setFieldValue(
              'receiverType',
              !chosenTypes.includes(typeValue)
                ? [...chosenTypes, val]
                : chosenTypes.filter((i) => i !== val)
            )
          }}
          checked={chosenTypes.includes(typeValue)}
        />

        <div className='mb-2'>
          {' '}
          <span className='fs-6'>{title}</span>
        </div>
      </label>
      <ModalNotification
        svg='/media/icons/duotune/general/gen046.svg'
        addClass='ModalNotification__position ModalNotification__receiver-checkbox'
        children={<div className='fs-7 fw-normal'>{noteText}</div>}
      />
    </div>
  )
}

export {ReceiverTypeCheckbox}
