/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedDate, FormattedMessage} from 'react-intl'
import {Link, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {scrollToTabsStart} from '../../../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {ModalNotification} from '../../../../../../modules/custom/modals/ModalNotification'
import {Track} from '../../../../core/_models/_tracks-models'

type Props = {
  track: Track
}

const TrackStatusCell = ({track}: Props) => {
  return (
    <div className='text-center'>
      <CurrentStatus track={track} />
      <LatestUpdate track={track} />
      <Storage track={track} />
      <RelatedParcels track={track} />
      <RelatedTtns track={track} />
    </div>
  )
}

export {TrackStatusCell}

const CurrentStatus = ({track}: Props) => {
  const {wp_data} = track
  const {current} = wp_data
  return (
    <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>
      {current && current.name}
    </p>
  )
}
const LatestUpdate = ({track}: Props) => {
  const {wp_data} = track
  const {latest} = wp_data
  return (
    <>
      {latest && (
        <p className='fs-8 fw-normal m-0'>
          <FormattedDate year='numeric' month='numeric' day='numeric' value={latest} />
        </p>
      )}
    </>
  )
}
const Storage = ({track}: Props) => {
  const {on_storage, on_storage_over, on_storage_msg, dispatch} = track
  const {type} = useParams()
  const isArchive = type === 'archive'
  return (
    <>
      {dispatch === 0 && on_storage && !isArchive && (
        <div className='d-flex justify-content-center align-items-center'>
          <p className='text-muted fw-bold text-hover-primary d-block fs-7 p-0 m-0 me-1'>
            <FormattedMessage
              id='TRACKS.PAGE.LIST.STATUS.STORAGE_DAYS_LEFT'
              values={{on_storage}}
            />
          </p>
          {on_storage_over && (
            <ModalNotification
              svg='/media/icons/duotune/general/gen046.svg'
              addClass='ModalNotification__position ModalNotification__track-status'
              children={
                <div className='fs-7 fw-normal'>
                  {on_storage_over.replace('<b>', '').replace('</b>', '').replace('  ', ' ')}
                </div>
              }
            />
          )}
          {on_storage_msg && (
            <ModalNotification
              svg='/media/icons/duotune/general/gen046.svg'
              addClass='ModalNotification__position ModalNotification__track-status'
              children={<div className='fs-7 fw-normal'>{on_storage_msg.hint}</div>}
            />
          )}
        </div>
      )}
    </>
  )
}
const RelatedParcels = ({track}: Props) => {
  const {track_package, id} = track

  const pkg = track_package[track_package.length - 1]
  return (
    <>
      {pkg && pkg.c_code && (
        <>
          <div className='d-flex justify-content-center align-items-center mb-1'>
            {' '}
            <KTSVG path='/media/icons/duotune/general/gen017.svg' className='svg-icon-1 m-1' />{' '}
            <p className='text-muted fw-bold d-block fs-6 p-0 m-0'>{pkg.c_code}</p>
          </div>
          {track_package.length > 1 && (
            <div onClick={scrollToTabsStart}>
              <Link
                to={`/tracks/edit/all-parcels/${id}`}
                className='text-muted fw-semobold text-muted d-block fs-7'
              >
                <FormattedMessage
                  id='GENERAL.COUNTABLES.PARCELS'
                  values={{itemCount: track_package.length - 1}}
                />
              </Link>
            </div>
          )}
        </>
      )}
    </>
  )
}
const RelatedTtns = ({track}: Props) => {
  const {track_package} = track

  const pkg = track_package[track_package.length - 1]

  return (
    <>
      {pkg && pkg.ttn && (
        <>
          {pkg.link ? (
            <div className='d-flex justify-content-center align-items-center'>
              {+pkg.type === 1 && (
                <KTSVG path='/media/icons/np-logo.svg' className=' svg-icon-1 m-1' />
              )}
              {+pkg.type === 3 && (
                <KTSVG path='/media/icons/vector.svg' className=' svg-icon-1 m-1' />
              )}
              <a
                href={pkg.link}
                target='_blank'
                rel='noreferrer noopener'
                className='text-muted fw-bold text-hover-primary d-block fs-6 '
              >
                {pkg.ttn}
              </a>
            </div>
          ) : (
            <div className='d-flex justify-content-center align-items-center'>
              {' '}
              {+pkg.type === 1 && (
                <KTSVG path='/media/icons/np-logo.svg' className=' svg-icon-1 m-1' />
              )}
              {+pkg.type === 3 && (
                <KTSVG path='/media/icons/vector.svg' className=' svg-icon-1 m-1' />
              )}
              <p className='text-muted fw-bold d-block fs-6 m-0'>{pkg.ttn}</p>
            </div>
          )}
        </>
      )}
    </>
  )
}
