import axios, {AxiosResponse} from 'axios'
import {queryClient} from '../../../..'
import {QUERIES} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {DashboardQueryResponse, IDashboardInfo} from './_models'

const API = process.env.REACT_APP_API_PATH

const PAGE = '/profile'

const BASE_URL = `${API}${PAGE}`

const getDashboardInfo = (): Promise<DashboardQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/dashboard`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<DashboardQueryResponse>) => d.data)
}

const loadData = async () => {
  await queryClient.prefetchQuery(QUERIES.DASHBOARD_INFO, getDashboardInfo, {cacheTime: 0})
}

export {getDashboardInfo, loadData}
