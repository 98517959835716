import {useState} from 'react'
import {Toast, ToastContainer} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'
import clsx from 'clsx'
import {NotType, usePageData} from '../../layout/core'
import {KTSVG} from '../../helpers'

export function Notification(data: NotType) {
  const [show, setShow] = useState(true)
  const {removeNotification} = usePageData()

  const handleClose = (id: string) => {
    setShow(false)
    removeNotification(id)
  }

  return (
    <>
      <Toast
        show={show}
        onClose={() => handleClose(data.id)}
        delay={3000}
        autohide
        className={`mx-3 my-2 bg-${data.type} ${clsx({'text-white': data.type})}`}
      >
        <Toast.Body>
          <div className='modal-dialog modal-dialog-centered mw-300px'>
            <div className='modal-content text-center h-150px d-flex flex-column align-items-center justify-content-start'>
              <button
                type='button'
                className='btn btn-sm btn-icon me-n5 ms-auto cursor-pointer'
                onClick={() => handleClose(data.id)}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </button>{' '}
              <div className={`swal2-icon swal2-${data.status} swal2-icon-show`}>
                <div className='swal2-icon-content'>
                  {data.status === 'success' && '✓'}
                  {data.status === 'warning' && '!'}
                  {data.status === 'danger' && 'X'}
                  {!data.status && '✓'}
                </div>
              </div>
              <p className='fs-5 fw-bold m-0'>
                <FormattedMessage
                  id={data.body}
                  defaultMessage={data.body}
                  values={data.intlValues || {}}
                />
              </p>
            </div>
          </div>
        </Toast.Body>
      </Toast>
    </>
  )
}

export function Notifications() {
  const {notifications} = usePageData()
  return (
    <ToastContainer position='middle-center' className='p-3' style={{zIndex: 100001}}>
      {notifications?.map((child: NotType) => (
        <Notification key={`${child.id}`} {...child} />
      ))}
    </ToastContainer>
  )
}
