import {useRef, useEffect} from 'react'
import {Field, useFormikContext} from 'formik'

interface Props {
  type: string
  inputName: string
  placeholder?: string
  backgroundClass?: string
  autoFocus?: boolean
  inputFontSize?: string
  handleChange?: any
  handleBlur?: any
  disabled?: boolean
}

export function NumberTextInput({
  type,
  inputName,
  placeholder,
  backgroundClass,
  autoFocus,
  inputFontSize,
  handleChange,
  handleBlur,
  disabled = false,
}: Props) {
  const autoFocusRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (autoFocus === true) {
      autoFocusRef.current?.focus()
    }
  }, [autoFocus])
  const {setFieldValue, validateField, setFieldTouched} = useFormikContext()

  // useEffect(() => {
  //   const numberInputs = document.querySelectorAll('input[type="number"]')
  //   const mouseWheelHandler = (event: WheelEvent) => {
  //     event.target.blur()
  //   }

  //   numberInputs.forEach((input) => {
  //     input.addEventListener('mousewheel', mouseWheelHandler)
  //   })

  //   return () => {
  //     numberInputs.forEach((input) => {
  //       input.removeEventListener('mousewheel', mouseWheelHandler)
  //     })
  //   }
  // }, [])

  return (
    <Field
      type={type}
      disabled={disabled}
      className={`form-control form-control-lg form-control-solid ${
        inputFontSize ? inputFontSize : ''
      } ${backgroundClass ? backgroundClass : ''}`}
      name={inputName}
      placeholder={placeholder ? placeholder : ''}
      innerRef={autoFocusRef}
      id={inputName}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(inputName, e.target.value)
        if (handleChange) {
          type === 'number' ? handleChange(+e.target.value) : handleChange(e.target.value)
        }
      }}
      onFocus={(e: React.FocusEvent<HTMLInputElement>) => (e.target.placeholder = '')}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        validateField(inputName)
        setFieldTouched(inputName, true)
        e.target.placeholder = `${placeholder ? placeholder : ''}`
        if (handleBlur) {
          handleBlur(e.target.value)
        }
      }}
    />
  )
}
