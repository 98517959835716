import axios, {AxiosResponse} from 'axios'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {BalanceQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const BALANCE_URL = `/balance`
const BASE_URL = `${API_URL}${BALANCE_URL}`

const getBalance = (query: string): Promise<BalanceQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<BalanceQueryResponse>) => {
      console.log('balance', d.data)
      return d.data
    })
}
type TCheckoutObj = {
  sum: number
  comment: string
  payment_token?: string
  provider: string
  debts?: number
  file?: string
  currency?: string
}

const checkout = ({
  sum,
  comment,
  payment_token,
  provider,
  debts,
  file,
}: TCheckoutObj): Promise<any> => {
  const obj: TCheckoutObj = {
    sum,
    comment,
    provider,
    currency: 'usd',
  }

  if (payment_token) {
    obj.payment_token = payment_token
  }

  if (file) {
    obj.file = file
  }

  if (debts !== undefined) {
    obj.debts = debts
  }

  return axios.post(`${BASE_URL}/add`, obj).then((d: AxiosResponse) => {
    return d.data
  })
}

const getCurrBalance = async () => {
  return await axios.get(`${BASE_URL}/current`).then((d) => d.data)
}

export type TPaymentOptions = Record<
  string,
  {
    currencies: Record<string, string>
    optional_fields: Record<string, {accept?: string; type: string; options?: string[]}>
    required_fields: Record<string, {type: string; options?: string[]}>
    title: string
    fee: number
  }
>

const getPaymentOptions = async (): Promise<TPaymentOptions> => {
  const {selectedLang} = getConfig()
  return await axios
    .get(`${BASE_URL}/providers`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<TPaymentOptions>) => d.data)
}

const uploadPaymentFile = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload_file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

export {checkout, getBalance, getCurrBalance, getPaymentOptions, uploadPaymentFile}
