/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Parcel} from '../../../../core/_models/_parcels-models'
import {FormattedMessage, useIntl} from 'react-intl'
import {ImageLightbox} from '../../../../../../modules/custom/images/ImageLightbox'
import {copyToClipboard} from '../../../../../../../_metronic/helpers/custom/funcs/copyToClipboard'
import {Overlay, Tooltip} from 'react-bootstrap'

type TProps = {
  parcel: Parcel
}

const ParcelNumberCell = ({parcel}: TProps) => {
  return (
    <div className='d-flex align-items-center'>
      <Img parcel={parcel} />
      <div className='d-flex justify-content-start flex-column'>
        <NumberLine parcel={parcel} />
        <ToBePaid parcel={parcel} />
      </div>
    </div>
  )
}

export {ParcelNumberCell}

const NumberLine = ({parcel}: TProps) => {
  const intl = useIntl()
  const {c_code, code, type_delive, id} = parcel
  const [show, setShow] = useState(false)
  const target = useRef(null)
  const parcelNumberRef = useRef(null)

  return (
    <div className='d-flex align-items-center'>
      <Link
        to={`/parcels/info/content/${id}`}
        className='text-dark fw-bold text-hover-primary fs-6 me-2'
      >
        {type_delive === 1 ? (
          <KTSVG path='/media/icons/anchor.svg' className='svg-icon-4 me-1' />
        ) : (
          <KTSVG path='/media/icons/flight.svg' className='svg-icon-4 me-1' />
        )}
        <span ref={parcelNumberRef}> {c_code || code}</span>
      </Link>
      <div
        ref={target}
        onClick={() => {
          copyToClipboard(parcelNumberRef, setShow)
          setTimeout(() => {
            setShow(false)
          }, 1000)
        }}
      >
        <KTSVG
          path='/media/icons/duotune/general/gen054.svg'
          className={`svg-icon-3 ${show ? 'svg-icon-dark' : 'svg-icon-gray-600 cursor-pointer'}`}
        />
      </div>{' '}
      <Overlay target={target.current} show={show} placement='right'>
        {(props) => (
          <Tooltip {...props}>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})} </Tooltip>
        )}
      </Overlay>
    </div>
  )
}

const Img = ({parcel}: TProps) => {
  const {c_code, preview_files} = parcel

  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }
  return (
    <div className='symbol symbol-45px me-5'>
      {preview_files && preview_files.length > 0 ? (
        <div className='cursor-zoom-in'>
          <div className='symbol symbol-fixed position-relative'>
            <img
              src={preview_files[0]}
              width='45'
              height='45'
              alt='track'
              onClick={openLightbox}
              style={{objectFit: 'contain'}}
            />

            <ImageLightbox
              viewerIsOpen={viewerIsOpen}
              closeLightbox={closeLightbox}
              currentImage={currentImage}
              images={preview_files.map((item, index) => {
                return {src: item, title: `${c_code}-${index + 1}`}
              })}
            />
          </div>
        </div>
      ) : (
        <img src={toAbsoluteUrl('/media/icons/duotune/general/gen017.svg')} alt='parcel' />
      )}
    </div>
  )
}

const ToBePaid = ({parcel}: TProps) => {
  const {flow} = parcel

  return (
    <>
      {flow && flow.sum.debt > 0 && (
        <span className='text-muted fw-semobold text-muted d-block fs-7 mt-1'>
          <span className={`p-1 fs-8 fw-semobold  StatusBar__status StatusBar_blocked`}>
            <FormattedMessage id='PARCELS.LIST.STATUSES.TO_BE_PAID' />
          </span>
        </span>
      )}
    </>
  )
}
