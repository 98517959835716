import {IWD} from './_Models'

export const DefaultWD: IWD = {
  id: null,
  host: '',
  name: '',
  directions: [],
  description: '',
  links: null,
  mainColor: '#ff0000',
  telegramBotName: '',
  logoUrl: '',
  logoDarkUrl: '',
  faviconUrl: '',
  logoPrep: '',
  authParams: {},
  gtmId: '',
}
