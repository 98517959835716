import {FieldArray} from 'formik'
import {FormattedMessage} from 'react-intl'
import {ISetFieldValue} from '../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {AddBuyoutForm, IBuyoutFormProds} from '../../../core/_models/_buyout-models'
import {BuyoutGoodsItem} from './BuyoutGoodsItem'

interface Props {
  prods: IBuyoutFormProds[]
  setFieldValue: ISetFieldValue
  values: AddBuyoutForm
}
export function BuyoutGoods({prods, setFieldValue, values}: Props) {
  return (
    <div className='d-flex flex-column'>
      {' '}
      <div className='w-100'>
        <FieldArray
          name='prods'
          render={(arrayHelpers) => (
            <div className='d-flex flex-column flex-col-gutter'>
              {prods.map((item, index) => (
                <BuyoutGoodsItem
                  key={index}
                  index={index}
                  item={item}
                  arrayHelpers={arrayHelpers}
                  prods={prods}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              ))}
              <div className='separator mb-3'></div>

              <div>
                <button
                  className='btn btn-sm btn-secondary d-flex align-items-center'
                  type='button'
                  onClick={() => {
                    arrayHelpers.push({
                      group: {value: '', label: ''},
                      name: {label: '', value: '', brands: []},
                      qty: 1,
                      cost: '',
                      total: '',
                      repack: false,
                      used: false,
                      model: '',
                      user_name: '',
                    })
                  }}
                >
                  <FormattedMessage id='GENERAL.BUTTONS.ADD_GOODS' />
                </button>
              </div>
            </div>
          )}
        />
      </div>
      {/* solve the issue of the object of errors for each property instead of the minimum number of items */}
      {/* <div className='text-danger'>
        <ErrorMessage name='prods' />
      </div> */}
    </div>
  )
}
