import {Buyout} from '../../core/_models/_buyout-models'
import {BuyoutCommentsItem} from './BuyoutCommentsItem'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  buyout: Buyout
}

export function BuyoutComments({buyout}: Props) {
  const intl = useIntl()

  const {comments} = buyout
  console.log('buyout', buyout)

  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      <div className='card-body py-9 px-20 cardBody'>
        <div className='table-responsive'>
          {comments && comments.length > 0 ? (
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-50px'>
                    <FormattedMessage id='BUYOUT_PAGE.COMMENTS_TAB.DATE_COLUMN' />
                  </th>

                  <th className='min-w-120px'>
                    <FormattedMessage id='BUYOUT_PAGE.COMMENTS_TAB.COMMENT_COLUMN' />
                  </th>
                </tr>
              </thead>

              <tbody>
                {comments.map((item) => (
                  <BuyoutCommentsItem key={item.id} row={item} />
                ))}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
