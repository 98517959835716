import AsyncCreatableSelect from 'react-select/async-creatable'
import AsyncSelect from 'react-select/async'
import {components} from 'react-select'
import {IReactSelectProps} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useIntl} from 'react-intl'
import React from 'react'
import {useFormikContext} from 'formik'

interface Props {
  props: IReactSelectProps
}

export const customStyles = {
  // @ts-ignore
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    backgroundColor: 'var(--kt-input-solid-bg)',
    minHeight: 'calc(1.5em + 1.65rem + 2px)',
  }),

  // @ts-ignore
  menuList: (base) => ({
    ...base,

    '::-webkit-scrollbar': {
      width: '10px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'var(--kt-primary)',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
}
// const seeStyles = (base:any, state:any) => {
//   console.log('placeholder styles', base)
//   return base
// }

export function ReactSelectTemplate({props}: Props) {
  const {
    options,
    isLoading,
    onInputChange,
    setFieldValue,
    isClearable,
    placeholder,
    selectName,
    currentValue,
    isCreatable = false,
    setObject = false,
    noOptionsMessage,
    defaultValue,
    handleChange,
    disabled = false,
    inputFontSize,
    optionFormat,
  } = props
  const intl = useIntl()

  const {validateField, setFieldTouched} = useFormikContext()
  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>
          {noOptionsMessage
            ? noOptionsMessage
            : intl.formatMessage({id: 'COMPONENTS.ReactSelectTemplate.NOT_FOUND'})}
        </span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = (inputValue: string, callback: any) => {
    console.log('reactsel loadOptions')

    setTimeout(() => {
      console.log('reactsel loadOptions timeout')

      callback(options)
    }, 1000)
  }

  const loadOptionsCreatable = (inputValue: string, callback: any) => {
    setTimeout(() => {
      const newOption = {label: inputValue, value: inputValue}
      const newOptions = [...options, newOption]
      callback(newOptions)
    }, 1000)
  }

  return (
    <>
      {isCreatable === true ? (
        <AsyncCreatableSelect
          styles={customStyles}
          loadOptions={loadOptionsCreatable}
          defaultOptions={options}
          form={selectName}
          isDisabled={disabled}
          components={{NoOptionsMessage}}
          loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
          formatCreateLabel={(userInput) =>
            intl.formatMessage(
              {
                id: 'COMPONENTS.ReactSelectTemplate.CREATABLE_ADD_LABEL',
              },
              {userInput}
            )
          }
          placeholder={placeholder}
          isClearable={isClearable}
          isLoading={isLoading}
          value={currentValue}
          onInputChange={(e) => {
            onInputChange(e)
          }}
          onChange={(e) => {
            setFieldValue(selectName, e?.value)

            if (handleChange) {
              handleChange()
            }
          }}
          onBlur={() => {
            validateField(selectName)
            setFieldTouched(selectName, true)
          }}
        />
      ) : optionFormat ? (
        <AsyncSelect
          formatOptionLabel={optionFormat}
          // styles={seeStyles}
          styles={customStyles}
          loadOptions={loadOptions}
          defaultOptions={options}
          form={selectName}
          isDisabled={disabled}
          components={{NoOptionsMessage}}
          loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
          placeholder={placeholder}
          isClearable={isClearable}
          isLoading={isLoading}
          value={currentValue}
          defaultValue={defaultValue ? defaultValue : undefined}
          onInputChange={(e) => {
            onInputChange(e)
          }}
          onChange={(e) => {
            console.log(e)

            if (setObject === true) {
              setFieldValue(selectName, e)
            } else {
              setFieldValue(selectName, e?.value)
            }

            if (handleChange) {
              handleChange()
            }
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            validateField(selectName)
            setFieldTouched(selectName, true)
          }}
        />
      ) : (
        <AsyncSelect
          styles={customStyles}
          loadOptions={loadOptions}
          defaultOptions={options}
          form={selectName}
          isDisabled={disabled}
          components={{NoOptionsMessage}}
          loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
          placeholder={placeholder}
          isClearable={isClearable}
          isLoading={isLoading}
          value={currentValue}
          defaultValue={defaultValue ? defaultValue : undefined}
          onInputChange={(e) => {
            onInputChange(e)
          }}
          onChange={(e) => {
            if (setObject === true) {
              setFieldValue(selectName, e)
            } else {
              setFieldValue(selectName, e?.value)
            }

            if (handleChange) {
              handleChange()
            }
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            validateField(selectName)
            setFieldTouched(selectName, true)
          }}
        />
      )}
    </>
  )
}
