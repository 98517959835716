/* eslint-disable jsx-a11y/anchor-is-valid */
import data from '../../../../data/receivers/receiversMockData.json'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {getSavedPagination, initialQueryState, KTCard, KTSVG} from '../../../../_metronic/helpers'
import {ReceiversTable} from './table/ReceiversTable'
import {ReceiversListHeader} from './components/header/ReceiversListHeader'
import {AddReceiverModal} from './add-receiver-modal/AddReceiverModal'
import {FormattedMessage} from 'react-intl'
import {useEffect} from 'react'

const ReceiversList = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const openAddReceiverModal = () => {
    setItemIdForUpdate(null)
  }
  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()

    updateState({
      ...initialQueryState,
      itemsPerPage: retrievedData,
    })
  }, [])

  return (
    <>
      <div
        style={{marginTop: '-4%', zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>
          <FormattedMessage id='RECEIVERS.PAGE.LIST.HEADING' />
        </h3>
        <button type='button' className='btn btn-primary' onClick={openAddReceiverModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          <FormattedMessage id='RECEIVERS.PAGE.LIST.ADD_BUTTON' />
        </button>
      </div>
      <KTCard>
        <ReceiversListHeader />
        <ReceiversTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <AddReceiverModal />}
    </>
  )
}

const ReceiversListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ReceiversList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ReceiversListWrapper}
